import { sendMatomoEvent } from "./sendMatomoEvent";
import { localeCodeFromUrl } from "./locale";

const localeCode = typeof window !== "undefined" 
  ? localeCodeFromUrl(window.location.pathname) 
  : "us-en";

const locale = localeCode ? localeCode.substring(0, localeCode.indexOf("-")) : "sv";

export interface OptionalCookies {
  name?: string;
  label?: string;
  description?: string;
  cookies?: Array<string>;
  onAccept?: () => void;
  onRevoke?: () => void;
}
export interface CookieControlConfig {
  apiKey: string;
  product: "COMMUNITY" | "PRO" | "PRO_MULTISITE";
  position: "LEFT" | "RIGHT";
  initialState: any;
  notifyDismissButton: boolean;
  optionalCookies: OptionalCookies[];
  accessibility: { [key: string]: string | boolean };
  theme: "LIGHT" | "DARK";
  locale?: string;
  locales: { [key: string]: any }[];
  statement: { [key: string]: any };
  branding: { [key: string]: any };
}

export type OptionalCookieCategories = "functional" | "analytics" | "marketing";

export const getCookieConsentByCategory = (
  category: OptionalCookieCategories
) => window.CookieControl.getCategoryConsent(CookieCategoryMap[category]);

export const CookieCategoryMap: Record<OptionalCookieCategories, number> = {
  functional: 0,
  analytics: 1,
  marketing: 2,
};

export const config: CookieControlConfig = {
  apiKey: process.env.NEXT_PUBLIC_CIVIC_API_KEY as string,
  initialState: "notify",
  notifyDismissButton: false,
  product: "PRO",
  position: "LEFT",
  theme: "LIGHT",
  locale: locale,
  statement: {
    description:
      "För mer detaljerad information kring kakorna vi använder och varför, läs gärna ",
    name: "vår policy gällande behandling av personuppgifter",
    url: "https://opera.se/personuppgifter",
    updated: "30/09/2020",
  },
  optionalCookies: [
    {
      name: "functional",
      label: "Funktionella kakor",
      description:
        "Funktionella kakor förbättrar din upplevelse av webbplatsen, men krävs inte för att den ska fungera.",
      cookies: ["alert-bar"],
      onRevoke: function () {
        window.CookieControl.delete("alert-bar");
      },
    },
    {
      name: "analytics",
      label: "Analytiska kakor",
      description:
        "Analytiska kakor hjälper oss att förbättra webbsidan genom att samla in och rapportera information kring hur webbplatsen används.",
      cookies: ["_pk_ref", "_pk_cvar", "_pk_id", "_pk_ses"],
      onAccept: function () {
        sendMatomoEvent({
          event: "analyticsConsent",
          analyticsConsentState: true,
        });
      },
      onRevoke: function () {
        sendMatomoEvent({
          event: "analyticsConsent",
          analyticsConsentState: false,
        });
      },
    },
    {
      name: "marketing",
      label: "Marknadsföringskakor",
      cookies: ["__cf_bm"],
      description:
        "Vi använder marknadsföringskakor för att hjälpa oss att förbättra relevansen i digitala kampanjer.",
      onAccept: function () {
        sendMatomoEvent({
          event: "marketingConsent",
          marketingConsentState: true,
        });
      },
      onRevoke: function () {
        sendMatomoEvent({
          event: "marketingConsent",
          marketingConsentState: false,
        });
      },
    },
  ],
  locales: [
    {
      locale: "en",
      text: {
        title: "This site uses cookies to store information on your computer.",
        notifyTitle:
          "This site uses cookies to store information on your computer.",
        intro:
          "Some of these cookies are essential, while others help us to improve your experience by providing insights into how the site is being used.",
        accept: "I Accept Recommended Settings",
        reject: "I Do Not Accept",
        acceptSettings: "I Accept Recommended Settings",
        rejectSettings: "I Do Not Accept",
        settings: "Settings",
        necessaryTitle: "Necessary Cookies",
        necessaryDescription:
          "Necessary cookies enable core functionality. The website cannot function properly without these cookies, and can only be disabled by changing your browser preferences.",
        on: "On",
        off: "Off",
        cornerButton: "Set cookie preferences", // used by screen readers
        landmark: "Cookie preferences", // used by screen readers
        removeAbout: true,
      },
      statement: {
        description:
          "For more detailed information on the cookies we use, please check our ",
        name: "Privacy Policy",
        url: "/en/about-this-website/personal-data-policy/",
        updated: "30/09/2020",
      },
      optionalCookies: [
        {
          label: "Functional Cookies",
          description:
            "Functional cookies improve your experience of the website, but aren't necessary for it to function.",
        },
        {
          label: "Analytical Cookies",
          description:
            "Analytical cookies help us to improve our website by collecting and reporting information on its usage.",
        },
        {
          label: "Marketing Cookies",
          description:
            "We use marketing cookies to help us improve the relevancy of advertising campaigns you receive.",
        },
      ],
    },
    {
      locale: "sv",
      text: {
        title: 'Denna webbplats använder kakor ("cookies").',
        notifyTitle: 'Denna webbplats använder kakor ("cookies").',
        intro:
          "Vissa kakor är nödvändiga, medan andra hjälper oss att förbättra din upplevelse genom att ge oss insikter kring hur webbplatsen användas.",
        notifyDescription:
          "Vissa kakor är nödvändiga, medan andra hjälper oss att förbättra din upplevelse genom att ge oss insikter kring hur webbplatsen användas.",
        accept: "Jag accepterar de rekommenderade inställningarna", // For initial pop up
        reject: "Jag accepterar inte", // For initial pop up
        acceptSettings: "Jag accepterar", // For settings panel
        rejectSettings: "Jag accepterar inte", // For settings panel
        settings: "Inställningar",
        necessaryTitle: "Nödvändiga kakor",
        necessaryDescription:
          "Nödvändiga kakor möjliggör grundläggande teknisk funktionalitet. Denna webbplats fungerar inte utan dessa kakor, och kan avaktiveras endast genom inställningar i din webbläsare.",
        on: "På",
        off: "Av",
        cornerButton: "Välj cookie inställningar", // used by screen readers
        landmark: "Cookie inställningar", // used by screen readers
      },
      statement: {
        description:
          "För mer detaljerad information kring kakorna vi använder och varför, läs gärna ",
        name: "vår policy gällande behandling av personuppgifter",
        url: "/om-webbplatsen/personuppgiftspolicy/",
        updated: "30/09/2020",
      },
    },
  ],
  branding: {
    removeIcon: true, // Completely removes Civic button from bottom left of screen
    removeAbout: true,
    buttonIcon:
      "https://www.opera.se/media/filebrowser/2020/09/cookieicon_white_.svg",
    buttonIconHeight: 64,
    buttonIconWidth: 64,
  },
  accessibility: {
    highlightFocus: true,
  },
};

const loadCookieControl = () => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js";
  script.setAttribute("crossorigin", "anonymous");
  script.integrity =
    "sha384-EeZGq++njpOkb2ymie9Fhtaw3EOPvAuxwaS5kq/K4ZeZN8qM2eARs3QxQ+BLdw7/";
  document.body.appendChild(script);

  script.addEventListener("load", (e) => {
    if (window.CookieControl) {
      window.CookieControl.load(config);
    }
  });
};

export const openCookieControlPanel = () => {
  if (window && window.CookieControl) {
    window.CookieControl.open();
  }
};

export { loadCookieControl };
