'use client'
import * as React from "react";
import styled from "styled-components";
import { Text } from "../../components";
import { colors, scale, breakpoint } from "../../styles";

export interface ProductGroupProps {
  title: string;
  image: React.ReactNode;
}

export interface ProductGroupComponent {
  Content: React.FC;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% + ${scale.px(4)});
  margin: 0 ${scale.px(-2)};

  // Line
  &:after {
    content: "";
    display: block;
    width: calc(100% - ${scale.px(6.5)});
    height: 1px;
    background-color: ${colors["brown-normal"]};
  }

  ${breakpoint("s")`
		width: 100%;
		margin: 0;
		border: 1px solid ${colors["brown-normal"]};
		padding: ${scale.px(1.75)};

		&:after {
			display: none;
		}
	`}

  ${breakpoint("l")`
		padding-left: ${scale.px(6.25)};
		padding-right: ${scale.px(8.75)};
	`}
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  ${breakpoint("s")`
		flex-direction: row-reverse;
	`}
`;

const Media = styled.div`
  position: relative;
  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 70.8%;
  }

  ${breakpoint("s")`
		width: 50%;
	`}

  ${breakpoint("l")`
		width: 62.5%;
	`}
`;

const Title = styled.div`
  width: calc(100% - ${scale.px(4.5)});
  margin-top: ${scale.px(-4)};
  padding: ${scale.px(3)} ${scale.px(2)};
  background-color: ${colors["greybrown"]};
  position: relative;

  h3 {
    color: ${colors["brown"]};
  }

  ${breakpoint("s")`
		width: 62%;
		position: absolute;
		top: ${scale.px(4)};
		left: 0;
		margin: 0;
		padding: ${scale.px(3)} ${scale.px(4)};
	`}
`;

const Content = styled.div`
  width: 100%;
  z-index: 1;
  padding: 0 ${scale.px(2.25)};

  ${breakpoint("s")`
		padding: 0;
	`}

  ${breakpoint("l")`
		width: 100%;
		margin-top: ${scale.px(-5.25)};
		padding-left: ${scale.px(14)};
		padding-right: ${scale.px(2)};
	`}
`;

const ProductGroup: React.FC<React.PropsWithChildren<ProductGroupProps>> &
  ProductGroupComponent = ({ title, image, children }) => {
  return (
    <Wrapper>
      <Header>
        <Media>{image}</Media>
        <Title>
          <Text
            mb={0}
            variant={"headline-3"}
            fontSize={{ _: 3, s: 4, l: 6 }}
            lineHeight={{ _: 4, s: 5, l: 7 }}
          >
            {title}
          </Text>
        </Title>
      </Header>
      {children}
    </Wrapper>
  );
};

ProductGroup.Content = function(props: React.PropsWithChildren<{}>) {
  return <Content>{props.children}</Content>;
};

export { ProductGroup };
