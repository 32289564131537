"use client";
import * as React from "react";
import styled from "styled-components";

export interface CollapsibleProps {
  isCollapsed?: boolean;
}

export interface CollapsibleComponent {
  Content: React.FC;
}

const CollapsibleStyled = styled.div<CollapsibleProps>`
  display: ${(props) => (props.isCollapsed ? "none" : "block")};
`;
export const CollapsibleContext = React.createContext({
  isCollapsed: true,
  toggleIsCollapsed: () => {},
});

const Collapsible: React.FC<CollapsibleProps> & CollapsibleComponent = (
  props
) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const toggleIsCollapsed = () => setIsCollapsed((prev) => !prev);

  return (
    <CollapsibleContext.Provider
      value={{ isCollapsed, toggleIsCollapsed }}
      {...props}
    />
  );
};

Collapsible.Content = function (props: React.PropsWithChildren<{}>) {
  const { isCollapsed } = React.useContext(CollapsibleContext);

  return (
    <CollapsibleStyled isCollapsed={isCollapsed}>
      {props.children}
    </CollapsibleStyled>
  );
} as React.FC;

export { Collapsible };
