import { lightTheme, darkTheme } from "../theme";
import { DefaultTheme } from "styled-components";
import { ColorTheme } from "../../@types/styled";

/**
 * Type guard to check if a key is a valid key of ColorTheme
*/
function isColorThemeKey(key: string): key is keyof ColorTheme {
  return key in lightTheme;
}

/**
 * Returns a merged theme with a given ColorTheme
 */
export const mergeColorTheme = (theme: ColorTheme): DefaultTheme => {
  // filter out null values
  const filteredTheme = Object.keys(theme).reduce(
    (acc, key) => ({
      ...acc,
      ...(theme[key as keyof ColorTheme] !== null && {
        [key]: theme[key as keyof ColorTheme],
      }),
    }),
    {}
  );
  return theme.dark
    ? { ...darkTheme, ...filteredTheme }
    : { ...lightTheme, ...filteredTheme };
};

/**
 * Returns the ColorTheme of a given theme
 */
export const extractColorTheme = (theme: DefaultTheme): ColorTheme =>
  Object.keys(lightTheme).reduce((acc, key) => {
    if (isColorThemeKey(key)) {
      return {
        ...acc,
        ...(typeof theme[key] !== "undefined" ? { [key]: theme[key] } : {}),
      };
    }
    return acc;
  }, {} as ColorTheme);

/**
 * Checks if a theme is using the default color theme
 */
export const isDefaultTheme = (theme: DefaultTheme): boolean =>
  JSON.stringify(extractColorTheme(theme)) === JSON.stringify(lightTheme);

