"use client";
import styled, { WebTarget } from "styled-components";
import { colors, breakpoint, scale } from "../../styles";
import { Text, Icon } from "../../components";
import { TRANSITION_CURVE } from "../../constants";
import {
  contentBlockAnimation,
  headingAnimation,
  preambleAnimation,
  arrowAnimation,
} from "./animations";
import { useInView } from "react-intersection-observer";

export interface PromoData {
  title?: string;
  preamble?: string;
}

export interface PromoProps extends PromoData {
  bgColor?: string;
  textColor?: string;
  media?: React.ReactNode;
  pageGridOrder?: number;
}

const PromoMedia = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;

  ${breakpoint("m")`
		position: absolute;
		top: 0;
		left: 0;
	`}

  img, video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const PromoContent = styled.div`
  position: absolute;
`;

const HeadingContainer = styled.div`
  ${breakpoint("l")`
		overflow: hidden;
	`}
`;

const PreambleContainer = styled.div`
  ${breakpoint("l")`
		height: ${scale.px(3 * 2)};
		overflow: hidden;
	`}
`;

const ArrowContainer = styled.div``;

export const PromoStyled = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["videoUrl", "bgColor", "pageGridOrder", "textColor"].includes(prop),
})<PromoProps>`
  position: relative;
  background-color: ${(props) => props.bgColor || colors["blue-dark"]};
  height: 100%;
  width: 100%;
  min-height: ${(props) => (props.media ? scale.px(14) : "none")};
  overflow: hidden;

  ${PromoMedia} {
    img {
      transform: scale(1) translateZ(0);
      backface-visibility: hidden;
      transition: transform 3s cubic-bezier(0.304, 0.132, 0, 0.991);
    }
  }

  ${breakpoint("l")`
		&:hover {
			${PromoMedia} {
				img {
					transform: scale(1.04) translateZ(0);
				}
			}

			${PromoContent} {
				svg {
					transform: translateY(50%) translateX(${scale.px(2)});
				}
			}
		}
	`}

  ${PromoContent} {
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.media ? "start" : "center")};
    height: ${(props) => (props.media ? "auto" : "100%")};
    left: ${(props) => (props.media ? scale.px(3) : 0)};
    right: ${(props) => (props.media ? scale.px(3) : 0)};
    bottom: ${(props) => (props.media ? scale.px(3) : 0)};
    padding: ${scale.px(2)} ${scale.px(8)} ${scale.px(2)} ${scale.px(2.5)};
    background-color: ${(props) =>
      props.bgColor ? props.bgColor : colors["off-white"]};
    opacity: 0;
    ${contentBlockAnimation}

    h3 {
      opacity: 0;
      margin-bottom: ${(props) => (props.media ? scale.px(1) : 0)};
      ${headingAnimation}
    }

    h3,
    p {
      color: ${(props) => props.textColor || colors["black"]};
    }

    p {
      display: ${(props) =>
        props.pageGridOrder === 2 ? "-webkit-box" : "none"};
      height: ${scale.px(3 * 2)};
      margin-bottom: 0;
      opacity: 0;
      ${preambleAnimation}
    }

    ${ArrowContainer} {
      position: absolute;
      right: ${scale.px(4)};
      bottom: 50%;
      ${arrowAnimation}
    }

    svg {
      transform: translateY(50%) translateX(0);
      transition: transform 0.3s ${TRANSITION_CURVE};

      path {
        fill: ${(props) => props.textColor || colors["off-white"]};
      }
    }

    ${breakpoint("s")<PromoProps>`
			display: block;
			height: auto;
			width: ${(props) => (props.media ? scale.px(47) : "none")};

			p {
				display: -webkit-box;
			}
		`}

    ${breakpoint("m")`
			margin-bottom: ${scale.px(1)};

			h3 {
				margin-bottom: ${scale.px(1)};
			}

			${ArrowContainer} {
				bottom: ${scale.px(3)};
			}
		`}

		${breakpoint("l")<PromoProps>`
			right: auto;
			align-items: start;
		`}

		${breakpoint("xxl")`
			padding-top: ${scale.px(4)};
			padding-bottom: ${scale.px(6)};
			padding-right: ${scale.px(6)};
			padding-left: ${scale.px(4)};
		`}
  }
`;

const Promo: React.FC<PromoProps> = (props) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <PromoStyled {...props} ref={ref}>
      {props.media && <PromoMedia>{props.media}</PromoMedia>}
      {inView && (
        <PromoContent>
          {props.title && (
            <HeadingContainer>
              <Text
                as="h3"
                fontSize={{ _: 3, xxl: 4 }}
                lineHeight={{ _: 4, xxl: 5 }}
                variant="headline-4"
                mb={1}
              >
                {props.title}
              </Text>
            </HeadingContainer>
          )}
          {props.preamble && (
            <PreambleContainer>
              <Text fontSize={2.25} lineHeight={3} truncate="twoLine">
                {props.preamble}
              </Text>
            </PreambleContainer>
          )}
          <ArrowContainer>
            <Icon icon="arrow-right" size={1.5} />
          </ArrowContainer>
        </PromoContent>
      )}
    </PromoStyled>
  );
};

export { Promo };
