"use client";
import React from "react";
import { Text } from "../Text";
import {
  Content,
  Media,
  MediaBackground,
  PromotionalAreaStyled,
  TextLink,
} from "./styled";

export interface PromotionalAreaData {
  title: string;
  media: React.ReactNode;
  text?: string;
  link?: {
    title: string;
    url: string;
    alt?: string;
    target?: string;
  };
  color?: string;
  darkMode?: boolean;
}

export interface PromotionalAreaProps extends PromotionalAreaData {}

export const PromotionalArea: React.FC<PromotionalAreaProps> = ({
  color = "#ffffff",
  darkMode = false,
  ...props
}) => {
  return (
    <PromotionalAreaStyled>
      <MediaBackground color={color}>
        {props.media && <Media>{props.media}</Media>}
      </MediaBackground>
      <Content useImagePadding={!!props.media}>
        {props.title && (
          <Text
            textAlign="left"
            variant="headline-4"
            mt={1}
            mb={1}
            textColor={darkMode ? "white" : "black"}
          >
            {props.title}
          </Text>
        )}
        {props.text && (
          <Text
            textAlign="left"
            variant="paragraph-1"
            fontSize={2.25}
            lineHeight={3.125}
            textColor={darkMode ? "white" : "black"}
          >
            {props.text}
          </Text>
        )}
        {props.link && (
          <TextLink
            textAlign="left"
            variant="paragraph-1"
            fontSize={2.25}
            lineHeight={3}
            mb={-2}
            textColor={darkMode ? "white" : "black"}
          >
            <a
              href={props.link.url}
              target={props.link.target}
              style={{ color: "inherit", textDecoration: "underline" }}
            >
              {props.link.title}
            </a>
          </TextLink>
        )}
      </Content>
    </PromotionalAreaStyled>
  );
};
