import { css, keyframes } from "styled-components"
import { scale } from "../../styles"
import { TRANSITION_CURVE } from "../../constants"
import { PromoProps } from "."

export const moveRightAndFadeIn = keyframes`
    0% {
        transform: translateX(${scale.px(-6)});
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
`

export const moveRightBig = keyframes`
    0% {
        transform: translateX(${scale.px(-17)});
    }
    100% {
        transform: translateX(0);
    }
`

export const moveDownAndFadeIn = keyframes`
    0% {
        transform: translateY(${scale.px(6)});
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`

export const contentBlockAnimation = css<PromoProps>`
	animation-name: ${moveRightAndFadeIn};
	animation-timing-function: ${TRANSITION_CURVE};
	animation-duration: 1.5s;
	animation-fill-mode: forwards;
	animation-delay: ${props =>
		props.pageGridOrder ? `${props.pageGridOrder * 0.2}s` : `0s`};
`

export const headingAnimation = css<PromoProps>`
	animation-name: ${moveDownAndFadeIn};
	animation-timing-function: ${TRANSITION_CURVE};
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-delay: ${props =>
		props.pageGridOrder ? `${0.9 + props.pageGridOrder * 0.2}s` : `0s`};
`

export const preambleAnimation = css<PromoProps>`
	animation-name: ${moveDownAndFadeIn};
	animation-timing-function: ${TRANSITION_CURVE};
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-delay: ${props =>
		props.pageGridOrder ? `${1 + props.pageGridOrder * 0.2}s` : `0s`};
`

export const arrowAnimation = css<PromoProps>`
	animation-name: ${moveRightBig};
	animation-timing-function: ${TRANSITION_CURVE};
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-delay: ${props =>
		props.pageGridOrder && props.pageGridOrder > 1
			? `${props.pageGridOrder * 0.2}s`
			: `0s`};
`
