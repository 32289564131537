"use client";
import React from "react";
import styled, { WebTarget } from "styled-components";
import { scale, fontWeight, breakpoint } from "../../styles";
import { Hamburger } from "../Hamburger";
export interface NavToggleLocales {
  label: string;
}

export interface NavToggleProps {
  open: boolean;
  locales?: NavToggleLocales;
  onClick: () => void;
  ariaControls?: string;
}

const NavToggleStyled = styled.button
  .attrs<NavToggleProps>((props) => ({
    "aria-controls": props.ariaControls || "mega-menu",
    "aria-expanded": props.open,
    "aria-haspopup": true,
  }))
  .withConfig({
    shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
      !["ariaControls"].includes(prop),
  })<NavToggleProps>`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-bottom: ${scale.px(-1.25)};

  ${breakpoint("l")`
		flex-direction: row;
		margin-bottom: 0;
	`}
`;

const NavToggleLabel = styled.span`
  color: ${({ theme }) => theme.textColor};
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: ${scale.px(1)};

  ${breakpoint("l")`
		display: inline-block;
		font-size: ${scale.px(1.75)};
		font-weight: ${fontWeight.semibold};
		margin-right: ${scale.px(1)};
	`}

  ${breakpoint("xl")`
		font-size: ${scale.px(2)};
	`}
`;

const NavToggle: React.FC<NavToggleProps> = ({
  onClick,
  locales,
  open = false,
  ...rest
}) => {
  const ref = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [hasBeenOpened, setHasBeenOpened] = React.useState(false);

  // Return focus to toggle after menu closes
  React.useEffect(() => {
    if (open) {
      setHasBeenOpened(true);
    }

    if (ref.current && hasBeenOpened && !open) {
      ref.current.focus();
      setHasBeenOpened(false);
    }
  }, [ref, open, hasBeenOpened]);

  return (
    <NavToggleStyled ref={ref} onClick={onClick} open={open} {...rest}>
      {locales?.label && <NavToggleLabel>{locales.label}</NavToggleLabel>}
      <Hamburger open={open} />
    </NavToggleStyled>
  );
};

export { NavToggle };
