import * as React from "react"
import { Text, TextProps } from "../"

export interface TextButtonProps extends TextProps {
	onClick?: () => any
}

export const TextButton: React.FC<TextButtonProps> = props => (
	<Text as="button" fontSize={2} {...props} />
)
