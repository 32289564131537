"use client";
import * as React from "react";
import { motion, AnimatePresence } from "framer-motion";

export interface AnimatedContainerProps {
  active?: boolean;
  children?: React.ReactNode;
}

const AnimatedContainer: React.FC<AnimatedContainerProps> = ({
  active = false,
  children,
}) => {
  return (
    <AnimatePresence initial={false}>
      {active && (
        <motion.section
          style={{ width: "100%" }}
          initial="active"
          animate="open"
          exit="active"
          variants={{
            open: { opacity: 1, y: 0, height: "auto" },
            active: { opacity: 0, y: -80, height: 0 },
          }}
          transition={{ ease: [0.57, 0.05, 0.08, 0.96], duration: 0.7 }}
        >
          {children}
        </motion.section>
      )}
    </AnimatePresence>
  );
};

export { AnimatedContainer };
