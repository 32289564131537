export const sendMatomoEvent = (
	dataLayer: Record<string, string | number | boolean>
) => {
	if (typeof window !== "undefined" && typeof document !== "undefined") {
		window._mtm = window._mtm || []
		window._mtm.push({
			...dataLayer
		})
	}
}

// -- Example usage --
// sendMatomoEvent({
//     event: "Name of event",
//     someSpecificKey: "A bit of data"
// })
