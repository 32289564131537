"use client";
import * as React from "react";
import styled from "styled-components";
import { breakpoint, scale } from "../../styles";
import { Flex, Text } from "../../components";

export interface MultipleShowsTabProps {
  image?: React.ReactNode;
  children?: React.ReactNode;
}

export interface MultipleShowsTabComponent {
  Title: React.FC<React.PropsWithChildren>;
  Subtitle: React.FC<React.PropsWithChildren>;
}

export const MultipleShowsTabFigure = styled.figure`
  position: relative;
  width: ${scale.px(14)};
  height: 100%;
  border: 1px solid transparent;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: ${scale.px(2)};

  ${breakpoint("m")`
		margin-bottom: ${scale.px(3)};
		width: 100%;
		max-width: ${scale.px(20)};
	`}

  ${breakpoint("xl")`
		max-width: ${scale.px(28)};
	`}

	& > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const MultipleShowsTab: React.FC<MultipleShowsTabProps> &
  MultipleShowsTabComponent = (props) => {
  return (
    <Flex flexDirection="column" w={1} alignItems="center" pb={{ _: 3, m: 4 }}>
      {props.image && (
        <MultipleShowsTabFigure>{props.image}</MultipleShowsTabFigure>
      )}
      {props.children}
    </Flex>
  );
};

MultipleShowsTab.Title = function (props: React.PropsWithChildren<{}>) {
  return (
    <Text
      fontSize={{ _: 1.75, m: 2, xxl: 2.5 }}
      lineHeight={{ _: 2.25, m: 2.5, xxl: 3 }}
      fontWeight="semibold"
      textTransform="uppercase"
      textAlign="center"
      letterSpacing={0.1875}
      mb={{ _: 0.5, m: 0 }}
      px={2}
    >
      {props.children}
    </Text>
  );
};
MultipleShowsTab.Subtitle = function (props: React.PropsWithChildren<{}>) {
  return (
    <Text
      fontSize={{ _: 1.75, m: 2.5 }}
      lineHeight={{ _: 2.25, m: 4 }}
      mb={0}
      textAlign="center"
    >
      {props.children}
    </Text>
  );
};

export { MultipleShowsTab };
