import styled from "styled-components";

export const Svg = styled.svg`
  display: block;
  width: 100%;
`;

export const TextLogo = ({ fill = "" }) => (
  <Svg viewBox="0 0 301.4 115.2" fill={fill}>
    <g>
      <path
        d="M202,114.9V72.3h-5.2v22.1c0,2.3,0.3,18.9,0.3,19.1c-0.3-0.8-5-16.3-5.9-19.3l-7.1-21.9h-9.6v42.6h5.2V92
                    c0-2.2-0.4-18.1-0.4-18.2l5.7,18.4l7.3,22.8L202,114.9L202,114.9z M146.7,92.6c2-9.2,3.6-17.8,3.8-18.6c0.1,0.9,1.9,9.9,3.9,18.6
                    l1.6,7.4h-11L146.7,92.6z M141.8,114.9l2.2-9.8h13.1l2.2,9.8h5.6l-9.6-42.6h-9.4l-9.6,42.6H141.8z M107.3,77.3h6.3
                    c5,0,7.3,2.4,7.3,7v1.9c0,4.5-2.3,7-7.1,7h-6.4V77.3H107.3z M122.1,114.9h6.2L120,96.8c4-1.5,6.3-5.4,6.3-10.7v-1.8
                    c0-7.5-4.6-12.1-12.6-12.1H102v42.6h5.4V98.3c3.4,0,7,0,7.3-0.1L122.1,114.9z M91.2,114.9v-5H76V96.2h13.9V91H76V77.3h15.2v-5H70.6
                    v42.6H91.2z M42,77.3h5.7c4.8,0,7.5,2.9,7.5,7.3v5c0,4.4-2.7,7.5-7.5,7.5H42V77.3z M42,114.9v-12.7h5.7c7.8,0,13-5.2,13-12.6v-4.9
                    c0-7.3-5.1-12.4-13-12.4H36.6v42.6H42z M0,103c0,7.3,5.1,12.2,12.6,12.2c7.4,0,12.4-4.9,12.4-12.2V84.2C25,76.9,20,72,12.5,72
                    C5.1,72,0,76.9,0,84.2V103z M5.4,84.2c0-4.2,2.6-7.1,7.1-7.1c4.6,0,7.1,2.9,7.1,7.1V103c0,4.2-2.6,7.1-7.1,7.1
                    c-4.6,0-7.1-2.9-7.1-7.1V84.2z"
      />
      <path
        d="M276.5,19.1c0.2,13.6,19.4,11.4,19.4,21c0,4.1-2.9,6.3-7.2,6.3c-4.7,0-7.8-2.7-7.8-7.4v-1.3h-5.4V39
                    c0,8.1,6.1,12.3,13.2,12.3c7.6,0,12.7-4.5,12.7-11.4c0-13.1-19.4-11.9-19.4-21c0-3.8,2.6-5.9,6.6-5.9c4.1,0,7,2.2,7,7v1.4h5.4V20
                    c0-8.1-5.6-11.9-12.4-11.9C281.7,8.1,276.5,12.6,276.5,19.1 M253.8,33.1h6.4V39c0,5-2.7,7.4-7.2,7.4c-4.5,0-7.1-2.7-7.1-7.5V20.7
                    c0-4.8,2.7-7.4,7.1-7.4c4.5,0,7,2.6,7,7.4v1.5h5.4v-1.3c0-8.3-5.1-12.7-12.4-12.7c-7.1,0-12.6,4.8-12.6,12.7v17.9
                    c0,8.3,5.2,12.7,12.5,12.7c7.1,0,12.5-4.5,12.5-12.6V28.2h-11.7v4.9H253.8z M211.2,13.5h6.3c5,0,7.3,2.4,7.3,7v1.9
                    c0,4.5-2.3,7-7.1,7h-6.4V13.5H211.2z M226,51.1h6.2L223.8,33c4-1.5,6.3-5.4,6.3-10.7v-1.8c0-7.5-4.6-12.1-12.6-12.1h-11.7V51h5.4
                    V34.5c3.4,0,7,0,7.3-0.1L226,51.1z M169.2,39.2c0,7.3,5.1,12.2,12.6,12.2c7.4,0,12.4-4.9,12.4-12.2V20.4c0-7.3-5-12.2-12.5-12.2
                    c-7.4,0-12.5,4.9-12.5,12.2V39.2z M174.7,20.4c0-4.2,2.6-7.1,7.1-7.1c4.6,0,7.1,2.9,7.1,7.1v18.9c0,4.2-2.6,7.1-7.1,7.1
                    c-4.6,0-7.1-2.9-7.1-7.1V20.4z M145.7,46.2h-6.9V32h6.9c5.9,0,7.9,2.6,7.9,6.5v1.3C153.6,43.6,151,46.2,145.7,46.2 M145,27.1h-6.2
                    V13.4h6.9c4.3,0,7,2,7,5.9v1.4C152.6,24.5,150.8,27.1,145,27.1 M152.1,29.2c3.1-0.8,5.8-3.5,5.8-8.7V19c0-6.4-4.3-10.6-12.2-10.6
                    h-12.2V51h12.8c8.2,0,12.7-4.6,12.7-11.2v-1.4C158.9,33,156,29.9,152.1,29.2 M122.6,51.1v-5h-15.2V32.3h13.9v-5.1h-13.9V13.5h15.2
                    v-5H102v42.6H122.6z M92.8,13.5v-5H69.1v5h9.1v37.6h5.4V13.5H92.8z M35.9,39.2c0,7.3,5.1,12.2,12.6,12.2c7.4,0,12.4-4.9,12.4-12.2
                    V20.4c0-7.3-5-12.2-12.5-12.2c-7.4,0-12.5,4.9-12.5,12.2V39.2z M41.3,20.4c0-4.2,2.6-7.1,7.1-7.1c4.6,0,7.1,2.9,7.1,7.1v18.9
                    c0,4.2-2.6,7.1-7.1,7.1c-4.6,0-7.1-2.9-7.1-7.1V20.4z M50.8,2.6c0,1.5,1.1,2.7,2.6,2.7s2.7-1.1,2.7-2.7C56.1,1.1,55,0,53.4,0
                    C52,0,50.8,1.1,50.8,2.6 M40.9,2.6c0,1.5,1.1,2.7,2.6,2.7s2.7-1.1,2.7-2.7C46.2,1.1,45,0,43.5,0C42.1,0,40.9,1.1,40.9,2.6
                    M13.3,33.1h6.4V39c0,5-2.7,7.4-7.2,7.4s-7.1-2.7-7.1-7.5V20.7c0-4.8,2.7-7.4,7.1-7.4c4.5,0,7,2.6,7,7.4v1.5H25v-1.3
                    c0-8.3-5.1-12.7-12.4-12.7C5.5,8.2,0,13,0,20.9v17.9C0,47,5.2,51.4,12.5,51.4c7.1,0,12.5-4.5,12.5-12.6V28.2H13.3V33.1z"
      />
    </g>
  </Svg>
);
