export type LocaleSlug = "en"
export type LocaleCode = "en-gb" | "sv-se"

const localeCodeMap: Record<string, LocaleCode> = {
  en: "en-gb"
};

/**
 * Returns a locale code based on a given URL's slug/sub-page, from a fixed list of possible locales.
 * I.e. the url http://website.com/en/sub-page will return "en-gb" for the en sub-directory.
 * If no matching locale is found the default locale "sv-se" is returned.
 */
export const localeCodeFromUrl = (url: string): LocaleCode => {
  let localeCode: LocaleCode = "sv-se";

  // Add index signature to allow any string indexing
  const indexedLocaleCodeMap: Record<string, LocaleCode> = localeCodeMap;

  Object.keys(localeCodeMap).some(localeSlug => {
    const match = new RegExp(`/${localeSlug}($|/)`).test(url);
    localeCode = match ? indexedLocaleCodeMap[localeSlug] : localeCode;

    return match;
  });

  return localeCode;
};

export const localeCodeFromSlug = (slug: string): LocaleCode => {
  let localeCode: LocaleCode = "sv-se";

  if (slug in localeCodeMap) {
    return localeCodeMap[slug];
  } 

  return localeCode;
};