import { css, keyframes } from "styled-components"
import { TRANSITION_CURVE } from "../../constants"
import { scale } from "../../styles"

export const moveUp = keyframes`
	0% {
		opacity: 0;
		transform: translateY(${scale.px(4)});
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
`

export const moveLeft = keyframes`
	0% {
		opacity: 0;
		transform: translateX(${scale.px(-4)});
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
`

export const zoomIn = keyframes`
    0% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
`

export const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

export const zoomInImage = css`
	animation-name: ${zoomIn};
	animation-timing-function: ${TRANSITION_CURVE};
	animation-duration: 1s;
	animation-delay: 0.3s;
	animation-fill-mode: both;
`

export const fadeInContainer = css`
	animation-name: ${fadeIn};
	animation-timing-function: ${TRANSITION_CURVE};
	animation-duration: 1s;
	animation-delay: 0.3s;
	animation-fill-mode: both;
`

export const fadeInText = css`
	animation-name: ${fadeIn};
	animation-timing-function: ${TRANSITION_CURVE};
	animation-duration: 0.5s;
	animation-fill-mode: both;
`

export const revealContainerLarge = css`
	animation-name: ${moveLeft};
	animation-timing-function: cubic-bezier(0.789, 0.301, 0, 0.956);
	animation-duration: 1.8s;
	animation-fill-mode: both;
`

export const revealContainerSmall = css`
	animation-name: ${moveUp};
	animation-timing-function: cubic-bezier(0.789, 0.301, 0, 0.956);
	animation-duration: 1.8s;
	animation-fill-mode: both;
`
