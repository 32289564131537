const getFocusableElements = (containerElement: HTMLElement) => {
	const focusableElementSelectors: string =
		"input:enabled:not([type='hidden']), select:enabled, a[href], textarea:enabled, button:enabled, [tabindex]"
	const nodeList: NodeListOf<Element> = containerElement.querySelectorAll(
		focusableElementSelectors
	)
	let focusableElements: HTMLElement[] = []

	for (let i = 0; i < nodeList.length; i++) {
		focusableElements.push(nodeList[i] as HTMLElement)
	}

	return focusableElements
}

export default getFocusableElements
