import { colors, ColorKeys } from "./colors";

export type BorderColorKeys = Extract<
  ColorKeys,
  | "greybrown"
  | "black"
  | "white"
  | "greybrown-light"
  | "greybrown-medium"
  | "greybrown-dark"
  | "brown"
  | "red"
>;

export const borderColors: Record<BorderColorKeys, string> = {
  greybrown: colors["greybrown"],
  black: colors["black"],
  white: colors["white"],
  "greybrown-light": colors["greybrown-light"],
  "greybrown-medium": colors["greybrown-medium"],
  "greybrown-dark": colors["greybrown-dark"],
  brown: colors["brown"],
  red: colors["red"],
};

export const borderKeys = Object.keys(borderColors) as BorderColorKeys[];
