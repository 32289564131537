"use client";
import styled from "styled-components";
import { scale, breakpoint } from "../../styles";
import { Flex, Text } from "../../components";
import { VideoPlayerStyled } from "../VideoPlayer";
import { INNER_MAX_WIDTH, READABLE_LINE_MAX_WIDTH } from "../../constants";
import { zoomInImage, fadeInContainer, fadeInText } from "./animations";
import { PropsWithChildren } from "react";
import { tiemposHeadline } from "../../../../../app/fonts";

export interface HeroData {
  title: string;
  preamble?: string;
}

export interface HeroProps extends HeroData {
  media?: React.ReactNode;
  children?: React.ReactNode;
}

export interface HeroComponent {
  Footer: React.FC<PropsWithChildren>;
}

const HeroStyled = styled.div`
  position: relative;
  margin-bottom: ${scale.px(8)};
`;

const MediaBackground = styled.div`
  position: relative;

  &:after {
    content: " ";
    display: block;
    padding-bottom: 125%;
  }

  ${breakpoint(`m`)`
        &:after {
            display: none;
        }
    `}
`;

const Media = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img,
  video,
  ${VideoPlayerStyled} {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    &:after {
      display: none;
    }
  }

  img {
    transform: scale(1.1);
    ${zoomInImage}
  }

  ${breakpoint(`m`)`
        position: relative;
        height: 70vh;
    `}

  ${breakpoint(`l`)`
        height: 90vh;
    `}
`;

const Content = styled.div`
  position: relative;
  text-align: center;

  &:after {
    content: " ";
    display: none;
    position: absolute;
    width: 100%;
    height: calc(100% + ${scale.px(25)});
    top: -${scale.px(25)};
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      transparent 0%,
      ${({ theme }) => theme.primaryColor} 100%
    );
  }

  ${breakpoint(`m`)`
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: left;

        &:after {
            display: block;
        }
    `}
`;

const Main = styled.div`
  position: absolute;
  transform: translateY(-100%);
  padding: ${scale.px(4)} ${scale.px(2)};
  width: 100%;
  max-width: ${scale.px(INNER_MAX_WIDTH)};
  margin: 0 auto;

  &:after {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: calc(100% + ${scale.px(25)});
    top: -${scale.px(25)};
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      transparent 0%,
      ${({ theme }) => theme.primaryColor} 100%
    );
  }

  h1,
  p {
    position: relative;
    z-index: 1;
    ${fadeInText}
    transform: translateY(${scale.px(3)});
    opacity: 0;
    animation-delay: 1s;
  }

  p {
    animation-delay: 1.1s;
  }

  ${breakpoint(`s`)`
        padding: ${scale.px(4)} ${scale.px(5)};
    `}

  ${breakpoint(`m`)`
        position: relative;
        transform: translateY(0);
        z-index: 1;
        padding: ${scale.px(8)} ${scale.px(5)};

        &:after {
            display: none;
        }
    `}

	${breakpoint(`l`)`
        padding: ${scale.px(8)} ${scale.px(10)} ${scale.px(10)};
    `}
`;

const FooterStyled = styled.div`
  background: ${({ theme }) => theme.primaryColor};
  margin: 0 auto;
  padding: 0 ${scale.px(2)} ${scale.px(4)};
  max-width: ${scale.px(INNER_MAX_WIDTH)};

  > * {
    ${fadeInContainer}
    opacity: 0;
    animation-delay: 1.5s;
  }

  ${breakpoint(`s`)`
        padding: 0 ${scale.px(5)} ${scale.px(5)};
    `}

  ${breakpoint(`m`)`
        background: none;
        position: relative;
        z-index: 1;
        padding-bottom ${scale.px(8)};
        margin-top: -${scale.px(6)};
	`}
	
	${breakpoint(`l`)`
		padding: 0 ${scale.px(10)} ${scale.px(5)};
    `}
`;

const Hero: React.FC<HeroProps> & HeroComponent = (props) => {
  return (
    <HeroStyled>
      <MediaBackground>
        {props.media && <Media>{props.media}</Media>}
      </MediaBackground>
      <Content>
        <Main className={tiemposHeadline.className}>
          <Flex flexDirection="column" maxw={READABLE_LINE_MAX_WIDTH}>
            {props.title && (
              <Text variant="headline-1" as="h1" mb={1}>
                {props.title}
              </Text>
            )}

            {props.preamble && (
              <Text variant="preamble" fontSize={2.75} lineHeight={3.75} mb={0}>
                {props.preamble}
              </Text>
            )}
          </Flex>
        </Main>

        {props.children}
      </Content>
    </HeroStyled>
  );
};

Hero.Footer = (props) => <FooterStyled>{props.children}</FooterStyled>;

export { Hero };
