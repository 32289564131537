"use client";
import * as React from "react";
import styled from "styled-components";
import { colors, breakpoint, scale } from "../../styles";
import { Icon } from "../Icon";
import useSSR from "use-ssr";
import focusTrap from "../../utils/focusTrap";
import { TRANSITION_CURVE } from "../../constants/index";

export interface DrawerProps {
  isCollapsed?: boolean;
  closeButtonLabel?: string;
}

export interface DrawerComponent {
  Content: React.FC;
}

const DrawerStyled = styled.div.attrs<DrawerProps>((props) => ({
  "aria-hidden": props.isCollapsed ? `true` : `false`,
}))<DrawerProps>`
  background-color: ${colors["white"]};
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  bottom: 0;
  transform: ${(props) =>
    props.isCollapsed ? "translateY(100%)" : "translateY(0)"};
  overflow: ${(props) => (props.isCollapsed ? "hidden" : "auto")};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  transition: transform 0.7s ${TRANSITION_CURVE};

  ${breakpoint("l")`
		height: auto;
	`}
`;

const DrawerButtonStyle = styled.button`
  display: flex;
  justify-content: flex-end;
  width: calc(100% - ${scale.px(4)});
  border-bottom: 1px solid ${colors["grey-light"]};
  position: absolute;
  top: ${scale.px(2)};
  right: ${scale.px(2)};
  left: auto;

  ${breakpoint("m")`
		width: calc(100% - ${scale.px(10)});
		right: ${scale.px(5)};
	`}

  ${breakpoint("l")`
		right: ${scale.px(2)};
		width: auto;
	`}

	svg {
    transform: translateY(${scale.px(-0.5)});
  }
`;

export const DrawerContext = React.createContext({
  isCollapsed: true,
  toggleIsCollapsed: () => {},
  closeButtonLabel: "",
});

const Drawer: React.FC<DrawerProps> & DrawerComponent = (props) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const toggleIsCollapsed = () => setIsCollapsed((prev) => !prev);
  const closeButtonLabel = props?.closeButtonLabel
    ? props?.closeButtonLabel
    : "";
  const { isBrowser } = useSSR();

  React.useEffect(() => {
    if (isBrowser) {
      document.body.style.overflow = isCollapsed ? "auto" : "hidden";
      document.body.style.height = isCollapsed ? "auto" : "100%";
    }
  }, [isCollapsed, isBrowser]);

  return (
    <DrawerContext.Provider
      value={{ isCollapsed, toggleIsCollapsed, closeButtonLabel }}
      {...props}
    />
  );
};

Drawer.Content = function (props) {
  const { toggleIsCollapsed, isCollapsed, closeButtonLabel } =
    React.useContext(DrawerContext);

  const ref = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleFocusTrap =
    React.useRef<{
      activate: () => void;
      deactivate: () => void;
    } | null>(null);

  React.useEffect(() => {
    if (ref.current) {
      handleFocusTrap.current = focusTrap(ref.current);
      if (!isCollapsed) {
        handleFocusTrap.current.activate();
      }
    }
    return () => {
      if (handleFocusTrap.current) {
        handleFocusTrap.current.deactivate();
      }
    };
  }, [ref, isCollapsed]);

  return (
    <DrawerStyled isCollapsed={isCollapsed} ref={ref}>
      <DrawerButtonStyle onClick={toggleIsCollapsed}>
        {closeButtonLabel}
        <Icon icon={"chevron-down"} size={3} ml={1} />
      </DrawerButtonStyle>
      {props.children}
    </DrawerStyled>
  );
} as React.FC<React.PropsWithChildren>;

export { Drawer };
