import { css, keyframes } from "styled-components"

export const scalePrevBtnKeyframes = keyframes`
    0% {
        transform: translateY(-50%) translateX(0) scaleX(1);
    }

    30% {
        transform: translateY(-50%) translateX(-12px) scaleX(0);
    }

    60% {
        transform: translateY(-50%) translateX(40px) scaleX(0);
    }

    80% {
        transform: translateY(-50%) translateX(0) scaleX(1);
    }
`

export const movePrevBtnArrowKeyframes = keyframes`
    0% {
        transform: translateX(0px);
        opacity: 1;
    }

    5% {
        transform: translateX(0px);
        opacity: 1;
    }

    30% {
        transform: translateX(-24px);
        opacity: 0;
    }

    60% {
        transform: translateX(80px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
`

export const scaleNextBtnKeyframes = keyframes`
    0% {
        transform: translateY(-50%) translateX(0) scaleX(1);
    }

    30% {
        transform: translateY(-50%) translateX(12px) scaleX(0);
    }

    60% {
        transform: translateY(-50%) translateX(-40px) scaleX(0);
    }

    80% {
        transform: translateY(-50%) translateX(0) scaleX(1);
    }
`

export const moveNextBtnArrowKeyframes = keyframes`
    0% {
        transform: translateX(0px);
        opacity: 1;
    }

    5% {
        transform: translateX(0px);
        opacity: 1;
    }

    30% {
        transform: translateX(24px);
        opacity: 0;
    }

    60% {
        transform: translateX(-80px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
`

export const scalePrevBtn = css`
	animation-name: ${scalePrevBtnKeyframes};
	animation-timing-function: cubic-bezier(0.55, 0.003, 0.164, 0.991);
	animation-duration: 1s;
	animation-delay: 0.1s;
	animation-fill-mode: forwards;
`

export const movePrevBtnArrow = css`
	animation-name: ${movePrevBtnArrowKeyframes};
	animation-timing-function: cubic-bezier(0.55, 0.003, 0.164, 0.991);
	animation-duration: 1s;
	animation-fill-mode: forwards;
`

export const scaleNextBtn = css`
	animation-name: ${scaleNextBtnKeyframes};
	animation-timing-function: cubic-bezier(0.55, 0.003, 0.164, 0.991);
	animation-duration: 1s;
	animation-delay: 0.1s;
	animation-fill-mode: forwards;
`

export const moveNextBtnArrow = css`
	animation-name: ${moveNextBtnArrowKeyframes};
	animation-timing-function: cubic-bezier(0.55, 0.003, 0.164, 0.991);
	animation-duration: 1s;
	animation-fill-mode: forwards;
`
