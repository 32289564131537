"use client";
import React, { PropsWithChildren, MutableRefObject } from "react";
import { ThemeContext } from "styled-components";
import { INNER_MAX_WIDTH } from "../../constants";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { ScrollPanelStyled, Inner, NavButton } from "./styled";
import { useIsomorphicLayoutEffect } from "../../hooks/useIsomorphicLayoutEffect";

export const ScrollPanelContext = React.createContext({
  innerWidth: 0,
  slideWidth: 0,
  hasOverflow: false,
  innerRef: { current: null } as MutableRefObject<HTMLDivElement | null>,
  scrollPanelRef: { current: null } as MutableRefObject<HTMLDivElement | null>,
  scrollAtEnd: false,
  scrollAtStart: true,
  isTouch: false,
  scrollToSlide: (direction: "prev" | "next"): void => {},
});

export interface ScrollPanelProps {
  slideRef: React.MutableRefObject<HTMLDivElement>;
  children?: React.ReactNode;
}

export interface ScrollPanelMembers {
  Content: React.FC<PropsWithChildren>;
  Navigation: React.FC<ScrollPanelNavigationProps>;
}

const ScrollPanel: React.FC<ScrollPanelProps> & ScrollPanelMembers = (
  props
) => {
  // measurements
  const [innerWidth, setInnerWidth] = React.useState(0);
  const [slideWidth, setSlideWidth] = React.useState(0);
  // refs
  const innerRef = React.useRef<HTMLDivElement>(null);
  const scrollPanelRef = React.useRef<HTMLDivElement>(null);
  // bools
  const [hasOverflow, setHasOverflow] = React.useState(false);
  const [scrollAtEnd, setScrollAtEnd] = React.useState(false);
  const [scrollAtStart, setScrollAtStart] = React.useState(true);
  const [isTouch, setIsTouch] = React.useState(false);

  // take measurements
  useIsomorphicLayoutEffect(() => {
    const currentInnerRef = innerRef.current;
    const currentSlideRef = props.slideRef.current;

    if (currentInnerRef && currentSlideRef) {
      const resizeHandler = () => {
        setInnerWidth(currentInnerRef.scrollWidth);
        setSlideWidth(currentSlideRef.scrollWidth);
      };

      resizeHandler();
      window.addEventListener("resize", resizeHandler);

      return () => {
        window.removeEventListener("resize", resizeHandler);
      };
    }
  }, [props.slideRef, innerRef]);

  // check overflow
  React.useEffect(() => {
    const currentScrollPanelRef = scrollPanelRef.current;

    if (currentScrollPanelRef) {
      const resizeHandler = () => {
        setHasOverflow(
          currentScrollPanelRef.scrollWidth > currentScrollPanelRef.offsetWidth
        );
      };

      resizeHandler();
      window.addEventListener("resize", resizeHandler);

      return () => {
        window.removeEventListener("resize", resizeHandler);
      };
    }
  }, [scrollPanelRef]);

  // check if scroll is at start or end
  React.useEffect(() => {
    const ref = scrollPanelRef.current;
    if (ref) {
      const scrollListener = (e: Event) => {
        setScrollAtEnd(ref.offsetWidth + ref.scrollLeft === ref.scrollWidth);
        setScrollAtStart(ref.scrollLeft === 0);
      };

      ref.addEventListener("scroll", scrollListener);

      return () => {
        ref.removeEventListener("scroll", scrollListener);
      };
    }
  }, [scrollPanelRef]);

  // check if scroll is at start or end
  React.useEffect(() => {
    const ref = scrollPanelRef.current;
    if (ref) {
      const touchStartListener = (e: Event) => {
        setIsTouch(() => true);
        ref.removeEventListener("scroll", touchStartListener);
      };
      ref.addEventListener("touchstart", touchStartListener);
      return () => ref.removeEventListener("scroll", touchStartListener);
    }
  }, [scrollPanelRef]);

  const scrollToSlide = (direction: "prev" | "next") => {
    setIsTouch(() => false);

    if (scrollPanelRef.current) {
      const scrollTarget =
        direction === "next"
          ? Math.ceil((scrollPanelRef.current.scrollLeft + 1) / slideWidth) *
            slideWidth
          : Math.floor((scrollPanelRef.current.scrollLeft - 1) / slideWidth) *
            slideWidth;

      scrollPanelRef.current.scroll({
        left: scrollTarget,
        behavior: "smooth",
      });
    }
  };

  return (
    <ScrollPanelContext.Provider
      value={{
        innerWidth,
        slideWidth,
        hasOverflow,
        innerRef,
        scrollPanelRef,
        scrollAtEnd,
        scrollAtStart,
        isTouch,
        scrollToSlide,
      }}
    >
      {props.children}
    </ScrollPanelContext.Provider>
  );
};

export const Content: React.FC<React.PropsWithChildren> = (props) => {
  const { scrollPanelRef, isTouch, innerRef } =
    React.useContext(ScrollPanelContext);

  return (
    <Flex flexDirection="column" alignItems="flex-end" w={1}>
      <ScrollPanelStyled ref={scrollPanelRef} scrollAuto={isTouch}>
        <Inner ref={innerRef}>{props.children}</Inner>
      </ScrollPanelStyled>
    </Flex>
  );
};

export interface ScrollPanelNavigationData {
  prevButtonLabel?: string;
  nextButtonLabel?: string;
}

export interface ScrollPanelNavigationProps extends ScrollPanelNavigationData {}

export const Navigation = function (props) {
  const { scrollAtEnd, scrollAtStart, hasOverflow, scrollToSlide } =
    React.useContext(ScrollPanelContext);

  const theme = React.useContext(ThemeContext);

  return (
    <>
      {hasOverflow && theme?.textColor && (
        <Flex maxw={INNER_MAX_WIDTH}>
          <Flex ml={2} mr={{ _: 0, m: 2 }}>
            <NavButton
              aria-label={props.prevButtonLabel || undefined}
              disabled={scrollAtStart}
              onClick={() => scrollToSlide("prev")}
              mr={2}
            >
              <Icon size={5} icon="arrow-long-left" color={theme.textColor} />
            </NavButton>
            <NavButton
              aria-label={props.nextButtonLabel || undefined}
              disabled={scrollAtEnd}
              onClick={() => scrollToSlide("next")}
            >
              <Icon size={5} icon="arrow-long-right" color={theme.textColor} />
            </NavButton>
          </Flex>
        </Flex>
      )}
    </>
  );
} as React.FC<ScrollPanelNavigationProps>;

ScrollPanel.Content = Content;
ScrollPanel.Navigation = Navigation;

export { ScrollPanel };
