"use client";
import * as React from "react";
import styled, { WebTarget } from "styled-components";
import { scale, colors, breakpoint, fontWeight } from "../../styles";
import focusTrap from "../../utils/focusTrap";

export interface MegaMenuToggleData {
  closeLabel: string;
}

export interface MegaMenuProps {
  id?: string;
  open: boolean;
  menu?: any;
  aside?: any;
  ariaLabel?: string;
}

export interface MegaMenuToggleProps extends MegaMenuProps, MegaMenuToggleData {
  onClick: () => void;
}

export interface MegaMenuComponent {
  Toggle: React.FC<MegaMenuToggleProps>;
}

const MegaMenuStyled = styled.div<MegaMenuProps>`
  display: none;
  position: absolute;
  top: ${scale.px(9)};
  width: 100%;
  background-color: ${colors["greybrown"]};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  height: ${(props) => (props.open ? "auto" : 0)};
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  overflow: hidden;
  z-index: 100;

  li > a {
    color: ${colors["black-light"]};
  }

  ${breakpoint("l")`
		display: block;
	`}
`;

const MegaMenuInner = styled.div`
  padding: ${scale.px(8)} ${scale.px(8)} ${scale.px(5)};
`;

const MegaMenuToggle = styled.button
  .attrs<MegaMenuToggleProps>((props) => ({
    "aria-controls": props.id || "mega-menu",
    "aria-expanded": props.open,
    "aria-label": props.ariaLabel || "Stäng menyn",
  }))
  .withConfig({
    shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
      !["closeLabel"].includes(prop),
  })<MegaMenuToggleProps>`
  position: absolute;
  top: ${scale.px(8)};
  right: ${scale.px(8)};
  opacity: 0;
  font-size: ${scale.px(2)};
  font-weight: ${fontWeight["bold"]};
  text-transform: uppercase;
  text-decoration: underline;
  padding: ${scale.px(2)} 0;

  &.focus-visible {
    opacity: 1;
  }
`;

const MegaMenu: React.FC<React.PropsWithChildren<MegaMenuProps>> &
  MegaMenuComponent = (props) => {
  const ref = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleFocusTrap = React.useRef<{
    activate: () => void;
    deactivate: () => void;
  } | null>(null);

  React.useEffect(() => {
    if (ref.current) {
      handleFocusTrap.current = focusTrap(ref.current);
      handleFocusTrap.current.activate();
    }

    return () => {
      handleFocusTrap.current?.deactivate();
    };
  }, [ref, props.open]);

  return (
    <MegaMenuStyled ref={ref} id="mega-menu" {...props}>
      <MegaMenuInner>{props.children}</MegaMenuInner>
    </MegaMenuStyled>
  );
};

MegaMenu.Toggle = function (props) {
  return <MegaMenuToggle {...props}>{props.closeLabel}</MegaMenuToggle>;
} as React.FC<MegaMenuToggleProps>;

export { MegaMenu };
