"use client";
import React from "react";
import styled, { WebTarget } from "styled-components";
import { scale, breakpoint } from "../../styles";
import { PromoStyled } from "../Promo";
import { VideoPlayerStyled } from "../VideoPlayer";

export interface PageGridProps {
  mirrorLayout?: boolean;
  children?: React.ReactNode;
}

export interface PageGridStyledProps extends PageGridProps {
  count: number;
}

const PageGridStyled = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["mirrorLayout"].includes(prop),
})<PageGridStyledProps>`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: ${(props) =>
    props.count === 1
      ? "1fr"
      : props.count === 2
      ? `100vw ${scale.px(36)}`
      : props.count === 3
      ? `100vw ${scale.px(36)} ${scale.px(14)}`
      : `100vw ${scale.px(36)} ${scale.px(14)} ${scale.px(14)}`};
  grid-column-gap: 2px;
  grid-row-gap: 2px;

  & > * {
    position: relative;
    grid-column: 1 / 3;

    ${PromoStyled},
    ${VideoPlayerStyled} {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    ${VideoPlayerStyled} {
      &:after {
        display: none;
      }
    }
  }

  & > *:nth-child(1) {
    grid-row: 1 / 2;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }

  & > *:nth-child(2) {
    grid-row: 2 / 3;
  }

  & > *:nth-child(3) {
    grid-row: 3 / 4;
  }

  & > *:nth-child(4) {
    grid-row: 4 / 5;
  }

  ${breakpoint("s")<PageGridStyledProps>`
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: ${(props) =>
      props.count === 1
        ? "1fr"
        : props.count === 2
        ? `100vw ${scale.px(36)}`
        : props.count === 3
        ? `100vw ${scale.px(36)} ${scale.px(36)}`
        : `100vw ${scale.px(36)} ${scale.px(14)} ${scale.px(14)}`};

		& > *:nth-child(3) {
			grid-row: ${(props) => (props.count === 3 ? `3 / 4` : `3 / 5`)};
			grid-column: ${(props) => (props.count === 3 ? `1 / 3` : `1 / 2`)};
		}

		& > *:nth-child(4) {
			grid-row: 3 / 5;
			grid-column: 2 / 3;
		}
	`}

  ${breakpoint("l")<PageGridStyledProps>`
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr;

		& > *:nth-child(1) {
			grid-column: ${(props) =>
        props.mirrorLayout
          ? props.count >= 2
            ? `3 / 5`
            : `1 / 5`
          : props.count >= 2
          ? `1 / 3`
          : `1 / 5`};
			grid-row: 1 / 3;

			&:after {
				content: "";
				display: block;
				padding-bottom: ${(props) => (props.count === 1 ? "48.78%" : "100%")};
			}
		}

		& > *:nth-child(2) {
			grid-column: ${(props) => (props.mirrorLayout ? `1 / 3` : `3 / 5`)};
			grid-row: ${(props) => (props.count >= 3 ? `1 / 2` : `1 / 3`)};
		}

		& > *:nth-child(3) {
			grid-column: ${(props) =>
        props.mirrorLayout
          ? props.count === 3
            ? `1 / 3`
            : `2 / 3`
          : props.count === 3
          ? `3 / 5`
          : `3 / 4`};
			grid-row: 2;
		}

		& > *:nth-child(4) {
			grid-column: ${(props) => (props.mirrorLayout ? `1 / 2` : `4 / 5`)};
			grid-row: 2;
		}
	`}
`;

const PageGrid: React.FC<PageGridProps> = ({
  children,
  mirrorLayout = false,
}) => {
  return (
    <PageGridStyled
      count={React.Children.count(children)}
      mirrorLayout={mirrorLayout}
    >
      {children}
    </PageGridStyled>
  );
};

export { PageGrid };
