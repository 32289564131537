const noForwardProps = [
  "active",
  "alignItems",
  "ariaControls",
  "backgroundColor",
  "bgColor",
  "bgGradient",
  "border",
  "borderBottom",
  "borderColor",
  "borderRadius",
  "borderRight",
  "borderWidth",
  "closeLabel",
  "current",
  "dayMonth",
  "dayNumber",
  "desktopImageSrc",
  "display",
  "expanded",
  "fetchPriority",
  "flexBasis",
  "flexDirection",
  "flexGrow",
  "flexWrap",
  "fontFamily",
  "fontSize",
  "fontWeight",
  "height",
  "icon",
  "iconPosition",
  "image",
  "innerRef",
  "isShow",
  "justifyContent",
  "letterSpacing",
  "lineHeight",
  "mb",
  "mirrorLayout",
  "ml",
  "mobileImageSrc",
  "more",
  "mr",
  "mt",
  "nextSlide",
  "noBorder",
  "order",
  "overflow",
  "overflowLeft",
  "overflowRight",
  "participantsLabel",
  "pb",
  "photoLabel",
  "previousSlide",
  "pt",
  "px",
  "py",
  "rounded",
  "showFade",
  "size",
  "stretch",
  "textAlign",
  "textColor",
  "textTransform",
  "themeColorVariant",
  "toggleLabel",
  "useImagePadding",
  "variant",
  "width",
  "withChevron",
];

export default noForwardProps;
