"use client";
import * as React from "react";
import styled, { WebTarget } from "styled-components";
import { colors, scale } from "../../styles";
import { Button } from "../Button";
import { Flex } from "../Flex";
import focusTrap from "../../utils/focusTrap";
import { AnimatePresence, motion } from "framer-motion";
import { createPortal } from "react-dom";
import useSSR from "use-ssr";

export interface ModalProps {
  portalId?: string;
  modalId: string;
  isOpen: boolean;
  lastFocusedElement?: HTMLElement;
  handleClose: () => void;
  children?: React.ReactNode;
}

const ModalStyled = styled(motion.div)`
  position: fixed;
  display: flex;
  flex-direction: column;
  inset: 0;
  background-color: ${colors.black};
  overflow-y: scroll;
  z-index: 999;
`;

const CloseButtonStyled = styled(Button).withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["backgroundColor", "iconPosition"].includes(prop),
})`
  align-self: flex-end;
  margin: ${scale.px(2)};
  svg {
    width: ${scale.px(3)};
    height: ${scale.px(3)};
  }
`;

const ModalInnerStyled = styled(Flex)`
  align-self: center;
`;

const Modal: React.FC<ModalProps> = ({
  portalId = "#modal-portal-wrapper",
  modalId,
  isOpen,
  lastFocusedElement,
  handleClose,
  children,
}) => {
  const { isBrowser } = useSSR();

  const handleFocusTrap = React.useRef<{
    activate: () => void;
    deactivate: () => void;
  } | null>(null);

  const ref = React.useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      handleFocusTrap.current = focusTrap(node);
      handleFocusTrap.current.activate();
    }
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      if (isBrowser) {
        document.body.style.overflow = "hidden";
      }
    }
    return () => {
      if (isBrowser) {
        document.body.style.overflow = "unset";
      }
    };
  }, [isOpen, isBrowser]);

  const returnFocusOnClose = React.useCallback(() => {
    if (typeof lastFocusedElement === "object") {
      return lastFocusedElement.focus();
    }
  }, [lastFocusedElement]);

  React.useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) =>
      e.key === "Escape" ? (handleClose(), returnFocusOnClose()) : null;
    if (isBrowser) {
      document.body.addEventListener("keydown", closeOnEscapeKey);
    }
    return () => {
      if (isBrowser) {
        document.body.removeEventListener("keydown", closeOnEscapeKey);
      }
    };
  }, [handleClose, returnFocusOnClose, isBrowser]);

  if (!isBrowser) return null;

  const modalPortalWrapper = document.querySelector(portalId);

  if (modalPortalWrapper) {
    return createPortal(
      <AnimatePresence>
        {isOpen && (
          <ModalStyled
            key={modalId}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { ease: [0.789, 0.301, 0, 0.956], duration: 0.7 },
            }}
            exit={{
              opacity: 0,
              transition: { ease: [0.789, 0.301, 0, 0.956], duration: 1 },
            }}
            id={modalId}
            aria-modal="true"
            aria-hidden={!isOpen ? "true" : "false"}
            role="dialog"
            ref={ref}
          >
            <CloseButtonStyled
              backgroundColor="transparent"
              textColor={"white"}
              icon="close"
              size="icon"
              iconPosition="center"
              onClick={() => {
                handleClose();
                returnFocusOnClose();
              }}
              aria-controls={modalId}
            />
            <ModalInnerStyled w={1} alignItems="center" justifyContent="center">
              {children}
            </ModalInnerStyled>
          </ModalStyled>
        )}
      </AnimatePresence>,
      modalPortalWrapper
    );
  }
};

export { Modal };
