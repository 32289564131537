"use client";
import * as React from "react";
import styled, { WebTarget, useTheme } from "styled-components";
import { colors, scale, breakpoint } from "../../styles";
import {
  Text,
  TextProps,
  Svg,
  Icon,
  IconTypes,
  Flex,
  LayoutProps,
} from "../../components";
import { motion } from "framer-motion";
import { SpaceProps, space, variant } from "styled-system";

export type TabsVariant = "default" | "ghost";
export type PanelAnimation = "opacity" | "opacity-y-transform";

export interface TabsProps {
  activeTab?: string; // Used to set default open tab
  initialActiveTab?: string;
  controlled?: boolean;
}

export interface TabStyledProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, "onClick"> {
  onClick?: (id: string) => void;
  expanded?: boolean;
  variant?: TabsVariant;
}
export interface TabProps extends SpaceProps, TabStyledProps {
  id: string;
}

export interface PanelProps {
  id: string;
  variant?: TabsVariant;
  animation?: PanelAnimation;
}

export interface TabsComponent {
  TabsContainer: typeof TabsContainer;
  Tab: typeof Tab;
  IconTab: typeof IconTab;
  Label: typeof Label;
  Panel: typeof Panel;
}

const TabsContext = React.createContext({
  activeTab: "",
  setActiveTab: (id: string) => {},
  controlled: false,
});

export const useTabsActiveTab = (initialTabId?: string) => {
  const [activeTab, setActiveTab] = React.useState(initialTabId || "");
  return { activeTab, setActiveTab };
};

const Tabs: React.FC<React.PropsWithChildren<TabsProps>> & TabsComponent = (
  props
) => {
  const { activeTab, setActiveTab } = useTabsActiveTab(props.activeTab || "");
  const internalActiveTab = props.controlled
    ? props.activeTab || ""
    : activeTab;

  return (
    <TabsContext.Provider
      value={{
        activeTab: internalActiveTab,
        setActiveTab,
        controlled: props.controlled || false,
      }}
    >
      {props.children}
    </TabsContext.Provider>
  );
};

const TabsContainer: React.FC<React.PropsWithChildren<LayoutProps>> = (
  props
) => {
  const theme = useTheme();
  return <Flex {...props} borderColor={theme.dark ? "white" : "black"} />;
};

const TabStyled = styled.button
  .withConfig({
    shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
      !["expanded"].includes(prop),
  })
  .attrs<TabStyledProps>((props) => ({
    "aria-expanded": props.expanded,
  }))<TabStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  background-color: ${(props) =>
    props.expanded ? colors["greybrown"] : colors["brown-light"]};
  margin-left: ${scale.px(2.5)};
  padding: ${scale.px(1)} ${scale.px(2)};
  min-height: ${scale.px(7)};
  text-align: center;
  ${space}

  ${variant({
    variants: {
      ghost: {
        backgroundColor: "transparent",
        justifyContent: "flex-start",
        margin: 0,
        padding: 0,
        minHeight: "auto",
      },
    },
  })}	

	&:first-child {
    margin-left: 0;
  }

  &:after {
    display: ${(props) => (props.variant === "ghost" ? `block` : `none`)};
    content: "";
    background-color: ${({ theme }) =>
      theme.dark ? colors["white"] : colors["black"]};
    position: absolute;
    bottom: ${scale.px(-1)};
    left: 50%;
    height: 5px;
    width: 100%;
    transform: ${(props) =>
      props.expanded
        ? `translateX(-50%) scaleX(1)`
        : `translateX(-50%) scaleX(0)`};
    transition: transform 0.5s cubic-bezier(0.789, 0.301, 0, 0.956);
  }

  &:hover {
    &:after {
      transform: translateX(-50%) scaleX(1);
    }
  }

  ${breakpoint("m")`
		padding: ${scale.px(1.5)} ${scale.px(2)};

		${variant({
      variants: {
        ghost: {
          padding: 0,
        },
      },
    })}	
	`}
` as React.FC<TabStyledProps>;

const Tab: React.FC<TabProps> = ({ children, id, onClick, ...rest }) => {
  const { setActiveTab, activeTab } = React.useContext(TabsContext);
  const expanded = id === activeTab;

  return (
    <TabStyled
      onClick={
        onClick
          ? () => onClick(id)
          : () => {
              setActiveTab(id);
            }
      }
      expanded={expanded}
      {...rest}
    >
      {children}
    </TabStyled>
  );
};

const IconTabStyled = styled.button
  .withConfig({
    shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
      !["expanded"].includes(prop),
  })
  .attrs<TabStyledProps>((props) => ({
    "aria-expanded": props.expanded,
  }))<TabStyledProps>`
  white-space: nowrap;
  padding: ${scale.px(3)} ${scale.px(4)};
  display: flex;
  align-items: center;

  > .label {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: ${scale.px(3.5)};
    font-size: ${scale.px(2.25)};
    color: ${({ theme }) => (theme.dark ? colors.white : colors.black)};
  }

  &:hover,
  &.--active {
    background: ${({ theme }) => (theme.dark ? colors.white : colors.black)};

    > .label {
      color: ${({ theme }) => (theme.dark ? colors.black : colors.white)};
    }

    > ${Svg} path {
      fill: ${({ theme }) => (theme.dark ? colors.black : colors.white)};
    }
  }
` as React.FC<TabStyledProps>;

interface IconTabProps extends TabProps {
  icon: IconTypes;
}

const IconTab: React.FC<IconTabProps> = ({
  id,
  icon,
  children,
  onClick,
  ...rest
}) => {
  const { setActiveTab, activeTab } = React.useContext(TabsContext);
  const expanded = id === activeTab;
  const { textColor } = useTheme();

  return (
    <IconTabStyled
      onClick={
        onClick
          ? () => onClick(id)
          : () => {
              setActiveTab(id);
            }
      }
      className={expanded ? "--active" : undefined}
      expanded={expanded}
      {...rest}
    >
      <Icon minw={3} mr={1.25} icon={icon} color={textColor} />
      <span className="label">{children}</span>
    </IconTabStyled>
  );
};

const Label: React.FC<TextProps> = (props) => (
  <Text
    textTransform="uppercase"
    mb={0}
    fontSize={{ _: 1.75, m: 2, xxl: 2.5 }}
    lineHeight={{ _: 2.25, m: 2.5, xxl: 3 }}
    fontWeight="semibold"
    letterSpacing="0.1875"
    {...props}
  />
);

const Panel: React.FC<React.PropsWithChildren<PanelProps>> = (props) => {
  const { activeTab } = React.useContext(TabsContext);
  const defaultPanelAnimation = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
  };
  const transformPanelAnimation = {
    initial: {
      y: 56,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
    },
  };

  return activeTab === props.id ? (
    <motion.div
      style={{ width: "100%" }}
      variants={
        props.animation === "opacity-y-transform"
          ? transformPanelAnimation
          : defaultPanelAnimation
      }
      initial="initial"
      animate="animate"
      transition={{ ease: [0.22, 0.17, 0, 0.94], duration: 0.5 }}
    >
      {props.children}
    </motion.div>
  ) : null;
};

Tabs.TabsContainer = TabsContainer;
Tabs.Tab = Tab;
Tabs.IconTab = IconTab;
Tabs.Label = Label;
Tabs.Panel = Panel;

export { Tabs };
