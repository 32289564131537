import * as React from "react";
import { iconMap } from "./IconMap";
import { IconTypes } from "./types";
import { SpaceProps } from "../../styles/styled-system";
import { ColorKeys } from "../../styles/constants";
import { Svg, SvgProps } from "../";

export interface IconProps extends SpaceProps, SvgProps {
  /**
   * identifier of which icon to use
   */
  icon: IconTypes;
  color?: ColorKeys;
}

const Icon: React.FC<IconProps & SvgProps> = ({
  color = "black",
  size = 3,
  icon = "arrow-up",
  ...props
}) => {
  const { space, ...rest } = props;

  const elementProps = {
    color,
    ...rest,
  };

  return (
    <Svg size={size} viewBox="0 0 24 24" color={color} {...rest}>
      {React.createElement(iconMap[icon], elementProps)}
    </Svg>
  );
};

export { Icon };
