"use client";
import * as React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { scale, breakpoint, colors } from "../../styles";

export interface SnackbarProps {
  alert: string;
  actionLabel: string;
  onActionClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const SnackbarStyled = styled(motion.div)`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: ${scale.px(11)};
  left: ${scale.px(2)};
  padding: ${scale.px(2)};
  padding-right: 0;
  font-size: ${scale.px(2)};
  line-height: ${scale.px(2)};
  background-color: ${colors["pink-light"]};
  color: ${colors["black"]};
  max-width: ${scale.px(38)};
  z-index: 100;

  span {
    margin-right: ${scale.px(3)};
  }

  ${breakpoint("l")`
		bottom: ${scale.px(17)};
		max-width: none;

		span {
			margin-right: ${scale.px(5)};
		}
	`}
`;

const SnackbarAction = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${scale.px(2)};
  color: ${colors["greybrown-dark"]};
  text-decoration: underline;

  &:hover {
    color: ${colors["black"]};
  }
`;

const Snackbar: React.FC<SnackbarProps> = (props) => {
  const ref = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    if (ref.current && props.alert !== "") {
      setTimeout(() => {
        if (ref.current) {
          ref.current.innerText = props.alert;
        }
      }, 10);
    }
  }, [ref, props.alert]);

  return (
    <SnackbarStyled
      initial={{ y: 16 }}
      animate={{ y: 0 }}
      exit={{ y: 16 }}
      transition={{ ease: [0.789, 0.301, 0, 0.956], duration: 0.2 }}
      onMouseEnter={() => props.onMouseEnter && props.onMouseEnter()}
      onMouseLeave={() => props.onMouseLeave && props.onMouseLeave()}
    >
      <span ref={ref} role="status" aria-live="polite"></span>

      <SnackbarAction onClick={props.onActionClick}>
        {props.actionLabel}
      </SnackbarAction>
    </SnackbarStyled>
  );
};

export { Snackbar };
