"use client";
import * as React from "react";
import styled, { WebTarget } from "styled-components";
import {
  typography,
  TypographyProps,
  border,
  BorderProps,
} from "../../styles/styled-system";
import { scale, colors, fontWeight } from "../../styles";
import { Icon, LayoutProps } from "../";
import { ThemeContext } from "styled-components";

export interface SelectOption {
  label: string;
  value: string | null;
}
export interface SelectProps
  extends TypographyProps,
    BorderProps,
    Omit<React.HTMLProps<HTMLSelectElement>, "size" | "width">,
    Omit<LayoutProps, "as"> {
  placeholder?: string;
  id?: string;
  options: SelectOption[];
  label?: string;
}

const SelectHolderStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const SelectStyled = styled.select.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["lineHeight", "borderColor", "borderBottom"].includes(prop),
})`
  display: block;
  width: 100%;
  position: relative;
  border: 0;
  padding: 0 ${scale.px(2.5)} 0 0;
  color: ${({ theme }) => (theme.dark ? colors["white"] : colors["black"])};
  font-weight: ${fontWeight["semibold"]};
  overflow: hidden;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::-ms-expand {
    display: none;
  }

  ${typography}
  ${border}

	option {
    color: ${colors["black"]};
  }
`;

const OptionStyled = styled.option`
`;

const IconStyled = styled.span`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
`;

const SelectLabelStyled = styled.label`
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  height: 1px;
  color: ${colors["black"]};
`;

const Select: React.FC<SelectProps> = (props) => {
  const { id, placeholder, options, ref, as, size, label, ...rest } = props;
  const theme = React.useContext(ThemeContext);
  const defaultValue = options[0].value;

  return (
    <SelectHolderStyled>
      {label && <SelectLabelStyled htmlFor={id}>{label}</SelectLabelStyled>}
      <SelectStyled
        id={id}
        defaultValue={
          placeholder ? 0 : defaultValue ? defaultValue : undefined
        }
        {...rest}
      >
        {placeholder && (
          <option disabled value="0">
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <OptionStyled
            key={`${option.label}-${option.value}-key`}
            value={option.value || ""}
          >
            {option.label}
          </OptionStyled>
        ))}
      </SelectStyled>

      {theme && (
        <IconStyled>
          <Icon
            icon="chevron-down"
            size={2.5}
            color={theme.dark ? "white" : "black"}
          />
        </IconStyled>
      )}
    </SelectHolderStyled>
  );
};

export { Select };
