"use client";
import * as React from "react";
import Link, { LinkProps } from "next/link";
import styled, { CSSProperties, css, WebTarget } from "styled-components";
import {
  variant,
  ResponsiveValue,
  display,
  DisplayProps,
  alignItems,
  AlignItemsProps,
} from "styled-system";
import {
  headingFontFamily,
  variants,
  Variants,
} from "../../styles/constants/typography";
import { typography, TypographyProps } from "../../styles/styled-system";
import noForwardProps from "../../../../../utils/restrictPropsToDOM";

export interface TextProps
  extends TypographyProps,
    DisplayProps,
    AlignItemsProps {
  variant?: Variants;
  as?: any;
  truncate?: ResponsiveValue<"singleLine" | "twoLine" | "threeLine" | "none">;
  style?: CSSProperties;
  children?: React.ReactNode;
}

export interface TextComponent {
  Anchor: React.FC<
    TextProps & Omit<React.HTMLProps<HTMLAnchorElement>, "size">
  >;
  Link: React.FC<React.PropsWithChildren<TextProps & LinkProps>>;
}

export const textStyles = css<TextProps>`
  ${typography}
  ${display}
	${alignItems}
  ${(props) =>
    typography({
      ...props,
      textColor: props.textColor ? props.textColor : props.theme.textColor,
      theme: props.theme,
    })}
	${variant({
    prop: "truncate",
    variants: {
      singleLine: {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      twoLine: {
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-line-clamp": "2",
        "-webkit-box-orient": "vertical",
      },
      threeLine: {
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-line-clamp": "3",
        "-webkit-box-orient": "vertical",
      },
      none: {
        overflow: "visisble",
        display: "inline",
        "-webkit-line-clamp": "none",
        "-webkit-box-orient": "initial",
        whiteSpace: "normal",
      },
    },
  })}
`;

/**
 * Text element
 */
export const TextStyled = styled.p.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})`
  ${textStyles}
`;

export const Text: React.FC<
  TextProps & Omit<React.HTMLProps<HTMLParagraphElement>, "size">
> &
  TextComponent = ({ variant = "paragraph-1", ...rest }) => {
  const isSanSerif = variant === "paragraph-1" || variant === "preamble" && rest.as !== "h2";
  return (
    <TextStyled
      {...variants[variant]}
      {...rest}
      className={!isSanSerif ? headingFontFamily : undefined}
    />
  );
};

/**
 * Link element
 */
const LinkStyled = styled(Link).withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    ![
      "fontSize",
      "fontWeight",
      "fontFamily",
      "letterSpacing",
      "textTransform",
      "active",
      "textColor",
      "lineHeight",
    ].includes(prop),
})`
  ${textStyles}
`;
Text.Link = ({ variant = "paragraph-1", ...rest }) => (
  <LinkStyled {...variants[variant]} as={Link} {...rest} />
);

/**
 * Anchor element
 */
export const AnchorStyled = styled.a.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["textColor", "lineHeight"].includes(prop),
})`
  ${textStyles}
`;

Text.Anchor = ({ variant = "paragraph-1", ...rest }) => (
  <AnchorStyled {...variants[variant]} as="a" {...rest} />
);
