"use client";
import * as React from "react";
import { LinkProps } from "next/link";
import styled, { WebTarget, css } from "styled-components";
import { scale, colors, breakpoint, fontWeight } from "../../styles";
import {
  HeaderStyled as AccordionHeader,
  PanelInnerStyled as AccordionPanelInner,
  ToggleStyled as AccordionToggle,
} from "../Accordion/styled";

export interface InfoBoxCTAProps {
  label: string;
}

export interface InfoBoxComponent {
  List: React.FC<React.PropsWithChildren>;
  Item: React.FC<React.PropsWithChildren>;
  ItemIcon: React.FC<React.PropsWithChildren>;
  Cta: React.FC<InfoBoxCTAProps>;
  CtaLink: React.FC<InfoBoxCTAProps & LinkProps>;
  CtaExternalLink: React.FC<
    InfoBoxCTAProps & React.HTMLProps<HTMLAnchorElement>
  >;
}

export interface InfoBoxProps {
  innerRef?: React.Ref<any>;
  children?: React.ReactNode;
}

export const InfoBoxStyled = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["innerRef"].includes(prop),
})`
  position: relative;
  padding: ${scale.px(3)};
  background-color: ${({ theme }) =>
    theme.dark ? `${colors["white"]}` : `${colors["black"]}`};
  width: 100%;
  z-index: 1;

  ${breakpoint("l")`
		padding: ${scale.px(4)};
		max-width: ${scale.px(60)};
    `};
`;

export const InfoBoxList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const InfoBoxListItem = styled.li`
  padding: ${scale.px(1.5)} 0;
  border-bottom: 1px solid ${colors["greybrown-dark"]};
  color: ${({ theme }) =>
    theme.dark ? `${colors["black"]}` : `${colors["black"]}`};

  &:first-of-type {
    padding-top: 0;
  }

  p {
    margin-bottom: 0;
  }

  /* Accordion specific styling */
  ${AccordionHeader} {
    padding: 0;
    height: auto;
    border-bottom: none;
  }

  ${AccordionPanelInner} {
    padding: ${scale.px(1)} 0;
    color: ${({ theme }) =>
      theme.dark ? `${colors["black"]}` : `${colors["white"]}`};
    font-size: ${scale.px(2)};
    line-height: ${scale.px(3)};
  }

  ${AccordionToggle} {
    border-left: none;

    &:before,
    &:after {
      background-color: ${({ theme }) =>
        theme.dark ? colors["black-light"] : colors["white"]};
    }
  }
`;

export const InfoBoxListItemIcon = styled.span`
  width: ${scale.px(4)};
  height: ${scale.px(4)};
  border-radius: 50%;
  background-color: ${({ theme }) =>
    theme.dark ? `rgba(0, 0, 0, 0.12)` : `rgba(255, 255, 255, 0.12)`};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${scale.px(1)};
  margin-top: ${scale.px(-0.5)};

  ${breakpoint("m")`
		width: ${scale.px(5)};
    	height: ${scale.px(5)};
	`}
`;

export const infoBoxCtaStyles = css`
  position: relative;
  display: inline-block;
  padding: ${scale.px(2)};
  text-decoration: none;
  text-align: center;
  width: auto;
  font-size: ${scale.px(2)};
  letter-spacing: 1px;
  font-weight: ${fontWeight.semibold};
  text-transform: uppercase;
  background-color: ${colors["gold"]};
  color: ${colors["black-light"]};
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: ${scale.px(0.5)};
  margin-top: ${scale.px(2.5)};
  width: 100%;

  ${breakpoint("m")`
		padding: ${scale.px(3)};
	`}

  ${breakpoint("xxl")`
		margin-top: ${scale.px(3)};
	`}
`;

const InfoBox: React.FC<InfoBoxProps> & InfoBoxComponent = (props) => (
  <InfoBoxStyled ref={props.innerRef} {...props} />
);

InfoBox.List = InfoBoxList;

InfoBox.Item = InfoBoxListItem;

InfoBox.ItemIcon = InfoBoxListItemIcon;

const InfoBoxCtaStyled = styled.div`
  ${infoBoxCtaStyles}
`;
InfoBox.Cta = ({ label }) => <InfoBoxCtaStyled>{label}</InfoBoxCtaStyled>;

const InfoBoxCTALinkStyled = styled.a`
  ${infoBoxCtaStyles}
`;

InfoBox.CtaLink = ({ label, href, ...rest }) => {
  const { as, ...restOfProps } = rest;
  return (
    <InfoBoxCTALinkStyled {...restOfProps} href={href.toString()}>
      {label}
    </InfoBoxCTALinkStyled>
  );
};

const InfoBoxCtaExternalLinkStyled = styled.a`
  ${infoBoxCtaStyles}
`;
InfoBox.CtaExternalLink = ({ label, as, ref, ...rest }) => (
  <InfoBoxCtaExternalLinkStyled {...rest}>{label}</InfoBoxCtaExternalLinkStyled>
);

export { InfoBox };
