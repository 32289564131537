"use client";
import { useState } from "react";
import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect";
interface UseDimensionsOptions {
  liveMeasure?: boolean;
}

const useDimensions = (
  ref: React.MutableRefObject<HTMLElement>,
  { liveMeasure = true }: UseDimensionsOptions = {}
) => {
  const [dimensions, setDimensions] = useState<DOMRect | null>(null);

  useIsomorphicLayoutEffect(() => {
    if (ref.current) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(ref.current.getBoundingClientRect())
        );
      measure();

      if (liveMeasure) {
        window.addEventListener("resize", measure);

        return () => {
          window.removeEventListener("resize", measure);
        };
      }
    }
  }, [ref, liveMeasure]);

  return dimensions;
};

export { useDimensions };
