"use client";
import * as React from "react";
import styled, { WebTarget } from "styled-components";
import { Icon } from "..";
import { scale, colors, fontWeight } from "../../styles";
import Link from "next/link";
import { ThemeContext } from "styled-components";
import noForwardProps from "../../../../../utils/restrictPropsToDOM";

export interface LinkStyledProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  withChevron?: boolean;
  label: string;
  active?: boolean;
  onClick?: (e: any) => void;
}

export const LinkStyled = styled(Link).withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})<LinkStyledProps>`
  position: relative;
  font-size: ${scale.px(2.25)};
  line-height: ${scale.px(3)};
  font-weight: ${fontWeight.semibold};
  color: ${({ theme }) =>
    theme.dark ? colors["white"] : colors["black-light"]};
  text-decoration: none;

  &:before {
    content: "";
    display: ${(props) => (props.active ? "block" : "none")};
    position: absolute;
    top: -1px;
    left: ${scale.px(-2)};
    height: 100%;
    width: ${scale.px(0.5)};
    background-color: ${colors["gold"]};
  }

  span {
    display: inline;
  }

  svg {
    display: inline-block;
    transform: translateY(3px) translateX(0);
    transition: transform 0.2s cubic-bezier(0.7, 0, 0.84, 0);
  }

  &:hover {
    svg {
      transform: translateY(3px) translateX(3px);
    }
  }
`;

export const CustomLink: React.FC<LinkStyledProps> = (props) => {
  const themeContext = React.useContext(ThemeContext);

  if (props.href && themeContext) {
    return (
      <LinkStyled {...props} prefetch={false}>
        <span>{props.label}</span>
        {props.withChevron && (
          <Icon
            icon="chevron-right"
            size={2.25}
            color={themeContext.dark ? "white" : "black-light"}
          />
        )}
      </LinkStyled>
    );
  }
};
