"use client";
import React from "react";
import styled from "styled-components";
import { scale, colors, ColorKeys } from "../../styles";
import { Icon } from "../Icon";

export interface DrawerToggleProps {
  isCollapsed?: boolean;
  label?: string;
  backgroundColor?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const DrawerToggleStyled = styled.button<DrawerToggleProps>`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${scale.px(2.25)};
  padding: ${(props) => (props.backgroundColor ? scale.px(1.5) : 0)};
  border-radius: ${scale.px(0.5)};
  background-color: ${(props) =>
    props.backgroundColor
      ? colors[props.backgroundColor as ColorKeys]
      : "transparent"};
  color: ${(props) =>
    props.disabled ? `${colors["grey"]}` : `${colors["black"]}`};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

const DrawerToggle: React.FC<DrawerToggleProps> = ({
  onClick,
  label,
  isCollapsed,
  disabled,
  ...rest
}) => {
  const ref = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [hasBeenOpened, setHasBeenOpened] = React.useState(false);

  // Return focus to toggle after drawer closes
  React.useEffect(() => {
    if (!isCollapsed) {
      setHasBeenOpened(true);
    }

    if (ref.current && isCollapsed && hasBeenOpened) {
      ref.current.focus();
      setHasBeenOpened(false);
    }
  }, [ref, isCollapsed, hasBeenOpened]);

  return (
    <DrawerToggleStyled
      onClick={onClick}
      isCollapsed={isCollapsed}
      disabled={disabled}
      {...rest}
      ref={ref}
    >
      <Icon
        size={5}
        icon={isCollapsed ? "chevron-up" : "chevron-down"}
        color={disabled ? "grey" : "black"}
      ></Icon>
      {label && label}
    </DrawerToggleStyled>
  );
};

export { DrawerToggle };
