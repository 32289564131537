import styled, { WebTarget } from "styled-components"
import { scale, breakpoint } from "../../styles"
import { space, SpaceProps } from "../../styles/styled-system"
import noForwardProps from "../../../../../utils/restrictPropsToDOM"

interface ScrollPanelStyledProps {
	scrollAuto?: boolean
}

export const ScrollPanelStyled = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["scrollAuto"].includes(prop),
})<ScrollPanelStyledProps>`
	display: flex;
	width: 100%;
	margin: 0 auto;
	scroll-behavior: ${props => (props.scrollAuto ? "smooth" : "auto")};
	overflow: ${props => (props.scrollAuto ? "auto" : "hidden")};
`

ScrollPanelStyled.defaultProps = {
	scrollAuto: false
}

export const Inner = styled.div`
	padding-left: ${scale.px(1)};

	${breakpoint("s")`
		padding-left: ${scale.px(3)};
	`}

	${breakpoint("l")`
		padding-left: ${scale.px(8)};
	`}

	${breakpoint("xxxl")`
		padding-left: calc(50vw - ${scale.px(184 / 2)});
	`}
`

interface NavButtonProps extends SpaceProps {
	disabled?: boolean
}

export const NavButton = styled.button.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})<NavButtonProps>`
	transition: opacity 0.25s;
	&:disabled {
		opacity: 0.5;
	}
	${space}
`

NavButton.defaultProps = {
	disabled: false
}
