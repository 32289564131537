import { scale } from "../../styles"
import styled, { WebTarget } from "styled-components"
import { PromotionalAreaProps } from "./PromotionalArea"
import { Text } from "../Text"
import noForwardProps from "../../../../../utils/restrictPropsToDOM";

export const Content = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})
<{useImagePadding: boolean}>`
	transform: translateY(0);
	padding-top: calc(${props => (props.useImagePadding ? (9 / 16) * 100 : 0)}% + ${props => (props.useImagePadding ? scale.px(4) : 0)});
	display: block;
`;

export const MediaBackground = styled.div<Pick<PromotionalAreaProps, "color">>`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: ${props => props.color};
	box-shadow: 0px 12px 17px rgba(0, 0, 0, 0);
`;

export const Media = styled.figure`
	position: relative;
	display: block;
	height: auto;
	width: 100%;
	padding-bottom: ${(10 / 16) * 100}%;
	transform-origin: center 100%;
	transform: scale(0.9);
	transform-origin: center 85%;

	> * {
		position: absolute;
		object-fit: cover;
		object-position: center;
		height: 100%;
		width: 100%;
	}
`;

export const PromotionalAreaStyled = styled.div`
	padding: ${scale.px(2.5)} ${scale.px(3)} ${scale.px(5.625)};
	position: relative;
	width: 100%;
	height: fit-content;
`;

export const TextLink = styled(Text)<{textColor: string}>`
  text-decoration: underline;
	color: ${props => props.textColor};

	a {
		text-decoration: inherit;
		color: inherit;
	}
`;
