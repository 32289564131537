"use client";
import * as React from "react";
import { scale, breakpoint, colors } from "../../styles";
import styled, { WebTarget } from "styled-components";
import { LayoutProps } from "../../components";
import { alignItems, justifyContent, flexDirection } from "styled-system";
import noForwardProps from "../../../../../utils/restrictPropsToDOM";

export interface HorizontalScrollerProps extends LayoutProps {
  showFade?: boolean;
}

export interface HorizontalScrollerStyledProps extends HorizontalScrollerProps {
  overflowLeft: boolean;
  overflowRight: boolean;
}

const HorizontalScrollerStyled = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})<HorizontalScrollerStyledProps>`
  display: flex;
  position: relative;
  width: 100%;
  ${alignItems}
  ${justifyContent}
	${flexDirection}

	&:before,
	&:after {
    content: "";
    display: ${(props) => (props.showFade ? "block" : "none")};
    position: absolute;
    top: 0;
    height: 100%;
    width: ${scale.px(5)};
    pointer-events: none;
    z-index: 1;
  }

  &:before {
    opacity: ${(props) => (props.overflowLeft ? 1 : 0)};
    left: 0;
    background-image: ${({ theme }) => `linear-gradient(
			-90deg,
			rgba(0,0,0, 0) 0%,
			${theme.dark ? colors["black"] : colors["white"]} 100%)`};
  }

  &:after {
    opacity: ${(props) => (props.overflowRight ? 1 : 0)};
    right: 0;
    background-image: ${({ theme }) => `linear-gradient(
			90deg,
			rgba(0,0,0, 0) 0%,
			${theme.dark ? colors["black"] : colors["white"]} 100%
		)`};
  }
`;

const HorizontalScrollerInner = styled.div`
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  padding: ${scale.px(4)} 0;
  padding-right: ${scale.px(2)};
  -webkit-overflow-scrolling: touch;

  ${breakpoint("m")`
		padding-right: 0;
		`}

  ${breakpoint("m")`
		justify-content: center;
	`}

	& > * {
    display: inline-block;
  }
`;

const HorizontalScroller: React.FC<
  React.PropsWithChildren<HorizontalScrollerProps>
> = ({ children, showFade = true, ...rest }) => {
  // Refs
  const innerRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  // State bools
  const [hasOverflow, setHasOverflow] = React.useState(false);
  const [scrollAtEnd, setScrollAtEnd] = React.useState(false);
  const [scrollAtStart, setScrollAtStart] = React.useState(true);

  // Check overflow
  React.useEffect(() => {
    const resizeHandler = () =>
      setHasOverflow(
        () => innerRef.current.scrollWidth > innerRef.current.offsetWidth
      );
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, [innerRef]);

  // Check if horizontal scroll is at start or end
  React.useEffect(() => {
    const ref = innerRef.current;

    const scrollListener = (e: Event) => {
      setScrollAtEnd(
        () => ref.offsetWidth + ref.scrollLeft === ref.scrollWidth
      );
      setScrollAtStart(() => ref.scrollLeft === 0);
    };
    if (ref) {
      ref.addEventListener("scroll", scrollListener);
    }
    return () => ref.removeEventListener("scroll", scrollListener);
  }, [innerRef]);

  return (
    <HorizontalScrollerStyled
      overflowLeft={hasOverflow && !scrollAtStart}
      overflowRight={hasOverflow && !scrollAtEnd}
      showFade={showFade}
      {...rest}
    >
      <HorizontalScrollerInner ref={innerRef}>
        {children}
      </HorizontalScrollerInner>
    </HorizontalScrollerStyled>
  );
};

export { HorizontalScroller };
