"use client";
import * as React from "react";
import styled from "styled-components";
import { Flex } from "../../components";
import { colors } from "../../styles";
import { logoBlackBase64, logoWhiteBase64 } from "./assets";
import { ThemeContext } from "styled-components";
import { TextLogo } from "./Logo";

const HeaderLogoStyled = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const HeaderLogo: React.FC = (props) => {
  const themeContext = React.useContext(ThemeContext);

  if (themeContext) {
    return (
      <Flex
        justifyContent={{ _: "flex-start", xxs: "center" }}
        alignItems="center"
      >
        <Flex
          style={{ position: "relative" }}
          w={{ _: 5, m: 6 }}
          h={{ _: 5, m: 6 }}
          mr={{ _: 1, m: 2 }}
        >
          <HeaderLogoStyled
            src={themeContext.dark ? logoWhiteBase64 : logoBlackBase64}
            alt=""
          />
        </Flex>
        <Flex
          style={{ position: "relative" }}
          w={{ _: 0, xxs: 6, m: 9 }}
          h={{ _: 0, xxs: 2.5, m: 4 }}
        >
          {themeContext.dark ? (
            <TextLogo fill={colors["white"]} />
          ) : (
            <TextLogo fill={colors["black"]} />
          )}
        </Flex>
      </Flex>
    );
  }
};
