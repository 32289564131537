'use client'
import React from "react";
import { Text } from "../Text";
import { Content, EventCardStyled, Media, MediaBackground } from "./styled";
import { Flex } from "../Flex";

export interface EventCardData {
  tag?: string;
  name?: string;
  meta?: string;
  title?: string;
}

export interface EventCardProps extends EventCardData {
  media?: React.ReactNode;
  bgColor?: string;
  bgGradient?: boolean;
}

export const EventCard: React.FC<EventCardProps> = ({
  bgGradient = true,
  ...props
}) => {
  return (
    <EventCardStyled>
      <MediaBackground bgGradient={bgGradient}>
        {props.media && <Media>{props.media}</Media>}
      </MediaBackground>
      <Content>
        {props.tag ? (
          <Text
            as="span"
            display="block"
            textAlign="center"
            variant="tag"
            mb={0.5}
            fontSize={1.5}
            lineHeight={2.25}
          >
            {props.tag}
          </Text>
        ) : (
          <Flex m={1}></Flex>
        )}
        {props.name && (
          <Text
            textAlign="center"
            variant="headline-4"
            as="h2"
            mb={2}
            fontSize={{
              _: 2.25,
              m: 2.5,
              xxl: 3,
            }}
            lineHeight={{
              _: 3.25,
              m: 3.5,
              xxl: 4,
            }}
          >
            {props.name}
          </Text>
        )}
        {props.meta && (
          <Text
            textAlign="center"
            fontSize={{ _: 1.75, m: 2 }}
            lineHeight={{ _: 2.25, m: 3 }}
          >
            {props.meta}
          </Text>
        )}
        {props.title && (
          <Text
            textAlign="center"
            variant="headline-4"
            as="h3"
            fontSize={{
              _: 3,
              xxl: 4,
            }}
            lineHeight={{
              _: 4,
              xxl: 5,
            }}
            // This text is redundant for screen readers, so we hide it from them
            aria-hidden="true"
          >
            {props.title}
          </Text>
        )}
      </Content>
    </EventCardStyled>
  );
};
