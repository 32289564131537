import styled, { WebTarget, css } from "styled-components";
import { scale, colors } from "../../styles";
import { breakpoint } from "../../styles";
import { ThemeColorVariant } from "../../styles";
import {
  scalePrevBtn,
  movePrevBtnArrow,
  scaleNextBtn,
  moveNextBtnArrow,
} from "./animations";
import { rgba } from "polished";
import { SlideshowLocales } from "./Slideshow";
import noForwardProps from "../../../../../utils/restrictPropsToDOM";

export interface NavButtonStyledProps extends SlideshowLocales {
  themeColorVariant: ThemeColorVariant;
}

export const SlideshowStyled = styled.div`
  position: relative;
  width: 100%;
`;

export const SlideshowImageWrapper = styled.div`
  overflow: hidden;
`;

export const SlideshowContent = styled.div`
  position: relative;
  height: 100%;

  ${SlideshowImageWrapper},
  ${SlideshowImageWrapper} > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${SlideshowImageWrapper} > img {
    object-fit: cover;
    object-position: center;
    will-change: transform;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 66.6%;

    ${breakpoint("m")`
			padding-bottom: 56.25%;
		`}
  }
`;

export const FooterStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  padding: 0 ${scale.px(2)};

  ${breakpoint("l")`
		flex-direction: row;
		align-items: flex-start;
		padding: ${scale.px(2)} 0;
	`}
`;

export const CaptionStyled = styled.p`
  font-size: ${scale.px(1.75)};
  line-height: ${scale.px(2.25)};
  text-align: center;

  & > span {
    font-size: ${scale.px(1.75)};
    line-height: ${scale.px(2.25)};
    margin-bottom: 0;
  }

  ${breakpoint("l")`
		font-size: ${scale.px(2)};
		line-height: ${scale.px(3)};
		margin-right: ${scale.px(4)};
		text-align: left;
	
		& > span {
			font-size: ${scale.px(2)};
			line-height: ${scale.px(3)};
		}
	`}

  ${breakpoint("xl")`
		max-width: 85%;
		margin-right: ${scale.px(10)};
	`}
`;

export const Indicator = styled.div`
  display: flex;
  align-items: center;
  padding: ${scale.px(2.5)} 0;

  ${breakpoint("l")`
		padding-top: ${scale.px(1)};
		padding-bottom: 0;
		margin-left: auto;
	`}
`;

export const IndicatorItem = styled.div`
  width: ${scale.px(1.25)};
  height: 4px;
  background-color: ${({ theme }) => rgba(theme.textColor, 0.4)};
  margin-right: ${scale.px(1)};

  &:last-of-type {
    margin-right: 0;
  }

  &.active {
    background-color: ${({ theme }) => theme.textColor};
  }
`;

export const btnStyles = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  align-items: center;
  justify-content: center;
  width: ${scale.px(6)};
  height: ${scale.px(6)};
  z-index: 1;
  overflow: hidden;

  display: none;

  ${breakpoint("l")`
		display: flex;
	`}

  svg,
	path,
	polygon {
    outline: none;
    fill: ${({ theme }) =>
      theme.dark ? colors["white"] : colors["black"]} !important;
  }
`;

export const PrevBtnStyled = styled.button
  .withConfig({
    shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
      !noForwardProps.includes(prop), 
  })
  .attrs<NavButtonStyledProps>((props) => ({
    "aria-label": props.previousSlide,
  }))<NavButtonStyledProps>`
  ${btnStyles}
  left: 0;
  transform: translateY(-50%);
  transform-origin: center left;
  margin-left: ${scale.px(-3)};
  background: ${({ theme, ...rest }) =>
    rest.themeColorVariant === "PRIMARY"
      ? theme.complementaryColor
      : theme.primaryColor};

  &.animate {
    ${scalePrevBtn};

    svg {
      ${movePrevBtnArrow};
    }
  }
`;

export const NextBtnStyled = styled.button
  .withConfig({
    shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
      !noForwardProps.includes(prop),
  })
  .attrs<NavButtonStyledProps>((props) => ({
    "aria-label": props.nextSlide,
  }))<NavButtonStyledProps>`
  ${btnStyles}
  right: 0;
  transform: translateY(-50%);
  transform-origin: center right;
  margin-right: ${scale.px(-3)};
  background: ${({ theme, ...rest }) =>
    rest.themeColorVariant === "PRIMARY"
      ? theme.complementaryColor
      : theme.primaryColor};

  &.animate {
    ${scaleNextBtn};

    svg {
      ${moveNextBtnArrow};
    }
  }
`;
