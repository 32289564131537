import { breakpoints, colors, borderColors, ColorKeys } from "../constants";
import { DefaultTheme } from "styled-components";

export const lightTheme: DefaultTheme = {
  textColor: colors.black as ColorKeys,
  primaryColor: colors.greybrown,
  complementaryColor: colors["off-white"],
  dark: false,
};

export const darkTheme: DefaultTheme = {
  textColor: colors.white as ColorKeys,
  primaryColor: colors.black,
  complementaryColor: colors["blue-dark"],
  dark: true,
};

export const theme: DefaultTheme = {
  breakpoints,
  colors,
  borderColors,
  ...lightTheme,
};

export type ThemeColorVariant = "PRIMARY" | "COMPLEMENTARY";
