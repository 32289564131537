"use client";
import React from "react";
import { Flex } from "../../components";
import {
  HeaderStyled,
  PanelStyled,
  PanelInnerStyled,
  ToggleStyled,
} from "./styled";
import { AccordionHeaderProps, AccordionPanelProps } from "./types";

export interface AccordionProps {
  activeId?: string;
  children: React.ReactNode;
}

export interface AccordionComponent {
  Header: React.FC<AccordionHeaderProps>;
  Panel: React.FC<AccordionPanelProps>;
}

const AccordionContext = React.createContext({
  activeId: "",
  setActiveId: (id: string) => {},
});

const Accordion: React.FC<AccordionProps> & AccordionComponent = (props) => {
  const [activeId, setActiveId] = React.useState(props.activeId || "");

  return (
    <AccordionContext.Provider value={{ activeId, setActiveId }} {...props} />
  );
};

Accordion.Header = function ({ children, onExpand, ...rest }) {
  const { activeId, setActiveId } = React.useContext(AccordionContext);
  const expanded = rest.id === activeId;

  return (
    <HeaderStyled {...rest} expanded={expanded}>
      <Flex alignItems={`center`}>{children}</Flex>
      <ToggleStyled
        onClick={() => {
          setActiveId(expanded ? "" : rest.id);
          onExpand && !expanded && onExpand();
        }}
        expanded={expanded}
        {...rest}
      />
    </HeaderStyled>
  );
} as React.FC<AccordionHeaderProps>;

Accordion.Panel = function (props) {
  const { activeId } = React.useContext(AccordionContext);

  return (
    <PanelStyled expanded={props.id === activeId} {...props}>
      <PanelInnerStyled
        px={{ _: 0, m: 3 }}
        py={{ _: 3, m: 3 }}
        pb={{ _: 1, m: 1 }}
      >
        {props.children}
      </PanelInnerStyled>
    </PanelStyled>
  );
} as React.FC<AccordionPanelProps>;

export { Accordion };
