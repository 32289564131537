import { css, keyframes } from "styled-components"
import { TRANSITION_CURVE } from "../../constants"

export const fadeDownText = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-24px);
    }
    60% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`

export const fadeText = css`
	animation-name: ${fadeDownText};
	animation-timing-function: ${TRANSITION_CURVE};
	animation-duration: 1s;
	animation-fill-mode: forwards;
`
