import { Image } from "../@types/graphql.generated";
import { qBankImageSrc } from ".";

export const imageDataToImageProps = (imageData: Image) => {
  if (imageData.src) {
    return {
      alt: imageData.alt,
      mobileImageSrc: qBankImageSrc(imageData.src, "750"),
      desktopImageSrc: qBankImageSrc(imageData.src, "1900"),
    };
  }
};
