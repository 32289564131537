"use client";
import * as React from "react";
import styled from "styled-components";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import { Icon } from "../../components";
import { scale } from "../../styles";
import { useInView } from "react-intersection-observer";

export interface VideoPlayerProps extends ReactPlayerProps {
  autoplay?: boolean;
  useCustomControls?: boolean;
  loadWhenInView?: boolean;
}

export const VideoPlayerStyled = styled.div`
  position: relative;
  width: 100%;

  &:after {
    content: "";
    display: inline-block;
    padding-bottom: 56.25%;
  }

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
`;

const PlaybackBtn = styled.button`
  position: absolute;
  top: ${scale.px(3)};
  right: ${scale.px(3)};
  left: auto;
  width: ${scale.px(4)};
  height: ${scale.px(4)};
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  autoplay = false,
  useCustomControls = false,
  loadWhenInView = true,
  ...rest
}) => {
  const playerRef = React.useRef<ReactPlayer>(null);
  const [isPlaying, setIsPlaying] = React.useState(autoplay);
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "100%",
  });

  const reactPlayerConfig = {
    vimeo: {
      playerOptions: {
        controls: true,
        width: "1440",
        style: {
          width: "100%",
          height: "100%",
        },
      },
    },
  };

  // Fix for referrer policy issue with Vimeo
  const handlePlayerReady = () => {
    if (!playerRef.current) return;

    let iframe: HTMLIFrameElement | null = null;
    const internalPlayer = playerRef.current.getInternalPlayer() as any;
    if (internalPlayer) {
      if (internalPlayer.tagName === "IFRAME") {
        iframe = internalPlayer;
      } else if (internalPlayer.element && internalPlayer.element.tagName === "DIV") {
        iframe = internalPlayer.element.querySelector("iframe");
      }

      if (iframe) {
        iframe.setAttribute("referrerpolicy", "strict-origin");
        const parent = iframe.parentNode;
        if (parent) {
          parent.removeChild(iframe);
          parent.appendChild(iframe);
        }
      }
    }
  };

  return (
    <VideoPlayerStyled>
      <div ref={ref}>
        {useCustomControls && (
          <PlaybackBtn
            aria-label={!isPlaying ? "pause" : "play"}
            onClick={(e) => {
              e.preventDefault();
              setIsPlaying(!isPlaying);
            }}
          >
            {isPlaying ? (
              <Icon icon="pause-btn" color={"off-white"} />
            ) : (
              <Icon icon="play-btn" color={"off-white"} />
            )}
          </PlaybackBtn>
        )}
        {(loadWhenInView ? inView : true) && (
          <ReactPlayer
            ref={playerRef}
            width="100%"
            height="100%"
            controls={false}
            config={reactPlayerConfig}
            playing={isPlaying}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onReady={handlePlayerReady}
            {...rest}
          />
        )}
      </div>
    </VideoPlayerStyled>
  );
};

export { VideoPlayer };
