"use client";
import styled, { WebTarget } from "styled-components";
import {
  space,
  SpaceProps,
  layoutSize,
  LayoutSizeProps,
  color,
  ColorProps,
  border,
  BorderProps,
} from "../../styles/styled-system";
import {
  alignItems,
  AlignItemsProps,
  width,
  WidthProps,
  flexDirection,
  FlexDirectionProps,
  justifyContent,
  JustifyContentProps,
  flex,
  FlexProps,
  flexWrap,
  FlexWrapProps,
  flexBasis,
  FlexBasisProps,
  flexGrow,
  FlexGrowProps,
  flexShrink,
  FlexShrinkProps,
  order,
  OrderProps,
  display,
  DisplayProps,
  position,
  PositionProps,
} from "styled-system";
import noForwardProps from "../../../../../utils/restrictPropsToDOM";

export interface LayoutProps
  extends SpaceProps,
    AlignItemsProps,
    WidthProps,
    FlexDirectionProps,
    FlexProps,
    LayoutSizeProps,
    JustifyContentProps,
    ColorProps,
    FlexWrapProps,
    FlexBasisProps,
    FlexGrowProps,
    FlexShrinkProps,
    BorderProps,
    OrderProps,
    DisplayProps,
    PositionProps {}

// 'withConfig' function stops props from being printed to HTML element in DOM
export const Flex = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})<LayoutProps>`
  display: flex;
  ${flexDirection}
  ${space}
  ${alignItems}
  ${width}
  ${justifyContent}
  ${flex}
	${layoutSize}
	${flexWrap}
  ${flexBasis}
  ${flexGrow}
  ${flexShrink}
	${color}
	${order}
	${border}
	${display}
	${position}
`;
