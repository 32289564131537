import { css, keyframes } from "styled-components"
import { TRANSITION_CURVE } from "../../constants"

export const zoomIn = keyframes`
    0% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
`

export const fadeIn = keyframes`
    0% {
        opacity: 0;
        transform: translateY(24px);
    }
    60% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`

export const zoomInImage = css`
	animation-name: ${zoomIn};
	animation-timing-function: ${TRANSITION_CURVE};
	animation-duration: 1s;
	animation-delay: 0.3s;
	animation-fill-mode: both;
`

export const fadeInContainer = css`
	animation-name: ${fadeIn};
	animation-timing-function: ${TRANSITION_CURVE};
	animation-duration: 1s;
	animation-delay: 0.3s;
	animation-fill-mode: both;
`

export const fadeInText = css`
	animation-name: ${fadeIn};
	animation-timing-function: ${TRANSITION_CURVE};
	animation-duration: 1s;
	animation-fill-mode: both;
`
