import * as React from "react";
import {
  ShowHeroStyled,
  ShowHeroContentStyled,
  ShowHeroContentInnerStyled,
  ShowHeroFigure,
} from "./styled";
import { Flex, Text } from "../../components";

export interface ShowHeroLocales {
  tag: string;
  name: string;
  title: string;
  riderAndCreator?: string;
}

export interface ShowHeroProps {
  media: React.ReactNode;
  locales: ShowHeroLocales;
  mediaRef?: React.Ref<any>;
  contentRef?: React.Ref<any>;
  children?: React.ReactNode;
}

const ShowHero: React.FC<ShowHeroProps> = React.memo(
  ({ children, locales, media, mediaRef, contentRef }) => (
    <ShowHeroStyled>
      <ShowHeroFigure ref={mediaRef}>{media}</ShowHeroFigure>
      <ShowHeroContentStyled ref={contentRef}>
        <ShowHeroContentInnerStyled>
          <Flex mb={{ _: 4, xxl: 4 }} flexDirection="column">
            {locales.tag && (
              <Text as="span" variant="tag" mb={2}>
                {locales.tag}
              </Text>
            )}
            {locales.name && (
              <Text
                variant="headline-3"
                as="h1"
                fontSize={{
                  _: 2.5,
                  l: 3,
                }}
                lineHeight={{
                  _: 3,
                  l: 4,
                }}
              >
                {locales.name}
              </Text>
            )}
            {locales.riderAndCreator && (
              <Text
                as="span"
                mb={0}
                fontSize={{
                  _: 1.75,
                  xxl: 2,
                }}
                lineHeight={{
                  _: 2.5,
                  xxl: 2.75,
                }}
                mt={{
                  _: 0,
                  m: -1,
                }}
              >
                {locales.riderAndCreator}
              </Text>
            )}
          </Flex>
          {locales.title && (
            <Text
              variant="headline-2"
              fontSize={{
                _: 3,
                l: 4,
                xxl: 5,
              }}
              lineHeight={{
                _: 3.5,
                l: 5,
                xxl: 6,
              }}
            >
              {locales.title}
            </Text>
          )}
        </ShowHeroContentInnerStyled>
        {children}
      </ShowHeroContentStyled>
    </ShowHeroStyled>
  )
);

export { ShowHero };
