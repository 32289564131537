"use client";
import * as React from "react";
import styled, { WebTarget } from "styled-components";
import { UnderlinedLink } from "../../components";
import { scale, colors, ColorKeys } from "../../styles";
import { useDimensions } from "../../hooks/useDimensions";

interface ShowMoreStyledProps {
  maxHeight: number;
  expanded?: boolean;
  fade?: ColorKeys;
  onExpand?: () => void;
}

export interface ShowMoreProps extends ShowMoreStyledProps {
  label: string;
  ariaLabel?: string;
  children?: React.ReactNode;
}

const ShowMoreContent = styled.div`
  overflow: hidden;
  width: 100%;
`;

const ShowMoreStyled = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["expanded", "maxHeight"].includes(prop),
})<ShowMoreStyledProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: ${(props) => (props.expanded ? "none" : "block")};
    width: 100%;
    height: ${scale.px(22)};
    pointer-events: none;
  }

  & > a {
    position: relative;
    z-index: 1;
    margin-top: ${(props) => (props.fade ? 0 : scale.px(4))};
  }

  ${ShowMoreContent} {
    position: relative;
    max-height: ${(props) =>
      props.expanded ? "none" : scale.px(props.maxHeight)};

    &:after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: ${scale.px(32)};
      background: ${(props) =>
        props.fade && !props.expanded
          ? `linear-gradient(transparent, ${colors[props.fade]})`
          : "none"};
      pointer-events: none;
    }
  }
`;

const ShowMore: React.FC<ShowMoreProps> = ({
  children,
  label,
  maxHeight,
  ariaLabel,
  onExpand,
  ...rest
}) => {
  const ref = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const [expanded, setExpanded] = React.useState(true);
  const showMoreSize = useDimensions(ref);

  React.useEffect(() => {
    if (showMoreSize && maxHeight !== 0) {
      setTimeout(() => {
        showMoreSize.height > scale(maxHeight!)
          ? setExpanded(false)
          : setExpanded(true);
      }, 100);
    }
  }, [showMoreSize, maxHeight]);

  return (
    <ShowMoreStyled
      maxHeight={maxHeight}
      expanded={expanded}
      ref={ref}
      {...rest}
    >
      <ShowMoreContent>{children}</ShowMoreContent>
      {!expanded && (
        <UnderlinedLink.Link
          href="#"
          label={label}
          aria-label={ariaLabel || ""}
          onClick={(e) => {
            e.preventDefault();
            setExpanded(!expanded);

            onExpand && onExpand();
          }}
        />
      )}
    </ShowMoreStyled>
  );
};

export { ShowMore };
