/* Predefined exisiting dimensions */
/*
	Aspect ratios for validDimensions:
	600w: 1x1
*/
type validDimensions = "600" | "750" | "768" | "1600" | "1900";

export const qBankBaseUrl = typeof window === 'undefined'
  ? process.env.REACT_APP_QBANK_BASE_URL
  : process.env.NEXT_PUBLIC_QBANK_BASE_URL;

if (!qBankBaseUrl) {
  console.error("NEXT_PUBLIC_QBANK_BASE_URL is not set");
}

export const qBankImageSrc = (
  image: string,
  dimension: validDimensions = "750"
) => `${qBankBaseUrl}/${dimension}w/${image}`;

export const qBankVideoSrc = (videoUrl: string) =>
  `${qBankBaseUrl}/${videoUrl}`;
