"use client";
import * as React from "react";
import styled, { css, WebTarget } from "styled-components";
import { scale, breakpoint, ColorKeys } from "../../styles";
import compose from "lodash/fp/compose";
import { setLightness, setSaturation, rgba } from "polished";
import { Icon } from "../Icon"
import noForwardProps from "../../../../../utils/restrictPropsToDOM";

export interface BlockAlertProps extends React.PropsWithChildren {
	color?: ColorKeys;
	showMore?: React.ReactElement;
}
export interface BlockAlertComponent {
  Icon: React.FC<React.PropsWithChildren>;
}

const setTone = compose(setSaturation(0.17), setLightness(0.55));

const BlockAlertStyled = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})<{more: boolean}>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	width: 100%;
	padding: ${scale.px(2)} ${scale.px(2)} ${scale.px(2)} ${scale.px(5.25)};
	background-color: ${({ theme }) => {
		const bgColor = theme.complementaryColor;
	
		if (bgColor) {
		  if (theme.dark) {
			return `${rgba(setTone(bgColor), 0.2)}`;
		  } else {
			return `${rgba(setTone(bgColor), 0.4)}`;
		  }
		} else {
		  return "none";
		}
	}};

	${breakpoint("l")`
        padding: ${scale.px(3)} ${scale.px(6)} ${scale.px(3)} ${scale.px(10.5)};
        margin-bottom: ${scale.px(0)};
	`}

	${props => props.more && css`
		padding-right: ${scale.px(8)};
		${breakpoint("l")`
			padding-right: ${scale.px(10)};
		`}
	`}

	article > * {
		font-size: ${scale.px(2)};
		line-height: ${scale.px(3)};

    ${breakpoint("l")`
            font-size: ${scale.px(2.5)};
            line-height: ${scale.px(4)};
		`}

    &:last-child {
      margin-bottom: 0;
    }

    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const BlockAlertIcon = styled.span`
  position: absolute;
  top: ${scale.px(2.5)};
  left: ${scale.px(2)};
  width: 13px;
  height: 11px;

  ${breakpoint("l")`
        top: ${scale.px(3.5)};
	    left: ${scale.px(3.5)};
    `}
`;

const BlockAlertExpandIcon = styled(Icon)`
	position: absolute;
	right: -${scale.px(2)};
	top: 50%;
	transform: translate(100%, -50%);
`

const BlockAlertContent = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

const BlockAlertShowMore = styled.div`

`

const BlockAlert: React.FC<BlockAlertProps> & BlockAlertComponent = props => {
	const [isCollapsed, setIsCollapsed] = React.useState(true);
	const content = (
		<div>
			{props.children}
		</div>
	);
	const expandIcon = props.showMore && (<div style={{position: "relative"}}>
		<BlockAlertExpandIcon
			size={{ _: 3, l: 5 }}
			color={props.color}
			icon={isCollapsed ? 
				"chevron-right" : 
				"chevron-down"
			}
		></BlockAlertExpandIcon>
	</div>);
	const expandedContent = props.showMore && (
		<BlockAlertShowMore>
			{!isCollapsed && props.showMore}
		</BlockAlertShowMore>
	);
	return (
		<BlockAlertStyled
			more={!!props.showMore}
			onClick={()=>setIsCollapsed(!isCollapsed)}
		>
			<BlockAlertContent>
				{content}
				{expandIcon}
			</BlockAlertContent>
			{expandedContent}
		</BlockAlertStyled>
	);
}

BlockAlert.Icon = (props) => <BlockAlertIcon>{props.children}</BlockAlertIcon>;

export { BlockAlert };
