"use client";
import * as React from "react";
import styled from "styled-components";
import { variants } from "../../styles/constants/typography";
import { typography } from "../../styles/styled-system";
import { colors, scale, breakpoint, fontWeight } from "../../styles";

export const RteStyled = styled.article`
  h1 {
    ${({ theme }) =>
      typography({
        ...variants["headline-1"],
        theme,
        textColor: theme.textColor,
      })}
  }
  h2 {
    ${({ theme }) =>
      typography({
        ...variants["headline-2"],
        theme,
        textColor: theme.textColor,
      })}
  }
  h3 {
    ${({ theme }) =>
      typography({
        ...variants["headline-3"],
        theme,
        textColor: theme.textColor,
      })}
  }
  h4 {
    ${({ theme }) =>
      typography({
        ...variants["headline-4"],
        theme,
        textColor: theme.textColor,
      })}
  }
  h5 {
    ${({ theme }) =>
      typography({
        ...variants["headline-5"],
        theme,
        textColor: theme.textColor,
      })}
  }
  h6 {
    ${({ theme }) =>
      typography({
        ...variants["headline-6"],
        theme,
        textColor: theme.textColor,
      })}
  }
  p {
    ${({ theme }) =>
      typography({
        ...variants["paragraph-1"],
        theme,
        textColor: theme.textColor,
      })}
  }
  a {
    ${({ theme }) =>
      typography({
        theme,
        textColor: theme.textColor,
      })}
    text-decoration: underline;
  }
  em {
    font-style: italic;
  }
  strong {
    font-weight: ${fontWeight.bold};
  }
  ul,
  ol,
  dl {
    padding-left: ${scale.px(2.5)};
    margin-bottom: ${scale.px(2)};
    font-size: ${scale.px(2.25)};
    line-height: ${scale.px(3.25)};
    margin-left: ${scale.px(4)};
    color: ${({ theme }) => theme.textColor};
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  li {
    margin-bottom: ${scale.px(2)};
  }
  figure,
  .qbankWrapper {
    position: relative;
    margin: ${scale.px(2)} 0 ${scale.px(5)};
    img {
      width: 100%;
      height: 100%;
    }
  }
  figcaption {
    padding: ${scale.px(1)} 0;
    font-weight: ${fontWeight.semibold};
    color: ${({ theme }) => theme.textColor};
  }
  button,
  .button,
  .cta,
  .download {
    position: relative;
    display: inline-block;
    padding: ${scale.px(1)};
    text-decoration: none;
    text-align: center;
    width: auto;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: 1px;
    font-weight: ${fontWeight.semibold};
    text-transform: uppercase;
    background-color: ${({ theme }) =>
      theme.dark ? colors["white"] : colors["black"]};
    color: ${({ theme }) => (theme.dark ? colors["black"] : colors["white"])};
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: ${scale.px(0.5)};

    ${breakpoint("l")`
			font-size: ${scale.px(2)};
			line-height: ${scale.px(3)};
			padding: ${scale.px(1.5)} ${scale.px(2)};
		`}
  }
  .cta {
    background-color: ${colors["gold"]};
    color: ${colors["black"]};
  }

  .download {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOCAxMUwxNi45NCA5Ljk0TDEyLjc1IDE0LjEzTDEyLjc1IDVMMTEuMjUgNUwxMS4yNSAxNC4xM0w3LjA2IDkuOTRMNiAxMUwxMiAxN0wxOCAxMVoiIGZpbGw9IiNmZmZmZmYiLz4KPHJlY3QgeD0iMTgiIHk9IjE5LjUiIHdpZHRoPSIxMiIgaGVpZ2h0PSIxLjUiIHRyYW5zZm9ybT0icm90YXRlKC0xODAgMTggMTkuNSkiIGZpbGw9IiNmZmZmZmYiLz4KPC9zdmc+Cg==");
    background-repeat: no-repeat;
    background-size: ${scale.px(2.25)} 100%;
    background-position: right ${scale.px(1)} center;
    padding-right: ${scale.px(4)};

    ${breakpoint("l")`
			background-size: ${scale.px(3)} 100%;
			padding-right: ${scale.px(5)};
		`}
  }
  .credit {
    position: absolute;
    bottom: ${scale.px(4.5)};
    right: 0;
    background-color: ${colors["black"]};
    opacity: 0.7;
    padding: ${scale.px(1)};
  }
  .credit span {
    color: ${colors["white"]};
    font-style: italic;
    font-weight: ${fontWeight.normal};
  }
  .alert {
    position: relative;
    padding: ${scale.px(2)} ${scale.px(2)} ${scale.px(2)} ${scale.px(5.25)};
    background-repeat: no-repeat;
    background-size: ${scale.px(2)};
    background-position: left ${scale.px(2)} top ${scale.px(2.5)};
    margin-bottom: ${scale.px(2.5)};

    ${breakpoint("l")`
			padding: ${scale.px(3)} ${scale.px(6)} ${scale.px(3)} ${scale.px(10.5)};
			background-size: ${scale.px(3.25)};
			background-position: left ${scale.px(3.5)} top ${scale.px(3.5)};
			margin-bottom: ${scale.px(8)};
		`}

    .alert__title,
		.alert__body {
      font-size: ${scale.px(2)};
      line-height: ${scale.px(3)};

      ${breakpoint("l")`
				font-size: ${scale.px(2.5)};
				line-height: ${scale.px(4)};
			`}
    }

    p {
      font-size: ${scale.px(2)};
      margin-top: ${scale.px(2)};

      &:first-child {
        margin-top: 0;
      }

      ${breakpoint("l")`
				font-size: ${scale.px(2.5)};
			`}

      &:last-child {
        margin-bottom: 0;
      }
    }

    .alert__title {
      font-weight: ${fontWeight["semibold"]};
      margin-bottom: 0;
    }

    .alert__icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 13px;
      height: 11px;
    }

    &--red {
      background-color: ${colors["pink-light"]};
    }

    &--yellow {
      background-color: ${colors["gold-light"]};
    }

    &--green {
      background-color: ${colors["green-light"]};
    }

    &--blue {
      background-color: ${colors["blue-light"]};
    }

    &--brown {
      background-color: ${colors["greybrown"]};
    }

    &--icon-red-alert {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyOCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI3LjAzMjYgMjEuMTg2QzI3Ljg4NzEgMjIuNjY3MiAyNi44MTQ0IDI0LjUxODggMjUuMTA3NyAyNC41MTg4SDIuODkwNzVDMS4xODA3IDI0LjUxODggMC4xMTMwMiAyMi42NjQ0IDAuOTY1ODkgMjEuMTg2TDEyLjA3NDUgMS45MjU0OEMxMi45Mjk1IDAuNDQzNTgyIDE1LjA3MDggMC40NDYyNjcgMTUuOTI0MiAxLjkyNTQ4TDI3LjAzMjYgMjEuMTg2Wk0xMy45OTk0IDE3LjIwMzlDMTIuODIzMiAxNy4yMDM5IDExLjg2OTcgMTguMTU3NCAxMS44Njk3IDE5LjMzMzZDMTEuODY5NyAyMC41MDk3IDEyLjgyMzIgMjEuNDYzMiAxMy45OTk0IDIxLjQ2MzJDMTUuMTc1NSAyMS40NjMyIDE2LjEyOSAyMC41MDk3IDE2LjEyOSAxOS4zMzM2QzE2LjEyOSAxOC4xNTc0IDE1LjE3NTUgMTcuMjAzOSAxMy45OTk0IDE3LjIwMzlaTTExLjk3NzUgOS41NDkwNEwxMi4zMjA5IDE1Ljg0NTNDMTIuMzM3IDE2LjE0IDEyLjU4MDYgMTYuMzcwNiAxMi44NzU2IDE2LjM3MDZIMTUuMTIzMUMxNS40MTgyIDE2LjM3MDYgMTUuNjYxOCAxNi4xNCAxNS42Nzc4IDE1Ljg0NTNMMTYuMDIxMyA5LjU0OTA0QzE2LjAzODYgOS4yMzA4IDE1Ljc4NTIgOC45NjMyMSAxNS40NjY1IDguOTYzMjFIMTIuNTMyMUMxMi4yMTM0IDguOTYzMjEgMTEuOTYwMSA5LjIzMDggMTEuOTc3NSA5LjU0OTA0WiIgZmlsbD0iI0YxM0QzRCIvPgo8L3N2Zz4K");
    }

    &--icon-black-alert {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIxLjc3NDkgMTcuMzg5NkMyMi40MTU4IDE4LjUwMDUgMjEuNjExMyAxOS44ODkxIDIwLjMzMTMgMTkuODg5MUgzLjY2ODU1QzIuMzg2MDIgMTkuODg5MSAxLjU4NTI1IDE4LjQ5ODMgMi4yMjQ5MSAxNy4zODk2TDEwLjU1NjQgMi45NDQxNEMxMS4xOTc2IDEuODMyNzIgMTIuODAzNiAxLjgzNDczIDEzLjQ0MzcgMi45NDQxNEwyMS43NzQ5IDE3LjM4OTZWMTcuMzg5NlpNMTIgMTQuNDAzQzExLjExNzkgMTQuNDAzIDEwLjQwMjggMTUuMTE4MSAxMC40MDI4IDE2LjAwMDJDMTAuNDAyOCAxNi44ODIzIDExLjExNzkgMTcuNTk3NCAxMiAxNy41OTc0QzEyLjg4MjEgMTcuNTk3NCAxMy41OTcyIDE2Ljg4MjMgMTMuNTk3MiAxNi4wMDAyQzEzLjU5NzIgMTUuMTE4MSAxMi44ODIxIDE0LjQwMyAxMiAxNC40MDNaTTEwLjQ4MzYgOC42NjE4MUwxMC43NDEyIDEzLjM4NEMxMC43NTMyIDEzLjYwNSAxMC45MzU5IDEzLjc3OCAxMS4xNTcyIDEzLjc3OEgxMi44NDI4QzEzLjA2NDEgMTMuNzc4IDEzLjI0NjggMTMuNjA1IDEzLjI1ODkgMTMuMzg0TDEzLjUxNjQgOC42NjE4MUMxMy41Mjk1IDguNDIzMTMgMTMuMzM5NCA4LjIyMjQ0IDEzLjEwMDQgOC4yMjI0NEgxMC44OTk2QzEwLjY2MDYgOC4yMjI0NCAxMC40NzA2IDguNDIzMTMgMTAuNDgzNiA4LjY2MTgxVjguNjYxODFaIiBmaWxsPSIjMEYwRjBGIi8+Cjwvc3ZnPgo=");
    }

    &--icon-black-info {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDJDNi40Nzc1NCAyIDIgNi40NzkxNSAyIDEyQzIgMTcuNTI0MSA2LjQ3NzU0IDIyIDEyIDIyQzE3LjUyMjUgMjIgMjIgMTcuNTI0MSAyMiAxMkMyMiA2LjQ3OTE1IDE3LjUyMjUgMiAxMiAyWk0xMiA2LjQzNTQ4QzEyLjkzNTMgNi40MzU0OCAxMy42OTM1IDcuMTkzNzEgMTMuNjkzNSA4LjEyOTAzQzEzLjY5MzUgOS4wNjQzNSAxMi45MzUzIDkuODIyNTggMTIgOS44MjI1OEMxMS4wNjQ3IDkuODIyNTggMTAuMzA2NSA5LjA2NDM1IDEwLjMwNjUgOC4xMjkwM0MxMC4zMDY1IDcuMTkzNzEgMTEuMDY0NyA2LjQzNTQ4IDEyIDYuNDM1NDhaTTE0LjI1ODEgMTYuNjc3NEMxNC4yNTgxIDE2Ljk0NDYgMTQuMDQxNCAxNy4xNjEzIDEzLjc3NDIgMTcuMTYxM0gxMC4yMjU4QzkuOTU4NTkgMTcuMTYxMyA5Ljc0MTk0IDE2Ljk0NDYgOS43NDE5NCAxNi42Nzc0VjE1LjcwOTdDOS43NDE5NCAxNS40NDI1IDkuOTU4NTkgMTUuMjI1OCAxMC4yMjU4IDE1LjIyNThIMTAuNzA5N1YxMi42NDUySDEwLjIyNThDOS45NTg1OSAxMi42NDUyIDkuNzQxOTQgMTIuNDI4NSA5Ljc0MTk0IDEyLjE2MTNWMTEuMTkzNUM5Ljc0MTk0IDEwLjkyNjMgOS45NTg1OSAxMC43MDk3IDEwLjIyNTggMTAuNzA5N0gxMi44MDY1QzEzLjA3MzcgMTAuNzA5NyAxMy4yOTAzIDEwLjkyNjMgMTMuMjkwMyAxMS4xOTM1VjE1LjIyNThIMTMuNzc0MkMxNC4wNDE0IDE1LjIyNTggMTQuMjU4MSAxNS40NDI1IDE0LjI1ODEgMTUuNzA5N1YxNi42Nzc0WiIgZmlsbD0iIzBGMEYwRiIvPgo8L3N2Zz4K");
    }

    &--icon-green-info {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDJDNi40Nzc1NCAyIDIgNi40NzkxNSAyIDEyQzIgMTcuNTI0MSA2LjQ3NzU0IDIyIDEyIDIyQzE3LjUyMjUgMjIgMjIgMTcuNTI0MSAyMiAxMkMyMiA2LjQ3OTE1IDE3LjUyMjUgMiAxMiAyWk0xMiA2LjQzNTQ4QzEyLjkzNTMgNi40MzU0OCAxMy42OTM1IDcuMTkzNzEgMTMuNjkzNSA4LjEyOTAzQzEzLjY5MzUgOS4wNjQzNSAxMi45MzUzIDkuODIyNTggMTIgOS44MjI1OEMxMS4wNjQ3IDkuODIyNTggMTAuMzA2NSA5LjA2NDM1IDEwLjMwNjUgOC4xMjkwM0MxMC4zMDY1IDcuMTkzNzEgMTEuMDY0NyA2LjQzNTQ4IDEyIDYuNDM1NDhaTTE0LjI1ODEgMTYuNjc3NEMxNC4yNTgxIDE2Ljk0NDYgMTQuMDQxNCAxNy4xNjEzIDEzLjc3NDIgMTcuMTYxM0gxMC4yMjU4QzkuOTU4NTkgMTcuMTYxMyA5Ljc0MTk0IDE2Ljk0NDYgOS43NDE5NCAxNi42Nzc0VjE1LjcwOTdDOS43NDE5NCAxNS40NDI1IDkuOTU4NTkgMTUuMjI1OCAxMC4yMjU4IDE1LjIyNThIMTAuNzA5N1YxMi42NDUySDEwLjIyNThDOS45NTg1OSAxMi42NDUyIDkuNzQxOTQgMTIuNDI4NSA5Ljc0MTk0IDEyLjE2MTNWMTEuMTkzNUM5Ljc0MTk0IDEwLjkyNjMgOS45NTg1OSAxMC43MDk3IDEwLjIyNTggMTAuNzA5N0gxMi44MDY1QzEzLjA3MzcgMTAuNzA5NyAxMy4yOTAzIDEwLjkyNjMgMTMuMjkwMyAxMS4xOTM1VjE1LjIyNThIMTMuNzc0MkMxNC4wNDE0IDE1LjIyNTggMTQuMjU4MSAxNS40NDI1IDE0LjI1ODEgMTUuNzA5N1YxNi42Nzc0WiIgZmlsbD0iIzMxNzQyNyIvPgo8L3N2Zz4K");
    }

    &--icon-green-check {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyIDEyQzIyIDYuNTE2MTMgMTcuNDgzOSAyIDEyIDJDNi40NzU4MSAyIDIgNi41MTYxMyAyIDEyQzIgMTcuNTI0MiA2LjQ3NTgxIDIyIDEyIDIyQzE3LjQ4MzkgMjIgMjIgMTcuNTI0MiAyMiAxMlpNMTAuODMwNiAxNy4zMjI2QzEwLjU4ODcgMTcuNTY0NSAxMC4xNDUyIDE3LjU2NDUgOS45MDMyMyAxNy4zMjI2TDUuNzA5NjggMTMuMTI5QzUuNDY3NzQgMTIuODg3MSA1LjQ2Nzc0IDEyLjQ0MzUgNS43MDk2OCAxMi4yMDE2TDYuNjM3MSAxMS4zMTQ1QzYuODc5MDMgMTEuMDMyMyA3LjI4MjI2IDExLjAzMjMgNy41MjQxOSAxMS4zMTQ1TDEwLjM4NzEgMTQuMTM3MUwxNi40MzU1IDguMDg4NzFDMTYuNjc3NCA3LjgwNjQ1IDE3LjA4MDYgNy44MDY0NSAxNy4zMjI2IDguMDg4NzFMMTguMjUgOC45NzU4MUMxOC40OTE5IDkuMjE3NzQgMTguNDkxOSA5LjY2MTI5IDE4LjI1IDkuOTAzMjNMMTAuODMwNiAxNy4zMjI2WiIgZmlsbD0iIzMxNzQyNyIvPgo8L3N2Zz4K");
    }

    &--icon-blue-info {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDJDNi40Nzc1NCAyIDIgNi40NzkxNSAyIDEyQzIgMTcuNTI0MSA2LjQ3NzU0IDIyIDEyIDIyQzE3LjUyMjUgMjIgMjIgMTcuNTI0MSAyMiAxMkMyMiA2LjQ3OTE1IDE3LjUyMjUgMiAxMiAyWk0xMiA2LjQzNTQ4QzEyLjkzNTMgNi40MzU0OCAxMy42OTM1IDcuMTkzNzEgMTMuNjkzNSA4LjEyOTAzQzEzLjY5MzUgOS4wNjQzNSAxMi45MzUzIDkuODIyNTggMTIgOS44MjI1OEMxMS4wNjQ3IDkuODIyNTggMTAuMzA2NSA5LjA2NDM1IDEwLjMwNjUgOC4xMjkwM0MxMC4zMDY1IDcuMTkzNzEgMTEuMDY0NyA2LjQzNTQ4IDEyIDYuNDM1NDhaTTE0LjI1ODEgMTYuNjc3NEMxNC4yNTgxIDE2Ljk0NDYgMTQuMDQxNCAxNy4xNjEzIDEzLjc3NDIgMTcuMTYxM0gxMC4yMjU4QzkuOTU4NTkgMTcuMTYxMyA5Ljc0MTk0IDE2Ljk0NDYgOS43NDE5NCAxNi42Nzc0VjE1LjcwOTdDOS43NDE5NCAxNS40NDI1IDkuOTU4NTkgMTUuMjI1OCAxMC4yMjU4IDE1LjIyNThIMTAuNzA5N1YxMi42NDUySDEwLjIyNThDOS45NTg1OSAxMi42NDUyIDkuNzQxOTQgMTIuNDI4NSA5Ljc0MTk0IDEyLjE2MTNWMTEuMTkzNUM5Ljc0MTk0IDEwLjkyNjMgOS45NTg1OSAxMC43MDk3IDEwLjIyNTggMTAuNzA5N0gxMi44MDY1QzEzLjA3MzcgMTAuNzA5NyAxMy4yOTAzIDEwLjkyNjMgMTMuMjkwMyAxMS4xOTM1VjE1LjIyNThIMTMuNzc0MkMxNC4wNDE0IDE1LjIyNTggMTQuMjU4MSAxNS40NDI1IDE0LjI1ODEgMTUuNzA5N1YxNi42Nzc0WiIgZmlsbD0iIzIxNTE2MCIvPgo8L3N2Zz4K");
    }

    &--icon-brown-ticket {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNDQ0NDQgOC42NjY4M0gxNy41NTU2VjE1LjMzMzVINi40NDQ0NFY4LjY2NjgzWk0yMC4zMzMzIDEyLjAwMDJDMjAuMzMzMyAxMi45MjA2IDIxLjA3OTUgMTMuNjY2OCAyMiAxMy42NjY4VjE3LjAwMDJDMjIgMTcuOTIwNiAyMS4yNTM4IDE4LjY2NjggMjAuMzMzMyAxOC42NjY4SDMuNjY2NjdDMi43NDYxOCAxOC42NjY4IDIgMTcuOTIwNiAyIDE3LjAwMDJWMTMuNjY2OEMyLjkyMDQ5IDEzLjY2NjggMy42NjY2NyAxMi45MjA2IDMuNjY2NjcgMTIuMDAwMkMzLjY2NjY3IDExLjA3OTcgMi45MjA0OSAxMC4zMzM1IDIgMTAuMzMzNVY3LjAwMDE2QzIgNi4wNzk2OCAyLjc0NjE4IDUuMzMzNSAzLjY2NjY3IDUuMzMzNUgyMC4zMzMzQzIxLjI1MzggNS4zMzM1IDIyIDYuMDc5NjggMjIgNy4wMDAxNlYxMC4zMzM1QzIxLjA3OTUgMTAuMzMzNSAyMC4zMzMzIDExLjA3OTcgMjAuMzMzMyAxMi4wMDAyWk0xOC42NjY3IDguMzg5MDVDMTguNjY2NyA3LjkyODgxIDE4LjI5MzYgNy41NTU3MiAxNy44MzMzIDcuNTU1NzJINi4xNjY2N0M1LjcwNjQyIDcuNTU1NzIgNS4zMzMzMyA3LjkyODgxIDUuMzMzMzMgOC4zODkwNVYxNS42MTEzQzUuMzMzMzMgMTYuMDcxNSA1LjcwNjQyIDE2LjQ0NDYgNi4xNjY2NyAxNi40NDQ2SDE3LjgzMzNDMTguMjkzNiAxNi40NDQ2IDE4LjY2NjcgMTYuMDcxNSAxOC42NjY3IDE1LjYxMTNWOC4zODkwNVoiIGZpbGw9IiM3MzVCMzQiLz4KPC9zdmc+Cg==");
    }
  }
`;

export const Rte: React.FC<React.PropsWithChildren> = (props) => (
  <RteStyled {...props} />
);
