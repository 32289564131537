"use client";
import * as React from "react";
import styled, { WebTarget } from "styled-components";
import { breakpoint, colors, scale, fontWeight } from "../../styles";
import { Flex, TextProps } from "../../components";
import {
  alignItems,
  justifyContent,
  textAlign,
  AlignItemsProps,
  JustifyContentProps,
  TextAlignProps,
} from "styled-system";

export interface ProfileCardComponent {
  Name: React.FC<React.PropsWithChildren>;
  Email: React.FC<TextProps & React.HTMLProps<HTMLAnchorElement>>;
  Phone: React.FC<TextProps & React.HTMLProps<HTMLAnchorElement>>;
}

export type ProfileCardVariant = "default" | "asParticipant";

export interface ProfileCardStyledProps {
  variant?: ProfileCardVariant;
}

export interface ProfileCardProps
  extends ProfileCardStyledProps,
    AlignItemsProps,
    JustifyContentProps,
    TextAlignProps {
  image?: React.ReactNode;
  children?: React.ReactNode;
}

const NameStyled = styled.h3`
  display: block;
  color: ${({ theme }) => (theme.dark ? colors["white"] : colors["black"])};
  font-size: ${scale.px(2)};
  font-weight: ${fontWeight.semibold};
  line-height: ${scale.px(2.5)};
  margin-bottom: ${scale.px(0.25)};

  a & {
    text-decoration: underline;
  }

  ${breakpoint("xs")`
        font-size: ${scale.px(2.5)};
        line-height: ${scale.px(3)};
        margin-bottom: ${scale.px(0.5)};
    `}
`;

const LinkStyled = styled.a`
  display: block;
  font-size: ${scale.px(1.5)};
  line-height: ${scale.px(2)};
  color: ${({ theme }) => (theme.dark ? colors["white"] : colors["black"])};
  text-decoration: underline;

  ${breakpoint("xs")`
        font-size: ${scale.px(1.75)};
        line-height: ${scale.px(2.25)};
        margin-bottom: ${scale.px(0.25)};
    `}
`;

const ImageStyled = styled.figure.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["textAlign", "alignItems"].includes(prop),
})<ProfileCardStyledProps>`
  position: relative;
  width: 100%;
  max-width: ${(props) =>
    props.variant === "asParticipant" ? scale.px(14) : "none"};
  margin: 0 auto;
  border: ${(props) =>
    props.variant === "asParticipant" ? `1px solid transparent` : `none`};
  border-radius: ${(props) => (props.variant === "asParticipant" ? `50%` : 0)};
  overflow: hidden;

  ${breakpoint("m")`
		max-width: none;
	`}

  &:after {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const ProfileCardStyled = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["textAlign", "alignItems"].includes(prop),
})<AlignItemsProps & JustifyContentProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${alignItems}
  ${justifyContent}
	${textAlign}
`;

const ProfileCard: React.FC<ProfileCardProps> & ProfileCardComponent = ({
  variant = "default",
  image,
  children,
  ...rest
}) => {
  const cardProps = {
    variant,
    ...rest,
  };

  return (
    <ProfileCardStyled {...cardProps}>
      <Flex w={1} pb={variant === "asParticipant" ? 1.5 : 1}>
        {image && <ImageStyled {...cardProps}>{image}</ImageStyled>}
      </Flex>
      <Flex flexDirection="column">{children}</Flex>
    </ProfileCardStyled>
  );
};

ProfileCard.Name = function (props: React.PropsWithChildren<{}>) {
  return <NameStyled>{props.children}</NameStyled>;
};
ProfileCard.Phone = ({ children, ...rest }) => (
  <LinkStyled {...rest}>{children}</LinkStyled>
);
ProfileCard.Email = ({ children, ...rest }) => (
  <LinkStyled {...rest}>{children}</LinkStyled>
);

export { ProfileCard };
