"use client";
import * as React from "react";
import styled from "styled-components";
import { scale, colors } from "../../styles";

export interface SwitchProps {
  id: string;
  label: string;
  onChange: (checked: boolean) => void;
  isChecked?: boolean;
}

const SwitchWrapper = styled.div`
  position: relative;
  display: inline-block;
  height: ${scale.px(4)};
  width: ${scale.px(8)};
`;
const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  height: ${scale.px(4)};
  width: ${scale.px(8)};
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: ${colors["greybrown-medium"]};
  border-radius: ${scale.px(0.5)};

  &::selection {
    background: transparent;
  }

  &::-moz-selection {
    background: transparent;
  }
`;

const SwitchStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  transition: transform 0.2s;
  width: ${scale.px(3.75)};
  height: ${scale.px(3.375)};
  border-radius: 3px;
  background-color: ${colors["greybrown"]};
  pointer-events: none;
`;

const SwitchInput = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  // Mimic Chrome focus outline
  &:focus ~ ${SwitchStyled} {
    outline-color: rgb(77, 144, 254);
    outline-offset: -2px;
    outline-style: auto;
    outline-width: 5px;
  }

  &:checked ~ ${SwitchStyled} {
    transform: translate(${scale.px(3.75)}, -50%);
  }

  &:checked ~ ${SwitchLabel} {
    background-color: ${colors.black};
  }
`;

const Switch: React.FC<SwitchProps> = ({ id, label, onChange, ...rest }) => {
  const [isChecked, setIsChecked] = React.useState(rest.isChecked || false);
  const checked = rest.isChecked || isChecked;

  return (
    <SwitchWrapper>
      <SwitchInput
        type="checkbox"
        id={id}
        name={id}
        checked={checked}
        onChange={() => {
          onChange(checked);
          setIsChecked((checked) => !checked);
        }}
      />
      <SwitchLabel htmlFor={id} aria-label={label} />
      <SwitchStyled />
    </SwitchWrapper>
  );
};

export { Switch };
