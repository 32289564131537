import * as React from "react";
import Image from "next/image";

export const FallbackImage: React.FC = () => (
  <Image
    src="https://gocmsdev.blob.core.windows.net/qbank/1600w/operan-fallback.jpg"
    alt=""
    width="1600"
    height="900"
  />
);

export const profileFallbackImageSrc =
  "https://gocmsdev.blob.core.windows.net/qbank/600w/fallbackportrait.jpg";
