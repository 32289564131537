import Link from "next/link";
import { fontWeight } from "../../styles/constants/typography";
import { scale, colors, breakpoint } from "../../styles";
import styled, { WebTarget } from "styled-components";
import { display, DisplayProps } from "styled-system";

export interface MenuStyledProps {
  open?: boolean;
  children?: React.ReactNode;
}

export interface ListItemStyledProps extends DisplayProps {
  active?: boolean;
  open?: boolean;
  index?: number;
  children?: React.ReactNode;
}

export interface MenuLinkStyledProps {
  active?: boolean;
  children?: React.ReactNode;
}

export const ListStyled = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  list-style-type: none;
  margin: 0;

  ${breakpoint("l")`
		justify-content: center;
		flex-direction: row;
	`}
`;
export const ListItemStyled = styled.li.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["active"].includes(prop),
})<ListItemStyledProps>`
  position: relative;
  display: inline-block;
  display: flex;
  align-items: center;
  height: ${scale.px(8)};
  padding: 0 ${scale.px(5)};

  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: ${(props) =>
    props.open
      ? `opacity 0.5s ease-in-out`
      : `opacity 0.35s cubic-bezier(0.6, 0.04, 0.98, 0.335)`};
  transition-delay: ${(props) =>
    props.open && props.index ? `${props.index * 0.1}s` : 0};
  ${display}

  &:after {
    content: "";
    position: absolute;
    left: ${scale.px(2)};
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 4px;
    height: ${scale.px(4)};
    background-color: ${colors["gold"]};
    opacity: ${(props) => (props.active ? 1 : 0)};
  }

  ${breakpoint("l")`
		height: 100%;
		padding: 0 ${scale.px(1.5)};
		transition: none;
		opacity: 1;

		&:first-of-type {
			padding-left: 0;
		}

		&:after {
			display: none;
		}
	`}

  ${breakpoint("xl")`
		padding: 0 ${scale.px(2)};
	`};
`;

export const LinkStyled = styled(Link).withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["active"].includes(prop),
})<MenuLinkStyledProps>`
  position: relative;
  font-size: ${scale.px(3)};
  font-weight: ${fontWeight.semibold};
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  color: ${colors["black"]};

  ${breakpoint("l")`
    font-size: ${scale.px(1.75)};
    padding: ${scale.px(1.5)} 0;
    color: ${({ theme }) => (theme.dark ? colors["white"] : colors["black"])};

    &:hover {
      &:after {
        transform: translateX(-50%) scaleX(1);
      }
    }
  `}

  ${breakpoint("xl")`
    font-size: ${scale.px(2)};
  `}

  &:after {
    background-color: ${colors["gold"]};
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 4px;
    width: ${scale.px(4)};
    transform: ${({ active }) =>
      active ? `translateX(-50%) scaleX(1)` : `translateX(-50%) scaleX(0)`};
    transition: transform 0.5s cubic-bezier(0.789, 0.301, 0, 0.956);

    ${breakpoint("l")`
      content: "";
      display: block;
    `}
  }
`;

export const MenuStyled = styled.nav<MenuStyledProps>`
  width: 100%;

  ${breakpoint("m")`
		padding: ${scale.px(3)} ${scale.px(5)};
	`}

  ${breakpoint("l")`
		padding: 0;
	`}
`;
