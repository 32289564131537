"use client";
import { createBreakpoint } from "styled-components-breakpoint";

const breakpointsMap = {
  xxs: 414,
  xs: 468,
  s: 640,
  m: 769,
  l: 968,
  xl: 1240,
  xxl: 1440,
  xxxl: 1600,
};

const breakpoints = Object.keys(breakpointsMap).reduce(
  (acc, key) => [...acc, `${breakpointsMap[key]}px`],
  []
);

const breakpoint = createBreakpoint(breakpointsMap);

breakpoints.xxs = breakpoints[0];
breakpoints.xs = breakpoints[1];
breakpoints.s = breakpoints[2];
breakpoints.m = breakpoints[3];
breakpoints.l = breakpoints[4];
breakpoints.xl = breakpoints[5];
breakpoints.xxl = breakpoints[6];
breakpoints.xxxl = breakpoints[7];

export { breakpoints, breakpointsMap, breakpoint };
