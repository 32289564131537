import { neutra2Text, tiemposHeadline } from "../../../../../app/fonts";
import { TypographyProps } from "../../styles/styled-system";

export type Variants =
  | "paragraph-1"
  | "headline-1-hero"
  | "headline-1"
  | "headline-2"
  | "headline-3"
  | "headline-4"
  | "headline-5"
  | "headline-6"
  | "preamble"
  | "tag";

export interface Variant extends TypographyProps {
  as: any;
}

export type FontWeight = "light" | "normal" | "semibold" | "bold";

export const bodyFontFamily = neutra2Text.className;
export const headingFontFamily = tiemposHeadline.className;

export const fontWeight: Record<FontWeight, string> = {
  light: "200",
  normal: "400",
  semibold: "500",
  bold: "700",
};

export const variants: Record<Variants, Variant> = {
  "paragraph-1": {
    as: "p",
    fontSize: { _: 2.25, m: 2.5 },
    lineHeight: { _: 3.25, m: 4 },
    mb: { _: 2, m: 2 },
  },
  "headline-1-hero": {
    as: "h1",
    fontSize: { _: 5, m: 12 },
    lineHeight: { _: 6, m: 12 },
    fontWeight: "normal",
    mb: 2,
  },
  "headline-1": {
    as: "h1",
    fontSize: { _: 4, m: 6, xxl: 8 },
    lineHeight: { _: 5, m: 7, xxl: 9 },
    fontWeight: "normal",
    mb: 3,
  },
  "headline-2": {
    as: "h2",
    fontSize: { _: 3.5, m: 5 },
    lineHeight: { _: 4.5, m: 6 },
    fontWeight: "normal",
    pt: 2.25,
    mb: 1.5,
  },
  "headline-3": {
    as: "h3",
    fontSize: { _: 3, m: 4 },
    lineHeight: { _: 4, m: 5 },
    fontWeight: "normal",
    mb: { _: 1, m: 1.5 },
  },
  "headline-4": {
    as: "h4",
    fontSize: { _: 2.5, m: 3.25 },
    lineHeight: { _: 3, m: 4.25 },
    fontWeight: "normal",
    mb: 1,
  },
  "headline-5": {
    as: "h5",
    fontSize: { _: 2.25, m: 2.75 },
    lineHeight: { _: 2.75, m: 3.75 },
    fontWeight: "normal",
    mb: 1,
  },
  "headline-6": {
    as: "h6",
    fontSize: { _: 2, m: 2.25 },
    lineHeight: { _: 2.5, m: 3.25 },
    fontWeight: "normal",
    mb: 1,
  },
  preamble: {
    as: "p",
    fontSize: { _: 2, m: 2.25, l: 2.5 },
    fontWeight: "normal",
    lineHeight: { _: 3.25, m: 3.5, l: 4 },
    mb: 2,
  },
  tag: {
    as: "h4",
    fontSize: 1.75,
    lineHeight: 1.75,
    fontWeight: "normal",
    textTransform: "uppercase",
    letterSpacing: 0.5,
  },
};
