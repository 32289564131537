"use client";
import * as React from "react";
import styled from "styled-components";
import { scale, colors } from "../../styles";
import { size, SizeProps, ColorProps } from "../../styles/styled-system";

export interface LoaderProps extends SizeProps, ColorProps {}

const LoaderStyled = styled.div<LoaderProps>`
  display: inline-block;
  position: relative;
  width: ${scale.px(10)};
  height: ${scale.px(10)};
  ${size};

  div {
    position: absolute;
    width: 100%;
    height: 100%;
    border-style: solid;
    border-top-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : colors["black-light"]};
    border-width: ${(props) =>
      props.w ? scale.px(Number(props.w) / 10) : scale.px(1)};
    border-radius: 50%;
    animation: loaderSpin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) =>
      props.backgroundColor
        ? `${props.backgroundColor} transparent transparent transparent`
        : `${colors["black-light"]} transparent transparent transparent`};

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes loaderSpin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Loader: React.FC<LoaderProps> = (props) => (
  <LoaderStyled {...props}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </LoaderStyled>
);
