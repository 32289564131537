"use client";
import * as React from "react";
import styled, { WebTarget } from "styled-components";
import { getImageProps } from "next/image";
import noForwardProps from "../../../../../utils/restrictPropsToDOM";

export interface ResponsiveImageProps {
  alt?: string | null;
  desktopImageSrc: string;
  mobileImageSrc: string;
}

export const ResponsiveImageStyled = styled.figure.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})<ResponsiveImageProps>`
  background-color: ${({ theme }) => theme.primaryColor};
`;

export const desktopSizes = "1920px";
export const mobileSizes = "(max-width: 384px) 384px, (min-width: 385px) and (max-width: 640px) 640px, 750px";

export const getDesktopAndMobileImageProps = (props: ResponsiveImageProps) => {
  const { desktopImageSrc, mobileImageSrc, alt } = props;
  const common = { alt: alt || "", priority: true };

  const {
    props: { srcSet: desktop },
  } = getImageProps({
    ...common,
    src: desktopImageSrc,
    width: 1900,
    height: 1069,
    sizes: desktopSizes,
  });

  const {
    props: { srcSet: mobile, ...rest },
  } = getImageProps({
    ...common,
    src: mobileImageSrc,
    width: 750,
    height: 1000,
    sizes: mobileSizes,
  });

  return { desktop, mobile, rest };
};

const ResponsiveImage: React.FC<ResponsiveImageProps> = (props) => {
  const { desktop, mobile, rest } = getDesktopAndMobileImageProps(props);

  return (
    <ResponsiveImageStyled {...props}>
      <picture>
        <source
          media="(min-width: 751px)"
          srcSet={desktop}
          sizes={desktopSizes}
        />
        <source
          media="(max-width: 750px)"
          srcSet={mobile}
          sizes={mobileSizes}
        />
        <img {...rest} />
      </picture>
    </ResponsiveImageStyled>
  );
};

export { ResponsiveImage };
