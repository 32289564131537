"use client";
import * as React from "react";
import styled from "styled-components";
import { Text, Icon } from "../../components";
import { scale, colors, breakpoint } from "../../styles";
import { ThemeContext } from "styled-components";
import { fadeText } from "./animations";

export interface AlertProps {
  backgroundColor?: string;
  children: React.ReactNode;
}

export interface AlertComponent {
  Header: React.FC<React.PropsWithChildren<React.HTMLProps<HTMLButtonElement>>>;
  Body: React.FC<React.PropsWithChildren>;
  Title: React.FC<React.PropsWithChildren>;
  CloseButton: React.FC<React.HTMLProps<HTMLButtonElement>>;
}

export const AlertStyled = styled.div<AlertProps>`
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors["gold-light"]};
  position: relative;
  overflow: hidden;
`;

export const AlertHeaderStyled = styled.button`
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: ${scale.px(1.5)} ${scale.px(8)};

  span {
    opacity: 0;
    ${fadeText}
  }

  & > svg {
    width: ${scale.px(3)};
    height: ${scale.px(3)};
    transform: translateY(-${scale.px(0.125)});
    transform: translateY(-${scale.px(0.125)});
    position: absolute;
    top: ${scale.px(1.5)};
    right: ${scale.px(2)};

    ${breakpoint("m")`
            top: ${scale.px(2)};
		`}
  }
`;

export const AlertBodyStyled = styled.div`
  display: block;
  text-align: center;
  margin: 0 auto;
  max-width: ${scale.px(90)};
  padding: ${scale.px(4)} ${scale.px(8)};

  h2,
  p {
    opacity: 0;
    ${fadeText}
  }

  a {
    opacity: 0;
    ${fadeText}
    animation-delay: 0.2s;
  }
`;

export const AlertCloseButtonStyled = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: ${scale.px(1.75)};
  right: 11px;

  ${breakpoint("m")`
        top: ${scale.px(2.2)};
    `}

  svg {
    width: ${scale.px(2.5)};
    height: ${scale.px(2.5)};
  }
`;

const AlertContext = React.createContext({
  expanded: true,
  setExpanded: (expanded: boolean) => {},
});

const Alert: React.FC<AlertProps> & AlertComponent = ({
  children,
  ...rest
}) => {
  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (window && window.CookieControl) {
        window.CookieControl.getCookie("alert-bar") === "true"
          ? setExpanded(false)
          : setExpanded(true);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <AlertContext.Provider value={{ expanded, setExpanded }}>
      <AlertStyled {...rest}>{children}</AlertStyled>
    </AlertContext.Provider>
  );
};

Alert.Header = function ({ children }) {
  const { expanded, setExpanded } = React.useContext(AlertContext);
  const themeContext = React.useContext(ThemeContext);

  return !expanded && themeContext ? (
    <AlertHeaderStyled onClick={() => setExpanded(!expanded)}>
      {children}
      <Icon
        icon={"chevron-down"}
        color={themeContext.dark ? "white" : "black"}
      />
    </AlertHeaderStyled>
  ) : null;
} as React.FC<React.PropsWithChildren<React.HTMLProps<HTMLButtonElement>>>;

Alert.Body = function (props) {
  const { expanded } = React.useContext(AlertContext);

  return expanded ? <AlertBodyStyled>{props.children}</AlertBodyStyled> : null;
} as React.FC<React.PropsWithChildren>;

Alert.Title = function (props) {
  const { expanded } = React.useContext(AlertContext);

  return (
    <Text
      as={expanded ? "h2" : "span"}
      variant={"headline-4"}
      mb={expanded ? 1 : 0}
    >
      {props.children}
    </Text>
  );
} as React.FC<React.PropsWithChildren>;

Alert.CloseButton = function ({ children }) {
  const { expanded, setExpanded } = React.useContext(AlertContext);
  const themeContext = React.useContext(ThemeContext);

  return themeContext ? (
    <AlertCloseButtonStyled
      onClick={() => {
        setExpanded(!expanded);
        if (window && window.CookieControl) {
          const functionalCookiesAccepted =
            window.CookieControl.getCategoryConsent(0);

          if (functionalCookiesAccepted) {
            window.CookieControl.saveCookie("alert-bar", true, 1);
          }
        }
      }}
    >
      <Icon icon={"close"} color={themeContext.dark ? "white" : "black"} />
      <Text
        as={"span"}
        mb={0}
        fontSize={1.33}
        lineHeight={2}
        textTransform={"uppercase"}
        fontWeight={"semibold"}
      >
        {children}
      </Text>
    </AlertCloseButtonStyled>
  ) : null;
} as React.FC<React.HTMLProps<HTMLButtonElement>>;

export { Alert };
