export const formatDateSpan = (fromDate: Date, toDate: Date, localeCode: string) => {
    let formatedFromDate = fromDate.toLocaleDateString(localeCode, { 
        day: '2-digit', 
        month: fromDate.getFullYear() === toDate.getFullYear() && fromDate.getMonth() === toDate.getMonth() ? 
            undefined :
            'short',
        year: fromDate.getFullYear() === toDate.getFullYear() ? 
            undefined :
            'numeric'
    }).toLowerCase();
    let formatedToDate = toDate.toLocaleDateString(localeCode, { day: '2-digit', month: 'short', year: 'numeric' }).toLowerCase();
    return `${formatedFromDate} - ${formatedToDate}`;
};