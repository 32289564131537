import getFocusableElements from "./getFocusableElements";

const focusTrap = (containerElement: HTMLElement, setFocusOnFirst = true) => {
  let state = {
    isActive: false,
  };

  const focusableElements = getFocusableElements(containerElement);
  const firstFocusableEl = focusableElements[0];
  const lastFocusableEl = focusableElements[focusableElements.length - 1];
  const KEYCODE_TAB = 9;

  const self = {
    get isActive() {
      return state.isActive;
    },
    activate() {
      if (setFocusOnFirst && firstFocusableEl) {
        firstFocusableEl.focus();
      }
      containerElement.addEventListener("keydown", self.onKeyDown);
      state.isActive = true;
    },
    deactivate() {
      containerElement.removeEventListener("keydown", self.onKeyDown);
      state.isActive = false;
    },
    onKeyDown(e: KeyboardEvent) {
      const isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;

      if (!isTabPressed) return;

      if (e.shiftKey) {
        /* shift + tab */
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else {
        /* tab */
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    },
  };

  return self;
};

export default focusTrap;
