"use client";
import * as React from "react";
import styled, { WebTarget } from "styled-components";
import { breakpoint, breakpoints, scale } from "../../styles";
import { Text, Icon } from "../../components";
import { VideoPlayerStyled } from "../VideoPlayer/VideoPlayer";
import { useScroll, useTransform, motion } from "framer-motion";
import { ResponsiveImageStyled } from "../ResponsiveImage/ResponsiveImage";

export interface MediaContentData {
  title?: string;
  preamble?: string;
}

export interface MediaContentProps extends MediaContentData {
  media?: React.ReactNode;
  mirrorLayout?: boolean;
  bgColor?: string;
  textColor?: string;
}

const Media = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    padding-bottom: 66.66%;
  }

  ${ResponsiveImageStyled} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img,
  video {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  video {
    transform: translateY(-50%);
  }

  ${VideoPlayerStyled} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:after {
      display: none;
    }
  }

  img {
    transform: scale(1) translateY(-50%) translateZ(0);
    backface-visibility: hidden;
    transition: transform 3s cubic-bezier(0.304, 0.132, 0, 0.991);
  }

  ${breakpoint("l")`
        width: 70%;
    `}
`;

const Content = styled.div`
  position: relative;
  z-index: 1;

  p {
    height: ${scale.px(3 * 2)};
  }

  ${breakpoint("m")`
		p {
			height: ${scale.px(3 * 3)};
		}
    `}

  ${breakpoint("l")`
        width: 30%;
    `}
`;

const Inner = styled.div`
  padding: ${scale.px(4)} ${scale.px(7)} ${scale.px(4)} ${scale.px(3)};

  ${breakpoint("l")`
		padding: ${scale.px(5)} ${scale.px(6)} ${scale.px(5)} ${scale.px(5)};
		height: ${scale.px(40)};
    `}
`;

const MediaContentStyled = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["videoUrl", "textColor", "bgColor", "mirrorLayout", "pageId"].includes(prop),
})<MediaContentProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Inner} {
    background-color: ${({ theme }) => theme.primaryColor};

    & > svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: ${scale.px(3)};
      transform: translateX(0);
      transition: transform 0.3s cubic-bezier(0.789, 0.301, 0, 0.956);

      path {
        fill: ${({ theme }) => theme.textColor};
      }
    }
  }

  ${breakpoint("l")<MediaContentProps>`
        flex-direction: ${(props) =>
          props.mirrorLayout ? "row-reverse" : "row"};

        ${Content} {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: ${(props) => (props.mirrorLayout ? "auto" : 0)};
            left: ${(props) => (props.mirrorLayout ? 0 : "auto")};

            svg {
				transform: none;
				top: auto;
				bottom: ${scale.px(3)};
                right: ${(props) =>
                  props.mirrorLayout ? scale.px(-10) : scale.px(3)};
            }
        }

        ${Inner} {
            margin-left: ${(props) => (props.mirrorLayout ? 0 : scale.px(-13))};
            margin-right: ${(props) =>
              props.mirrorLayout ? scale.px(-13) : 0};
        }
    `}

  &:hover {
    ${Media} {
      img {
        transform: scale(1.04) translateY(-50%) translateZ(0);
      }
    }

    ${Content} {
      svg {
        transform: translateX(3px);
      }
    }
  }
`;

const MediaContent: React.FC<MediaContentProps> = ({
  media,
  title,
  preamble,
  mirrorLayout = false,
  ...rest
}) => {
  const { scrollY } = useScroll();
  const ref = React.useRef<HTMLDivElement>(null);
  const [elementTop, setElementTop] = React.useState(0);
  const [useParallax, setUseParallax] = React.useState(false);
  const y = useTransform(
    scrollY,
    [elementTop + 500, elementTop - 500],
    ["-20%", "0%"],
    {
      clamp: false,
    }
  );

  React.useEffect(() => {
    if (typeof window !== "undefined" && ref.current) {
      const mq = window.matchMedia(`(min-width: ${breakpoint("l")})`);

      const handleChange = () => setUseParallax(false);

      if (mq.addEventListener) {
        mq.addEventListener("change", handleChange); // Chrome, Firefox, Safari v.14+
      } else {
        mq.addListener(handleChange); // Safari v.13-. addListener() is deprecated.
      }

      setElementTop(ref.current.offsetTop);
      setUseParallax(false);
    }
  }, [ref]);

  return (
    <MediaContentStyled {...rest} mirrorLayout={mirrorLayout} ref={ref}>
      {media && <Media>{media}</Media>}
      <Content>
        {useParallax ? (
          <motion.div style={{ y }}>
            <Inner>
              {title && (
                <Text variant="headline-3" as="h2">
                  {title}
                </Text>
              )}

              {preamble && (
                <Text
                  fontSize={2.25}
                  lineHeight={3}
                  truncate={{ _: "twoLine", m: "threeLine" }}
                >
                  {preamble}
                </Text>
              )}
              <Icon icon="arrow-right" size={1.5} />
            </Inner>
          </motion.div>
        ) : (
          <Inner>
            {title && (
              <Text variant="headline-3" as="h2">
                {title}
              </Text>
            )}

            {preamble && (
              <Text
                fontSize={2.25}
                lineHeight={3}
                truncate={{ _: "twoLine", m: "threeLine" }}
              >
                {preamble}
              </Text>
            )}
            <Icon icon="arrow-right" size={1.5} />
          </Inner>
        )}
      </Content>
    </MediaContentStyled>
  );
};

export { MediaContent };
