"use client";
import * as React from "react";
import styled, { WebTarget } from "styled-components";
import { scale, colors, breakpoint } from "../../styles";
import { Icon } from "..";
import { TRANSITION_CURVE } from "../../constants";
import { neutra2Text, tiemposHeadline } from "../../../../../app/fonts";
import noForwardProps from "../../../../../utils/restrictPropsToDOM";

export interface SearchInputData {
  cancelButtonLabel?: string;
}

export type SearchInputVariant = "small" | "normal";

export interface SearchInputStyledProps {
  variant?: SearchInputVariant;
}

export interface SearchInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "type">,
    SearchInputData,
    SearchInputStyledProps {
  value: string;
  initialValue?: string;
  placeholder?: string;
  id?: string;
  ref?: React.Ref<HTMLInputElement>;
  changeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCancel?: () => void;
}

const SearchInputWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
`;

const InputLabelStyled = styled.label`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const SearchIconStyled = styled.span`
  position: absolute;
  top: 50%;
  left: ${scale.px(1.5)};
  transform: translateY(-50%);
`;

const SubmitButton = styled.button.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
}).attrs({
  type: "submit",
})<SearchInputStyledProps>`
  position: absolute;
  right: 0;
  top: 0;
  font-size: ${scale.px(1.5)};
  line-height: ${scale.px(2)};
  height: 100%;
  min-width: ${scale.px(7.5)};
  width: auto;
  padding: 0 ${scale.px(1.5)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors["blue-light"]};
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: padding 0.5s ${TRANSITION_CURVE};

  ${(props) =>
    props.variant === "normal" &&
    breakpoint("m")`
        font-size: ${scale.px(2)};
		line-height: ${scale.px(3)};
		width: auto;
		padding: 0 ${scale.px(7.5)};

		&:hover {
			padding: 0 ${scale.px(8.5)};
		}
    `}
`;

const CancelButton = styled.button.attrs({
  type: "button",
})<SearchInputStyledProps>`
  display: none;
  position: absolute;
  top: 50%;
  right: ${scale.px(10)};
  transform: translateY(-50%);

  ${(props) =>
    props.variant === "normal" &&
    breakpoint("s")`
        display: block;
    `}

  ${(props) =>
    props.variant === "normal" &&
    breakpoint("m")`
		right: ${scale.px(26)};
	`}
`;

const SearchInputStyled = styled.input.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
}).attrs<SearchInputProps>((props) => ({
  type: "search",
  "aria-label": props.label,
}))<SearchInputProps>`
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0px;
  border: 1px solid ${colors["greybrown"]};
  margin: 0;
  width: 100%;
  font-size: ${(props) =>
    props.variant === "normal" ? scale.px(4) : scale.px(2.25)};
  line-height: ${(props) =>
    props.variant === "normal" ? scale.px(5) : scale.px(4.5)};
  color: ${colors["black"]};
  padding: ${scale.px(1)} ${scale.px(6.5)} 2px ${scale.px(6.5)};

  & > div {
    display: flex;
    align-items: center;
  }

  ${(props) =>
    props.variant === "normal" &&
    breakpoint("m")`
			padding: 11px ${scale.px(9)} ${scale.px(0.5)} ${scale.px(12)};
			font-size: ${scale.px(6)};
			line-height: ${scale.px(7)};
	`}

  &::placeholder {
    color: ${colors["black"]};
    line-height: ${scale.px(4)};
    opacity: 1;

    ${(props) =>
      props.variant === "normal" &&
      breakpoint("m")`
			line-height: ${scale.px(8)};

			${SearchIconStyled} {
				left: ${scale.px(3)};
			}
		`}
  }

  &::-webkit-search-cancel-button {
    display: none !important;
  }

  &::-ms-clear {
    display: none !important;
  }
`;

const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  (props, ref) => {
    const {
      value,
      label,
      placeholder,
      initialValue,
      as,
      cancelButtonLabel,
      changeHandler,
      onCancel,
      variant = "normal",
      ...rest
    } = props;

    return (
      <SearchInputWrapper>
        <InputLabelStyled htmlFor="search">{label}</InputLabelStyled>
        <SearchInputStyled
          ref={ref}
          id="search"
          label={label}
          value={value || ""}
          placeholder={placeholder || ""}
          onChange={changeHandler}
          variant={variant}
          className={
            variant === "normal" ? tiemposHeadline.className : neutra2Text.className
          }
          {...rest}
        />
        <SearchIconStyled>
          <Icon
            icon="search"
            size={variant === "normal" ? { _: 3.5, m: 5 } : 3.5}
            color="black"
          />
        </SearchIconStyled>
        {value && (
          <CancelButton
            onClick={onCancel}
            aria-label={cancelButtonLabel || undefined}
            variant={variant}
          >
            <Icon icon="close" size={2.5} color="greybrown-dark" />
          </CancelButton>
        )}
        <SubmitButton variant={variant}>{label}</SubmitButton>
      </SearchInputWrapper>
    );
  }
);

export { SearchInput };
