"use client";
import styled, { WebTarget } from "styled-components";
import { ResponsiveValue } from "styled-system";
import { size, SizeProps, space, SpaceProps } from "../../styles/styled-system";

export interface SvgProps extends SizeProps {
  size?: ResponsiveValue<number>;
  space?: SpaceProps;
}

export const Svg = styled.svg.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["ml", "mt"].includes(prop),
})<SvgProps>`
  display: block;
  ${size}
  ${space}
`;
