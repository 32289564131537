"use client";
import * as React from "react";
import { LinkProps } from "next/link";
import styled, { css, WebTarget } from "styled-components";
import { scale, colors, breakpoint, fontWeight } from "../../styles";
import {
  color,
  ColorProps,
  border,
  BorderProps,
} from "../../styles/styled-system";
import { variant, DisplayProps, display } from "styled-system";
import { Icon } from "../../components";
import { IconTypes } from "../../components/Icon/types";
import noForwardProps from "../../../../../utils/restrictPropsToDOM"

export type ButtonSize = "small" | "large" | "xlarge" | "icon";
export type ButtonIconPosition = "left" | "right" | "center";

export interface ButtonStyledProps
  extends ColorProps,
    BorderProps,
    DisplayProps,
    React.HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  rounded?: boolean;
  size?: ButtonSize;
  stretch?: boolean;
  icon?: IconTypes;
  iconPosition?: ButtonIconPosition;
  textTransform?: "uppercase" | "none";
}

export interface ButtonProps extends ButtonStyledProps {
  label?: string;
  onClick?: (e: any) => void;
  as?: any;
  type?: "button" | "submit" | "reset";
}

export interface ButtonComponent {
  Anchor: React.FC<
    ButtonProps & Omit<React.HTMLProps<HTMLAnchorElement>, "size">
  >;
  Link: React.FC<ButtonProps & LinkProps>;
}

const hoverStyles = css<ButtonStyledProps>`
  &:hover:not([disabled]) {
    padding: ${(props) =>
      props.size === "xlarge"
        ? `${scale.px(3)} ${scale.px(6)}`
        : props.size === "large"
        ? `${scale.px(1.5)} ${scale.px(3)}`
        : `${scale.px(1.5)} ${scale.px(2)}`};
  }
`;

export const ButtonStyles = css<ButtonStyledProps>`
  position: relative;
  display: inline-block;
  text-decoration: none;
  width: auto;
  vertical-align: middle;
  transition: ${(props) =>
    props.stretch
      ? "padding 0.5s cubic-bezier(0.789, 0.301, 0, 0.956)"
      : "none"};
  border-radius: ${(props) => (props.rounded ? scale.px(0.5) : "none")};
  text-align: ${(props) =>
    props.icon && props.iconPosition === "right"
      ? "left"
      : props.icon && props.iconPosition === "left"
      ? "right"
      : "center"};
  ${color}
  ${border}
	${display}

	&:disabled {
    background-color: #e5e5e4;
    cursor: default;

    span {
      color: ${colors["greybrown-dark"]};
    }

    path {
      fill: ${colors["greybrown-dark"]};
    }
  }

  ${variant({
    prop: "size",
    variants: {
      small: {
        padding: `${scale.px(1)} ${scale.px(2)}`,
      },
      large: {
        padding: [
          `${scale.px(1)}`, // _:
          `${scale.px(1)}`, // xxs:
          `${scale.px(1)}`, // xs:
          `${scale.px(1)}`, // s:
          `${scale.px(1)}`, // m:
          `${scale.px(1.5)} ${scale.px(2)}`, // l:
        ],
      },
      xlarge: {
        padding: [
          `${scale.px(0.75)}`, // _:
          `${scale.px(0.75)}`, // xxs:
          `${scale.px(0.75)}`, // xs:
          `${scale.px(0.75)}`, // s:
          `${scale.px(0.75)}`, // m:
          `${scale.px(3)} ${scale.px(4)}`, // l:
        ],
        minHeight: ["auto", "auto", "auto", "auto", "auto", `${scale.px(9)}`],
      },
      icon: {
        padding: `${scale.px(2)} ${scale.px(2.25)}`,
      },
    },
  })}

  ${breakpoint("l")<ButtonStyledProps>`
		font-size: ${(props) =>
      props.size === "small" ? scale.px(1.75) : scale.px(2)};
		line-height:${(props) =>
      props.size === "small" ? scale.px(2.5) : scale.px(3)};
      ${(props: ButtonStyledProps) => props.stretch && hoverStyles}
	`}

  svg {
    display: block;
    position: absolute;
    top: 50%;
    right: ${(props) =>
      props.iconPosition === "right" ? scale.px(1) : "auto"};
    left: ${(props) =>
      props.iconPosition === "left"
        ? scale.px(1)
        : props.iconPosition === "center"
        ? "50%"
        : "auto"};
    transform: ${(props) =>
      props.iconPosition === "center"
        ? `translateY(-50%) translateX(-50%)`
        : `translateY(-50%)`};
    padding-bottom: 2px; // Linear to font

    path,
    polygon {
      fill: ${(props) => props.textColor || "red"};
    }

    ${breakpoint("l")<ButtonStyledProps>`
			right: ${(props) => (props.iconPosition === "right" ? scale.px(2) : "auto")};
			left: ${(props) =>
        props.iconPosition === "left"
          ? scale.px(2)
          : props.iconPosition === "center"
          ? "50%"
          : "auto"};
		`}
  }
`;

const ButtonLabel = styled.span.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})<ButtonStyledProps>`
  display: inline-block;
  color: ${(props) => props.textColor || "black"};
  margin-right: ${(props) =>
    props.icon && props.iconPosition === "right" ? scale.px(4) : 0};
  margin-left: ${(props) =>
    props.icon && props.iconPosition === "left" ? scale.px(4) : 0};
  font-size: ${(props) =>
    props.size === "small" ? scale.px(1.75) : scale.px(2)};
  line-height: ${(props) =>
    props.size === "small" ? scale.px(2.5) : scale.px(3)};
  font-weight: ${fontWeight.semibold};
  letter-spacing: 1px;
  text-transform: ${(props) => props.textTransform};
`;

const defaultProps: ButtonProps = {
  rounded: false,
  size: "large",
  stretch: false,
  disabled: false,
  icon: undefined,
  iconPosition: "right",
  textTransform: "uppercase",
};

const Content: React.FC<
  Pick<
    ButtonProps,
    "icon" | "iconPosition" | "label" | "size" | "textColor" | "textTransform"
  >
> = (props) => (
  <>
    {props.icon && props.iconPosition !== "right" && (
      <Icon icon={props.icon} size={2} />
    )}
    {props.label && props.iconPosition !== "center" && (
      <ButtonLabel {...props}>{props.label}</ButtonLabel>
    )}
    {props.icon && props.iconPosition === "right" && (
      <Icon icon={props.icon} size={2} />
    )}
  </>
);

export const ButtonStyled = styled.button.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})`
  ${ButtonStyles}
`;
export const Button: React.FC<ButtonProps> & ButtonComponent = (props) => {
  const mergedProps = { ...defaultProps, ...props };

  return (<ButtonStyled {...mergedProps}>
    <Content {...mergedProps} />
  </ButtonStyled>);
};

/**
 * Link element
 */
const LinkStyled = styled.a.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})<ButtonProps>`
  ${ButtonStyles}
`;

Button.Link = (props) => {
  const mergedProps = { ...defaultProps, ...props };

  return (
    <LinkStyled {...mergedProps}>
      <Content {...mergedProps} />
    </LinkStyled>
  );
};

/**
 * Anchor element
 */
const AnchorStyled = styled.a.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})`
  ${ButtonStyles}
`;

Button.Anchor = (props) => {
  const mergedProps = { ...defaultProps, ...props };

  return (
    <AnchorStyled as="a" {...mergedProps}>
      <Content {...mergedProps} />
    </AnchorStyled>
  );
};
