import * as React from "react";
import {
  PaginationProps,
  PaginationItemProps,
  PaginationItemType,
} from "./Pagination";

// https://dev.to/namirsab/comment/2050
const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

export const usePagination = (props: PaginationProps) => {
  const {
    totalPages,
    currentPage,
    siblingCount,
    boundaryCount,
    onChange,
    locales,
  } = props;

  const [page, setCurrentPage] = React.useState(currentPage ? currentPage : 1);

  React.useEffect(() => {
    if (page !== currentPage) {
      setCurrentPage(currentPage);
    }
  }, [page, currentPage]);

  const startPages = range(1, Math.min(boundaryCount, totalPages));
  const endPages = range(
    Math.max(totalPages - boundaryCount + 1, boundaryCount + 1),
    totalPages
  );

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      page - siblingCount,
      // Lower boundary when page is high
      totalPages - boundaryCount - siblingCount * 2 - 1
    ),
    // Greater than startPages
    boundaryCount + 2
  );

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      page + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 2
    ),
    // Less than endPages
    endPages[0] - 2
  );

  // Eg output: ['prev', 1, 'ellipsis', 4, 5, 6, 'ellipsis', 10, 'next']
  const itemList: (number | PaginationItemType)[] = [
    "prev" as PaginationItemType,
    ...startPages,

    // Start ellipsis
    ...(siblingsStart > boundaryCount + 2
      ? ["ellipsis" as PaginationItemType]
      : boundaryCount + 1 < totalPages - boundaryCount
      ? [boundaryCount + 1]
      : []),

    // Sibling pages
    ...range(siblingsStart, siblingsEnd),

    // End ellipsis
    ...(siblingsEnd < totalPages - boundaryCount - 1
      ? ["ellipsis" as PaginationItemType]
      : totalPages - boundaryCount > boundaryCount
      ? [totalPages - boundaryCount]
      : []),

    ...endPages,
    "next" as PaginationItemType,
  ];

	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement>,
		value: number
	) => {
		setCurrentPage(value);
	
		if (onChange) {
			const syntheticEvent = event as unknown as React.ChangeEvent<HTMLButtonElement>;
			onChange(syntheticEvent, value);
		}
	};
	
	const items: PaginationItemProps[] = itemList.map((item) => {
		return typeof item === "number"
			? {
					onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
						handleClick(e, item);
					},
					type: "page",
					page: item,
					active: item === page,
					disabled: item === page ? true : false,
					"aria-current": item === page ? "true" : undefined,
					"aria-label": item === page ? "" : `${locales?.goToPage} ${item}`,
				}
			: {
					onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
						handleClick(e, item === "prev" ? page - 1 : page + 1);
					},
					type: item,
					page: item === "prev" ? page - 1 : page + 1,
					active: false,
					disabled:
						item.indexOf("ellipsis") > -1 ||
						(item === "next" ? page >= totalPages : page <= 1),
					"aria-label":
						item === "prev" ? locales?.previousPage : locales?.nextPage,
				};
	});

  return items;
};
