"use client";
import * as React from "react";
import Link, { LinkProps } from "next/link";
import styled, { WebTarget, css } from "styled-components";
import { Icon, Flex } from "../../components";
import { scale, colors, fontWeight } from "../../styles";
import { ThemeContext } from "styled-components";

export interface UnderlinedLinkProps {
  label?: string;
  withIcon?: boolean;
  onClick?: (e: any) => void;
}

export interface UnderlinedLinkComponent {
  Button: React.FC<UnderlinedLinkProps & React.HTMLProps<HTMLButtonElement>>;
  Anchor: React.FC<
    UnderlinedLinkProps & Omit<React.HTMLProps<HTMLAnchorElement>, "size">
  >;
  Link: React.FC<UnderlinedLinkProps & LinkProps>;
}

const underlinedLinkStyles = css`
  position: relative;
  display: inline-block;
  font-size: ${scale.px(1.75)};
  font-weight: ${fontWeight.bold};
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-top: ${scale.px(1)};
  padding-bottom: 10px;
  text-decoration: none;
  overflow: hidden;
  color: ${({ theme }) => theme.textColor};

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background-color: ${({ theme }) =>
      theme.dark ? colors["white"] : colors["black-light"]};
    transform: scaleY(1);
    transition: transform 0.1s linear;
  }

  svg {
    position: absolute;
    left: 0;
    top: 8px;
    transform: translateX(0);
    transition: transform 0.3s cubic-bezier(0.789, 0.301, 0, 0.956);
  }

  &:hover {
    &:after {
      transform: scaleY(0.3);
    }

    svg {
      transform: translateX(3px);
    }
  }
`;

export const UnderlinedLinkStyled = styled.p`
  ${underlinedLinkStyles}
`;

export const UnderlinedLink: React.FC<UnderlinedLinkProps> &
  UnderlinedLinkComponent = (props) => (
  <UnderlinedLinkStyled {...props}>{props.label}</UnderlinedLinkStyled>
);

const LinkStyled = styled(Link).withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["backgroundColor"].includes(prop),
})<LinkProps>`
  ${underlinedLinkStyles}
`;

UnderlinedLink.Link = function ({ withIcon = false, label, ...rest }) {
  const themeContext = React.useContext(ThemeContext);
  const darkMode = themeContext?.dark || false;

  return (
    <LinkStyled {...(rest as LinkProps)} prefetch={false}>
      <Flex pl={withIcon ? 3 : 0}>{label}</Flex>
      {withIcon && (
        <Icon
          icon="arrow-right"
          size={1.5}
          color={darkMode ? "white" : "black-light"}
        />
      )}
    </LinkStyled>
  );
} as React.FC<UnderlinedLinkProps & LinkProps>;

const AnchorStyled = styled.a.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["backgroundColor"].includes(prop),
})`
  ${underlinedLinkStyles}
`;

UnderlinedLink.Anchor = function ({
  withIcon = false,
  label,
  as,
  ref,
  ...rest
}) {
  const themeContext = React.useContext(ThemeContext);
  const darkMode = themeContext?.dark || false;

  return (
    <AnchorStyled {...rest}>
      <Flex pl={withIcon ? 3 : 0}>{label}</Flex>
      {withIcon && (
        <Icon
          icon="arrow-right"
          size={1.5}
          color={darkMode ? "white" : "black-light"}
        />
      )}
    </AnchorStyled>
  );
} as React.FC<
  UnderlinedLinkProps & Omit<React.HTMLProps<HTMLAnchorElement>, "size">
>;

const ButtonStyled = styled.button.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["backgroundColor"].includes(prop),
})`
  ${underlinedLinkStyles}
`;
UnderlinedLink.Button = function ({ withIcon, label, as, ref, type, ...rest }) {
  const themeContext = React.useContext(ThemeContext);
  const darkMode = themeContext?.dark || false;

  return (
    <ButtonStyled {...rest}>
      <Flex pl={withIcon ? 3 : 0}>{label}</Flex>
      {withIcon && (
        <Icon
          icon="arrow-right"
          size={1.5}
          color={darkMode ? "white" : "black-light"}
        />
      )}
    </ButtonStyled>
  );
} as React.FC<UnderlinedLinkProps & React.HTMLProps<HTMLButtonElement>>;
