"use client";
import * as React from "react";
import styled, { WebTarget } from "styled-components";
import { breakpoint, colors, scale } from "../../styles";
import { Text, Flex } from "../../components";
import { tiemposHeadline } from "../../../../../app/fonts";
import noForwardProps from "../../../../../utils/restrictPropsToDOM";
export interface SearchResultItemProps {
  image?: React.ReactNode;
  isShow?: boolean;
  className?: string;
}

export interface SearchResultItemComponent {
  Date: React.FC<
    React.PropsWithChildren<
      SearchResultItemProps & { shouldTruncate?: boolean }
    >
  >;
  Tag: React.FC<
    React.PropsWithChildren<
      SearchResultItemProps & { shouldTruncate?: boolean }
    >
  >;
  ContactLink: React.FC<React.PropsWithChildren<{ href: string }>>;
  Content: React.FC<React.PropsWithChildren<SearchResultItemProps>>;
  Header: React.FC<
    React.PropsWithChildren<
      SearchResultItemProps & { shouldTruncate?: boolean }
    >
  >;
}

const SearchResultItemStyled = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})<SearchResultItemProps>`
  width: 100%;
  &:not(:last-of-type) {
    border-bottom: ${(props) =>
      props.isShow && props.theme.primaryColor && props.theme.complementaryColor
        ? null
        : `1px solid ${colors["greybrown"]}`};
  }
  background: ${(props) =>
    props.isShow && props.theme
      ? `
				linear-gradient(
					314.76deg,
					${props.theme.primaryColor} 0.41%,
					${props.theme.complementaryColor} 100%
				)
			`
      : colors["white"]};

  /* Override parent container's margnins */
  ${(props) =>
    props.isShow &&
    props.theme &&
    `
			left: 50%;
			right: 50%;
			margin-left: -50vw;
			margin-right: -50vw;
			max-width: 100vw;
			position: relative;
			width: 100vw;
	`}

  ${breakpoint("m")`
		left: unset;
		margin-left: unset;
		margin-right: unset;
		max-width: unset;
		right: unset;
		width: 100%;
	`}
`;

const ImageStyled = styled.figure.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})<Pick<SearchResultItemProps, "isShow">>`
  position: relative;
  min-width: ${scale.px(12)};

  ${breakpoint("s")`
		    min-width: ${scale.px(39)};
        `}

  &:after {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: ${(props) => (props.isShow ? "66.6%" : "100%")};

    ${breakpoint("s")`
		    padding-bottom: 66.6%;
        `}
  }

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const ContactLinkStyled = styled.a`
  display: block;
  color: ${colors.black};
  text-decoration: underline;
  font-size: ${scale.px(1.75)};
  line-height: ${scale.px(2.5)};
  ${breakpoint("m")`
		font-size: ${scale.px(2.25)};
		line-height: ${scale.px(3.25)};
	`}
`;

const SearchResultItem: React.FC<
  React.PropsWithChildren<SearchResultItemProps>
> &
  SearchResultItemComponent = (props) => {
  return (
    <SearchResultItemStyled {...props}>
      <Flex
        flexDirection={{ _: props.isShow ? "column-reverse" : "row", m: "row" }}
        w={1}
        py={{ _: props.isShow ? 0 : 2.5, m: 4 }}
        px={{ _: 0, m: 4 }}
      >
        <Flex
          flexDirection="column"
          mr={{ _: props.isShow ? 0 : 2.5, m: props.isShow ? 4 : 10, l: 10 }}
          flexGrow={1} // For truncate to work in header
          minw={0} // For truncate to work in header
        >
          {props.children}
        </Flex>
        <Flex
          flexDirection="column"
          ml="auto"
          w={{ _: props.isShow ? 1 : "unset", m: "unset" }}
        >
          {props.image && <ImageStyled {...props}>{props.image}</ImageStyled>}
        </Flex>
      </Flex>
    </SearchResultItemStyled>
  );
};

SearchResultItem.Date = (props) => (
  <Text
    as="span"
    fontSize={1.75}
    lineHeight={2}
    mb={{ _: props.isShow ? 2 : 0, m: 0 }}
  >
    {props.children}
  </Text>
);
SearchResultItem.Tag = (props) => (
  <div className={tiemposHeadline.className}>
    <Text
      as="span"
      fontSize={{ _: 1.5, m: 1.75 }}
      letterSpacing={0.5}
      lineHeight={2}
      mt={{ _: props.isShow ? 1.25 : 0, m: 0 }}
      mr={{ _: props.isShow ? 0 : 2, m: 2 }}
      mb={{ _: props.isShow ? 2.25 : 0.25, m: 0 }}
      textTransform="uppercase"
      truncate={props.shouldTruncate ? "singleLine" : null}
    >
      {props.children}
    </Text>
  </div>
);

SearchResultItem.Header = (props) => {
  return (
    <Flex
      flexDirection={{ _: props.isShow ? "column" : "row", m: "row" }}
      mb={{ _: 1.5, m: 2 }}
      alignItems={{
        _: props.isShow ? "center" : "flex-start",
        m: "flex-start",
      }}
    >
      {props.children}
    </Flex>
  );
};
SearchResultItem.Content = ({ children, ...rest }) => (
  <Flex flexDirection="column" maxw={124} {...rest}>
    {children}
  </Flex>
);
SearchResultItem.ContactLink = (props) => (
  <ContactLinkStyled {...props}>{props.children}</ContactLinkStyled>
);

export { SearchResultItem };
