"use client";
import { createGlobalStyle } from "styled-components";
import reset from "./reset";
import base from "./base";
import typography from "./typography";

const globalStyles = () => {

  return createGlobalStyle`
		${reset}
		${base}
    ${typography}
	`;
};
export { globalStyles };

// import { createGlobalStyle } from "styled-components"
// import reset from "./reset"
// import base from "./base"
// import typography from "./typography"

// export interface Config {
// 	publicPath: string
// }

// const defaultConfig: Config = {
// 	publicPath: ""
// }

// const globalStyles = (config: Config) => {
// 	const _config = { ...defaultConfig, config }

// 	return createGlobalStyle`
// 		${reset}
// 		${base}
//     ${typography(_config)}
// 	`
// }
// export { globalStyles }

