"use client";
import * as React from "react";
import styled from "styled-components";
import { scale, colors } from "../../styles";

export interface HamburgerProps {
  open: boolean;
}

const Box = styled.div`
  position: relative;
  display: inline-block;
  width: ${scale.px(3)};
  height: ${scale.px(2.25)};
  margin-bottom: -1px;
`;

const Inner = styled.div`
  display: block;
  margin-top: -2px;
  top: auto;
  bottom: 0;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 0.13s;

  &,
  &:before,
  &:after {
    position: absolute;
    width: ${scale.px(3)};
    height: 2px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    background-color: ${({ theme }) =>
      theme.dark ? colors["white"] : colors["black"]};
  }

  &:before,
  &:after {
    content: "";
    display: block;
  }

  &:before {
    top: ${scale.px(-1)};
    transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &:after {
    top: ${scale.px(-2)};
    transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
      opacity 0.1s linear;
  }
`;

const HamburgerStyled = styled.div<HamburgerProps>`
  display: inline-block;
  margin: 0;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;

  ${Inner} {
    ${(props) =>
      props.open &&
      `
            transform: translate3d(0, ${scale.px(-1)}, 0) rotate(-45deg);
            transition-delay: 0.22s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            &:after {
                top: 0;
                opacity: 0;
                transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
            }

            &:before {
                top: 0;
                transform: rotate(-90deg);
                transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        `}
  }
`;

const Hamburger: React.FC<HamburgerProps> = ({ open = false }) => {
  return (
    <HamburgerStyled open={open}>
      <Box>
        <Inner />
      </Box>
    </HamburgerStyled>
  );
};

export { Hamburger };
