"use client";
import styled from "styled-components";
import { lighten } from "polished";
import { scale, breakpoint } from "../../styles";
import { InfoBoxStyled } from "../InfoBox/InfoBox";
import { VideoPlayerStyled } from "../VideoPlayer/VideoPlayer";
import {
  zoomInImage,
  fadeInContainer,
  fadeInText,
  revealContainerLarge,
  revealContainerSmall,
} from "./animations";

export const ShowHeroFigure = styled.figure`
  position: relative;
  margin: 0;
  height: 70vh;
  width: 100%;
  overflow: hidden;

  img,
  video,
  ${VideoPlayerStyled} {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
  }

  img,
  video {
    object-fit: cover;
    object-position: center;
  }

  img {
    transform: scale(1.1);
    ${zoomInImage}
  }

  ${VideoPlayerStyled} {
    &:after {
      display: none;
    }
  }

  ${breakpoint(`l`)`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
		height: 100%;
		opacity: 1;
		${fadeInContainer}

        & > * > img {
            object-position: right;
		}
		
		&:after {
			content: '';
			display: block;
			padding-bottom: 56.25%;
		}
    `}
`;

export const ShowHeroContentInnerStyled = styled.div`
  padding: ${scale.px(3)};
  padding-top: ${scale.px(5)};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.primaryColor} 4%,
    ${({ theme }) => lighten(0.08, theme.complementaryColor as string)} 98%
  );
  margin-bottom: ${scale.px(3)};
  width: 100%;
  overflow: hidden;

  ${breakpoint("l")`
        padding: ${scale.px(6)} ${scale.px(5)} ${scale.px(13.75)} ${scale.px(
    5
  )};
        justify-content: start;
		text-align: left;
		margin-bottom: ${scale.px(-11.25)};
		width: ${scale.px(70)};
		
        h2,
        h1,
		h4,
		span {
			opacity: 0;
			${fadeInText}
        }

        h2 {
            animation-delay: 1.7s;
        }

        h1 {
            animation-delay: 1.5s;
        }

        h4 {
			animation-delay: 1.3s;
		}
		
		span {
			animation-delay: 1.5s;
		}

	`}

  ${breakpoint("xl")`
		min-height: 480px;
		
        h2 {
			font-size: ${scale.px(4)};
            line-height: ${scale.px(5)};
        }
		`}

	${breakpoint("xxxl")`
		padding: ${scale.px(8)} ${scale.px(5)} ${scale.px(15)} ${scale.px(5)};
		min-height: 540px;
		width: ${scale.px(80)};
		margin-bottom: ${scale.px(-8)};
		
		h2 {
			font-size: ${scale.px(6)};
            line-height: ${scale.px(7)};
        }
	`}
		
	@keyframes fadeInText {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const ShowHeroContentStyled = styled.div`
  position: relative;
  margin: ${scale.px(-10)} ${scale.px(2)} 0 ${scale.px(2)};
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${revealContainerSmall}

  ${breakpoint("l")`
		display: block;
        position: absolute;
        top: 13%;
        left: ${scale.px(8)};
        margin: 0;
		opacity: 0;
		${revealContainerLarge}


		${InfoBoxStyled} {
			left: 50%;
			transform: translateX(-50%);
		}
    `}

	${breakpoint("xxxl")`
		top: 20%;
    `}
`;

export const ShowHeroStyled = styled.div`
  position: relative;
  width: 100%;
  min-height: auto;

  ${breakpoint(`l`)`
        height: 90vh;
		min-height: var(--showHeroxMinHeight, 680px);
		
		&:after {
			content: '';
			display: block;
			padding-bottom: 56.25%;
		}
	`}
  ${breakpoint(`xl`)`
		min-height: auto;
	`}
`;
