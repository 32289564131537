"use client";
import * as React from "react";
import styled from "styled-components";
import { colors, scale } from "../../styles";

export interface SkipLinkProps {
  label: string;
}

const SkipLinkStyled = styled.a`
  position: absolute;
  top: -9999px;
  left: -9999px;
  width: 100%;
  height: 1px;
  overflow: hidden;
  text-align: center;
  display: block;
  padding: ${scale.px(1)} ${scale.px(3)};
  background-color: ${colors["gold-light"]};
  color: ${colors["black"]};

  &:active,
  &:focus,
  &:hover {
    position: relative;
    left: 0;
    top: 0;
    height: auto;
    overflow: visible;

    span {
      outline-width: 2px;
      outline-style: solid;
      outline-color: Highlight;
    }
  }
`;

const SkipLink: React.FC<SkipLinkProps> = (props) => {
  return (
    <SkipLinkStyled href="#main">
      <span>{props.label}</span>
    </SkipLinkStyled>
  );
};

export { SkipLink };
