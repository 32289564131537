"use client";
import * as React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "@popmotion/popcorn";
import { Icon, Text } from "../../components";
import { ThemeColorVariant } from "../../styles";
import { qBankImageSrc } from "../../../../../utils";

import {
  SlideshowStyled,
  SlideshowImageWrapper,
  SlideshowContent,
  FooterStyled,
  CaptionStyled,
  Indicator,
  IndicatorItem,
  PrevBtnStyled,
  NextBtnStyled,
} from "./styled";

export interface SlideshowImage {
  src?: string;
  alt?: string;
}

export interface SlideshowItem {
  caption?: string;
  credit?: string;
  image?: SlideshowImage;
}

export interface SlideshowLocales {
  nextSlide?: string;
  previousSlide?: string;
  participantsLabel?: string;
  photoLabel?: string;
}

export interface SlideshowProps {
  items: SlideshowItem[];
  themeColorVariant?: ThemeColorVariant;
  locales?: SlideshowLocales;
  onClickListener?: () => void;
}

// Define how slides should animate from one state to another.
// Note: X offset needs to take scale into account
const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? "114%" : "-115%",
      scale: 1.3,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    scale: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? "105%" : "-105%",
      scale: 1.1,
    };
  },
};

const Slideshow: React.FC<SlideshowProps> = (props) => {
  const [[item, direction], setItem] = React.useState([0, 0]);
  const itemCount = props.items ? props.items.length : 0;

  // Wraps a number when it breaches the defined range
  // Allows animatePrescence to infinitely paginate an array of items
  const activeIndex = wrap(0, itemCount, item);

  // Paginate with nav btns
  const paginate = (newDirection: number) => {
    setItem([item + newDirection, newDirection]);
  };

  // Nav btns animation states
  const [prevBtnAnimate, setPrevBtnAnimate] = React.useState(false);
  const [nextBtnAnimate, setNextBtnAnimate] = React.useState(false);

  // Swipe calculation
  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };

  const {
    image,
    caption,
    credit,
  } = props.items[activeIndex];

  return (
    <SlideshowStyled>
      <SlideshowContent>
        <SlideshowImageWrapper>
          <AnimatePresence initial={false} custom={direction}>
            <motion.img
              key={item}
              src={image?.src ? qBankImageSrc(image.src, "1600") : undefined}
              alt={image?.alt || ""}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ ease: [0.62, 0.102, 0.082, 1.009], duration: 1 }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}
            />
          </AnimatePresence>
        </SlideshowImageWrapper>
        <PrevBtnStyled
          onClick={() => {
            paginate(-1);
            setPrevBtnAnimate(true);
          }}
          onAnimationEnd={() => setPrevBtnAnimate(false)}
          className={prevBtnAnimate ? "animate" : ""}
          themeColorVariant={props.themeColorVariant || "PRIMARY"}
          {...props.locales}
        >
          <Icon icon="arrow-left" size={1.5} />
        </PrevBtnStyled>
        <NextBtnStyled
          onClick={() => {
            paginate(1);
            setNextBtnAnimate(true);
            props.onClickListener && props.onClickListener();
          }}
          onAnimationEnd={() => setNextBtnAnimate(false)}
          className={nextBtnAnimate ? "animate" : ""}
          themeColorVariant={props.themeColorVariant || "PRIMARY"}
          {...props.locales}
        >
          <Icon icon="arrow-right" size={1.5} />
        </NextBtnStyled>
      </SlideshowContent>

      <FooterStyled>
        <CaptionStyled>
          {caption && (
            <>
              <Text as="span" fontWeight="semibold">
                {props?.locales?.participantsLabel}: &nbsp;
              </Text>
              <Text as="span" mb={0}>
                {caption}
              </Text>
              <Text as="span" mx={{ _: 1, s: 2 }} mb={0}>
                |
              </Text>
            </>
          )}
          {credit && (
            <>
              <Text as="span" fontWeight="semibold" fontStyle="italic">
                {props?.locales?.photoLabel}: &nbsp;
              </Text>

              <Text as="span" fontWeight="normal" fontStyle="italic">
                {credit}
              </Text>
            </>
          )}
        </CaptionStyled>

        <Indicator>
          {props.items.map((item, i) => (
            <IndicatorItem
              key={i}
              className={i === activeIndex ? "active" : undefined}
            />
          ))}
        </Indicator>
      </FooterStyled>
    </SlideshowStyled>
  );
};

export { Slideshow };
