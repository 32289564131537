import styled, { WebTarget } from "styled-components";
import { breakpoint, scale } from "../../styles";
import noForwardProps from "../../../../../utils/restrictPropsToDOM";

const transition = "cubic-bezier(.789, .301, 0, .956) 0.38s";

export const Content = styled.div`
  transform: translateY(0);
  padding-top: calc(${(9 / 16) * 100}% + ${scale.px(2)});
  display: block;
`;

export const MediaBackground = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})<{ bgGradient: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition:
    transform ${transition},
    box-shadow ${transition};
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0);
`;

export const Media = styled.figure`
  position: relative;
  display: block;
  height: auto;
  width: 100%;
  padding-bottom: ${(9 / 16) * 100}%;
  transform-origin: center 100%;
  transform: scale(0.9);
  transform-origin: center 85%;

  > * {
    position: absolute;
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }
`;

export const EventCardStyled = styled.div`
  padding: ${scale.px(2)} ${scale.px(2)} ${scale.px(0)};
  position: relative;
  width: 100%;
  height: 100%;

  ${Media}, ${Content} {
    transition: transform ${transition};
  }

  ${breakpoint("l")`
		&:hover  {
			z-index: 2;
			${MediaBackground}  {
				transform: scale(1.05);
				box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.16);
			}
			${Media}  {
				transform: scale(0.9);
			}

			${Content}  {
				transform: translateY(2%);
				h3  {
					text-decoration: underline;
				}
			}
		}
	`}

  ${breakpoint("xxl")`
		padding: ${scale.px(2)} ${scale.px(3)} ${scale.px(5)};
	`}
`;
