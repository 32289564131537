"use client";
import * as React from "react";
import styled, { css } from "styled-components";
import { colors, scale, breakpoint } from "../../styles";
import { Icon } from "../../components";
import { usePagination } from "./usePagination";

export type PaginationItemType = "page" | "prev" | "next" | "ellipsis";

export interface PaginationItemProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, "type"> {
  active: boolean;
  type: PaginationItemType;
  page: number;
}

export interface PaginationLocales {
  previousPage: string;
  nextPage: string;
  goToPage: string;
}

export interface PaginationProps {
  totalPages: number;
  currentPage: number;
  siblingCount: number;
  boundaryCount: number;
  onChange: (e: React.ChangeEvent<HTMLButtonElement>, page: number) => void;
  locales?: PaginationLocales;
}

export interface PaginationComponent {
  Item: React.FC<PaginationItemProps>;
}

const PaginationStyled = styled.nav.attrs({
  "aria-label": "pagination navigation",
})`
  display: flex;
`;

const PaginationList = styled.ul`
  display: flex;
  border: 1px solid;
  border-color: ${({ theme }) =>
    theme.dark ? `rgba(255, 255, 255, 0.2)` : `rgba(0, 0, 0, 0.2)`};
  border-right: none;
`;

const paginationItemBaseStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => (theme.dark ? colors["white"] : colors["black"])};
  width: ${scale.px(4)};
  height: ${scale.px(4)};
  font-size: ${scale.px(2.25)};
  border-right: 1px solid;
  border-color: ${({ theme }) =>
    theme.dark ? `rgba(255, 255, 255, 0.2)` : `rgba(0, 0, 0, 0.2)`};

  ${breakpoint("l")`
		width: ${scale.px(7.5)};
		height: ${scale.px(7.5)};
	`}
`;

const PaginationItemStyled = styled.button`
  ${paginationItemBaseStyles}

  & polygon {
    fill: ${({ theme }) => (theme.dark ? colors["white"] : colors["black"])};
  }

  &:disabled {
    cursor: default;

    & polygon {
      fill: ${({ theme }) =>
        theme.dark ? `rgba(255, 255, 255, 0.2)` : colors["greybrown-medium"]};
    }
  }

  &.active {
    background-color: ${({ theme }) =>
      theme.dark ? colors["white"] : colors["greybrown-medium"]};
    color: ${colors["black"]};
    border-right: none;
  }
`;

const Ellipsis = styled.div`
  ${paginationItemBaseStyles}
`;

const Pagination: React.FC<PaginationProps> & PaginationComponent = ({
  currentPage = 1,
  siblingCount = 1,
  boundaryCount = 1,
  locales = {
    previousPage: "Previous page",
    nextPage: "Next page",
    goToPage: "Go to page",
  },
  ...props
}) => {
  const allProps = {
    currentPage,
    siblingCount,
    boundaryCount,
    locales,
    ...props,
  };

  const items = usePagination(allProps);

  return (
    <PaginationStyled>
      <PaginationList>
        {items.map((item, i) => (
          <li key={i + "-" + item?.page}>
            <Pagination.Item {...item} />
          </li>
        ))}
      </PaginationList>
    </PaginationStyled>
  );
};

Pagination.Item = (props) => {
  const { as, ref, onClick, page, type, active, ...rest } = props;

  return type === "ellipsis" ? (
    <Ellipsis>. . .</Ellipsis>
  ) : (
    <PaginationItemStyled
      onClick={onClick}
      className={active ? "active" : ""}
      {...rest}
    >
      {type === "prev" ? (
        <Icon icon="chevron-left" size={2.5} />
      ) : type === "next" ? (
        <Icon icon="chevron-right" size={2.5} />
      ) : (
        page
      )}
    </PaginationItemStyled>
  );
};

export { Pagination };
