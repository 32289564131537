const base = `
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  img {
    max-width: 100%;
  }
  a {
    text-decoration: none;
  }
  p, span, h1, h2, h3, h4, h5, h6 {
    font-variant-numeric: lining-nums;
  }
  option:disabled {
    color: #CDCBC7;
  }
`;

export default base;
